import React from 'react';
import { Route, Redirect } from 'react-router';

const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    !!sessionStorage.jwt ?  (
      <Redirect to={{
        pathname: '/',
        state: { from: props.location }
      }}/>
    ) : (
      <Component {...props}/>
    )
  )}/>
)

export default UnauthenticatedRoute
