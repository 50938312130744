import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import { withRouter } from 'react-router';
import {Field, FieldArray, reduxForm} from 'redux-form';
import {Form, Button, FormGroup, FormControl, Alert, FormLabel, Badge} from 'react-bootstrap';
import queryString from 'query-string';
import {ESAStore, months, productsList, statesList} from '../../constant';
import {FieldInput, FieldPhoneInput, FieldSelect, FieldTextArea} from '../formFields';
import {required, email, number, maxValue11} from '../formValidations';

import * as questionsActions from '../../actions/questionsActionCreators';
import * as paymentActions from '../../actions/paymentActionCreators';
import "./style.css";


const getAgeOptions = () => {
  let arr = [];
  arr.push(<option key={-1} value={""}>{"Not Selected"}</option>)
  for (let i = 1; i <= 20; i++) {
    arr.push(<option key={i} value={i}>{i}</option>)
  }
  return arr;
}

const getWeightOptions = () => {
  let arr = [];
  arr.push(<option key={-1} value={""}>{"Not Selected"}</option>)
  for (let i = 0; i < 30; i++) {
    const option = `${i * 5 + 1} - ${(i + 1) * 5}`;
    arr.push(<option key={i} value={option}>{option}</option>)
  }
  return arr;
}

const getUSStatesOptions = () => {
  let arr = [];
  arr.push(<option key={-1} value={""}>{"Not Selected"}</option>)
  statesList.map((state, index) => {
    arr.push(<option key={index} value={state.value}>{state.value}</option>);
  });
  return arr;
}

const getDays = () => {
  let arr = [];
  arr.push(<option key={-1} value={""}>{"Not Selected"}</option>)
  for (let i = 1; i <= 31; i++) {
    arr.push(<option key={i} value={i}>{i}</option>)
  }
  return arr;
}

const getMonths = () => {
  let arr = [];
  arr.push(<option key={-1} value={""}>{"Not Selected"}</option>)
  for (let i = 0; i < months.length; i++) {
    arr.push(<option key={i} value={i+1}>{months[i]}</option>)
  }
  return arr;
}

const getYears = () => {
  let arr = [];
  let max_year = new Date().getFullYear() - 18;
  arr.push(<option key={-1} value={""}>{"Not Selected"}</option>)
  for (let i = 1910; i <= max_year; i++) {
    arr.push(<option key={i} value={i}>{i}</option>)
  }
  return arr;
}

const getAnimalTypeOptions = () => {
  let arr = [];
  arr.push(<option key={-1} value={""}>{"Not Selected"}</option>)
  arr.push(<option key={"dog"} value={"dog"}>{"Dog"}</option>)
  arr.push(<option key={"cat"} value={"cat"}>{"Cat"}</option>)
  return arr;
}

const getAnimalGenderOptions = () => {
  let arr = [];
  arr.push(<option key={-1} value={""}>{"Not Selected"}</option>)
  arr.push(<option key={"male"} value={"male"}>{"Male"}</option>)
  arr.push(<option key={"female"} value={"female"}>{"Female"}</option>)
  return arr;
}

const renderAdditionalAnimalsField = ({ fields, meta: { touched, error, submitFailed } }) => (
  <div className="row mb-3 mb-lg-4">
    <div className="col-12 d-flex flex-column">
      <FormLabel>
        Add animal for $49
      </FormLabel>
      {fields.map((member, index) => (
        <div key={index} className="shadow-sm mb-3 p-3">
          <div className="row">
            <div className="col-12 d-flex justify-content-between mb-3">
              <div>
                <h6 className="text-muted">Animal #{index + 2}</h6>
                <hr className="header-underline mb-3" />
              </div>
              <a title="Remove" onClick={() => fields.remove(index)}>
                <svg aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 6L6 18" stroke="#061132" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M6 6L18 18" stroke="#061132" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </a>
            </div>

            <Field name={`${member}.animalName`}
                   label="Animal's Name*"
                   component={FieldInput}
                   validate={required}
                   className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" />

            <Field name={`${member}.type`}
                   label="Type of Animal*"
                   component={FieldSelect}
                   validate={required}
                   options={getAnimalTypeOptions()}
                   className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" />

            <Field name={`${member}.breedName`}
                   label="Animal's Breed*"
                   component={FieldInput}
                   validate={required}
                   className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" />

            <div className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4">
              <FormLabel>
                Animal's Age*
              </FormLabel>
              <div className="row mx-gutters-1">
                <Field name={`${member}.age.years`}
                       component={FieldInput}
                       validate={[number]}
                       placeholder="Years"
                       className="col-6 mb-0" />

                <Field name={`${member}.age.months`}
                       component={FieldInput}
                       validate={[number, maxValue11]}
                       placeholder="Months"
                       className="col-6 mb-0" />
              </div>
            </div>

            <Field name={`${member}.wght`}
                   label="Animal's Weight*"
                   component={FieldInput}
                   validate={[required, number]}
                   className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" />

            <Field name={`${member}.gender`}
                   label="Animal's Sex*"
                   component={FieldSelect}
                   validate={required}
                   options={getAnimalGenderOptions()}
                   className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" />

            <Field name={`${member}.support`}
                   label="What therapeutic support does this animal provide?*"
                   component={FieldTextArea}
                   rows={2}
                   maxLength={1024}
                   validate={required}
                   className="col-12 d-flex flex-column justify-content-between mb-3 mb-lg-4" />
          </div>
        </div>
      ))}
      {(touched || submitFailed) && error && <span>{error}</span>}

      {fields.length <= 1 &&
        <div className="form-group">
          <Button variant="primary" onClick={() => fields.push({})}>
            <span className="fa fa-plus"/>
            <span>&nbsp;Animal</span>
          </Button>
        </div>
      }
    </div>
  </div>
);

const validate = values => {
  let errors = {}
  if (values.animalInfo && values.animalInfo.age) {
    if ((!values.animalInfo.age.years || values.animalInfo.age.years === "0" || values.animalInfo.age.years === "") &&
      (!values.animalInfo.age.months || values.animalInfo.age.months === "0" || values.animalInfo.age.months === "")) {
      errors = {animalInfo: {age: {years: "Required"}}}
    }
  } else {
    errors = {animalInfo: {age: {years: "Required"}}}
  }

  let additionalAnimalsErrors = []
  if (values.additionalAnimals) {
    values.additionalAnimals.forEach((animal) => {
      if (animal.age) {
        if ((!animal.age.years || animal.age.years === "0" || animal.age.years === "") &&
          (!animal.age.months || animal.age.months === "0" || animal.age.months === "")) {
          additionalAnimalsErrors.push({age: {years: "Required", months: "Required if less than 1 year"}})
        } else {
          additionalAnimalsErrors.push({})
        }
      } else {
        additionalAnimalsErrors.push({age: {years: "Required", months: "Required if less than 1 year"}})
      }
    })
  }

  errors = {...errors, additionalAnimals: [...additionalAnimalsErrors]}

  return errors
}

class PersonalInfoForm extends React.Component {
  state = {
    discount: false
  }

  componentDidMount() {
    const parsed = queryString.parse(location.search);
    if (parsed.discount_token) this.setState({ discount: true });
  }

  onSubmitPersonalInfo = (data) => {
    const {userInfo, animalInfo, addition, additionalAnimals} = data;

    this.props.dispatch(paymentActions.addUserInfo(userInfo));
    this.props.dispatch(questionsActions.addAnimalInfo(animalInfo));
    this.props.dispatch(questionsActions.addAdditionalAnimalsInfo(additionalAnimals));
    this.props.dispatch(questionsActions.addAdditionInfo(addition));

    this.props.history.push('/checkout' + this.props.history.location.search);
  };

  render() {
    const { handleSubmit, invalid, submitting, pristine, dirty, submitFailed } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmitPersonalInfo.bind(this))}>
        {(!pristine && submitFailed) && invalid &&
        <Alert variant="danger">
          Please answer all questions to proceed.
        </Alert>
        }

        <div className="row mx-gutters-2 mb-3 mb-lg-4">
          <Field name="userInfo.firstName"
                 label="Patient First Name*"
                 component={FieldInput}
                 validate={required}
                 className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" />

          <Field name="userInfo.lastName"
                 label="Patient Last Name*"
                 component={FieldInput}
                 validate={required}
                 className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" />

          <Field name="userInfo.email"
                 label="Patient Email Address*"
                 type="email"
                 component={FieldInput}
                 validate={[required, email]}
                 className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" />

          <Field name="userInfo.phone"
                 label="Patient Phone Number*"
                 component={FieldPhoneInput}
                 validate={required}
                 className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" />

          <Field name="userInfo.sex"
                 label="Sex*"
                 component={FieldSelect}
                 validate={required}
                 options={[
                   <option key={"-1"} value={""}>Not Selected</option>,
                   <option key={"male"} value="male">Male</option>,
                   <option key={"female"} value="female">Female</option>]}
                 className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" />

          <div className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4">
            <FormLabel>
              Birth Date*
            </FormLabel>
            <div className="row mx-gutters-1">
              <Field name="userInfo.birthdate.year"
                     component={FieldSelect}
                     options={getYears()}
                     validate={required}
                     className="col-4 mb-0" />

              <Field name="userInfo.birthdate.month"
                     component={FieldSelect}
                     options={getMonths()}
                     validate={required}
                     className="col-4 mb-0" />

              <Field name="userInfo.birthdate.day"
                     component={FieldSelect}
                     options={getDays()}
                     validate={required}
                     className="col-4 mb-0" />
            </div>
          </div>

          <Field name="userInfo.address.street"
                 label="Street Address*"
                 component={FieldInput}
                 validate={required}
                 className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" />

          <Field name="userInfo.address.city"
                 label="City*"
                 component={FieldInput}
                 validate={required}
                 className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" />

          <Field name="userInfo.address.state"
                 label="State*"
                 component={FieldSelect}
                 validate={required}
                 options={getUSStatesOptions()}
                 className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" />

          <Field name="userInfo.address.zip"
                 label="Zip Code*"
                 component={FieldInput}
                 validate={required}
                 className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" />
        </div>

        <div className="row mx-gutters-2 mb-3 mb-lg-4">
          <div className="col-12">
            <h4 className="text-muted">Support Animal Information</h4>
            <hr className="header-underline mb-3" />
          </div>

          <Field name="animalInfo.animalName"
                 label="Animal's Name*"
                 component={FieldInput}
                 validate={required}
                 className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" />

          <Field name="animalInfo.type"
                 label="Type of Animal*"
                 component={FieldSelect}
                 validate={required}
                 options={getAnimalTypeOptions()}
                 className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" />

          <Field name="animalInfo.breedName"
                 label="Animal's Breed*"
                 component={FieldInput}
                 validate={required}
                 className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" />

          <div className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4">
            <FormLabel>
              Animal's Age*
            </FormLabel>
            <div className="row mx-gutters-1">
              <Field name="animalInfo.age.years"
                     component={FieldInput}
                     validate={[number]}
                     placeholder="Years"
                     className="col-6 mb-0" />

              <Field name="animalInfo.age.months"
                     component={FieldInput}
                     validate={[number, maxValue11]}
                     placeholder="Months"
                     className="col-6 mb-0" />
            </div>
          </div>

          <Field name="animalInfo.wght"
                 label="Animal's Weight*"
                 component={FieldInput}
                 validate={[required, number]}
                 className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" />

          <Field name="animalInfo.gender"
                 label="Animal's Sex*"
                 component={FieldSelect}
                 validate={required}
                 options={getAnimalGenderOptions()}
                 className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" />

          <Field name={"animalInfo.support"}
                 label="What therapeutic support does this animal provide?*"
                 component={FieldTextArea}
                 rows={2}
                 maxLength={1024}
                 validate={required}
                 className="col-12 d-flex flex-column justify-content-between mb-3 mb-lg-4" />
        </div>

        {!this.state.discount && <FieldArray name="additionalAnimals" component={renderAdditionalAnimalsField} />}

        <div className="row mx-gutters-2 mb-3 mb-lg-4">
          <Field name="addition"
                 label="Is there any additional information you would like the doctor to be aware of when reviewing
              your case?"
                 component={FieldTextArea}
                 rows={3}
                 maxLength={4000}
                 placeholder="Enter your additional information"
                 className="col" />
        </div>

        <div className="row mx-gutters-2">
          <div className="col">
            {(!pristine && submitFailed) && invalid &&
                <Alert variant="danger">
                  Please answer all questions to proceed.
                </Alert>
            }
            <Button
              type="submit"
              disabled={submitting}
              className="btn btn-primary pull-right btn-action btn-custom">
              Next
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

PersonalInfoForm = reduxForm({form: 'personalInfo', validate})(PersonalInfoForm)

const mapStateToProps = (state) => {
  return {
    questions: state.questions,
    initialValues: {
      userInfo: state.payment.userInfo,
      animalInfo: state.questions.animalInfo,
      additionalAnimals: state.questions.additionalAnimals
    },
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      questionsActions: bindActionCreators(questionsActions, dispatch),
      paymentActions: bindActionCreators(paymentActions, dispatch)
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PersonalInfoForm));
