import React from 'react';
import {Route, Redirect, Switch} from 'react-router-dom'

import Home from '../containers/Home/Home';
import LoginContainer from '../containers/Login/LoginContainer';
import StripeCheckoutContainer from '../containers/Payment/StripeCheckoutContainer';
import AppointmentContainer from '../containers/Appointment/AppointmentContainer';
import DoctorHomeContainer from '../components/Dashboard/DoctorHomeContainer';
import ForgotPasswordContainer from '../containers/Password/ForgotPasswordContainer';
import PasswordResetContainer from '../containers/Password/PasswordResetContainer';
import ProductContainer from '../containers/Product/ProductContainer';
import FAQContainer from '../containers/FAQ/FAQContainer';
import HowItWorksContainer from '../containers/HowItWorks/HowItWorksContainer';
import UserQuestionsContainer from '../containers/UserQuestion/UserQuestionContainer';
import QuestionsContainer from '../containers/Questions/QuestionsContainer';
import ContactUsContainer from '../containers/ContactUs/ContactUsContainer';
import HousingPolicyContainer from '../containers/HousingPolicy/HousingPolicyContainer';
import ThankyouContainer from '../containers/Thankyou/Thankyou';
import PrivacyPolicyContainer from '../containers/Terms/PrivacyPolicyContainer';
import PurchaseTermsContainer from '../containers/Terms/PurchaseTermsContainer';
import UseTermsContainer from '../containers/Terms/UseTermsContainer';
import ConfirmAppointmentContainer from '../containers/ConfirmAppointment/ConfirmAppointmentContainer';

import UnauthenticatedRoute from './UnauthenticatedRoute';
import DoctorRoute from './DoctorRoute';

const RootRoutes = () => (
  <Switch>
    <Route
      path="/"
      component={Home}
      exact
    />
    <Route
      path="/letters"
      component={ProductContainer}
      exact
    />
    <Route
      path="/FAQ"
      component={FAQContainer}
      exact
    />
    <Route
    path="/how-it-works"
    component={HowItWorksContainer}
    exact
    />
    <Route
      path="/questionnaire"
      component={UserQuestionsContainer}
      exact
    />
    <Route
      path="/userinfo"
      component={QuestionsContainer}
      exact
    />
    <Route
      path="/housing-facts"
      component={HousingPolicyContainer}
      exact
    />
    <Route
      path="/contact"
      component={ContactUsContainer}
      exact
    />
    <UnauthenticatedRoute
      path="/home"
      component={Home}
      exact
    />
    <UnauthenticatedRoute
      path="/login"
      component={LoginContainer}
      exact
    />
    <UnauthenticatedRoute
      path="/forgot-password"
      component={ForgotPasswordContainer}
      exact
    />
    <UnauthenticatedRoute
      path="/reset-password"
      component={PasswordResetContainer}
      exact
    />
    <DoctorRoute
      path="/provider"
      component={DoctorHomeContainer}
    />
    <Route
      path="/checkout"
      component={StripeCheckoutContainer}
      exact
    />
    <Route
      path="/services"
      component={AppointmentContainer}
      exact
    />
    <Route
      path="/thankyou"
      component={ThankyouContainer}
      exact
    />
    <Route
      path="/privacy-policy"
      component={PrivacyPolicyContainer}
      exact
    />
    <Route
      path="/terms-of-purchase"
      component={PurchaseTermsContainer}
      exact
    />
    <Route
      path="/terms-of-use"
      component={UseTermsContainer}
      exact
    />
    <Route
      path="/appointment_confiramtion"
      component={ConfirmAppointmentContainer}
      exact
    />
    <Redirect from='*' to='/'/>
  </Switch>
)

export default RootRoutes
