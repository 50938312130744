import React, {useEffect, useState, useMemo} from 'react';
import {useDropzone} from 'react-dropzone';

import './style.css';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px',
    borderWidth: 1,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const rejectionMessages = {
    'file-invalid-type': 'File type not supported',
    'file-too-large': 'File size too large',
    'file-too-small': 'File size too small',
    'too-many-files': 'Too many files'
};

const ConfirmAppoinmentFileUploads = props => {
    const { handleFileUpload } = props;
    const [files, setFiles] = useState([]);
    const [rejections, setRejections] = useState([]);
    const maxNumberOfFiles = 3;

    const {getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            setRejections([]);
            const newFiles = [...files, ...acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))];
            if (newFiles.length > maxNumberOfFiles) {
                let overflow = newFiles.length - maxNumberOfFiles;
                for (let i = 0; i < overflow; i++) {
                    setRejections(rejections => [...rejections, {
                        name: newFiles[maxNumberOfFiles + i].name,
                        message: rejectionMessages['too-many-files']
                    }]);
                }
                return;
            }
            setFiles(newFiles);
        },
        maxSize: 5242880,
        maxFiles: maxNumberOfFiles,
        onDropRejected: (e) => {
            setRejections([]);
            for (let i = 0; i < e.length; i++) {
                setRejections(rejections => [...rejections, {
                    name: e[i].file.name,
                    message: rejectionMessages[e[i].errors[0].code] || 'Something went wrong'
                }]);
            }
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const removeFile = (e, index) => {
        e.preventDefault();
        setFiles(files.filter((_, i) => i !== index).map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
    };

    const thumbs = files.map((file, i) => (
        <div key={i} className="confirm-appointment-thumbs">
            <div className="confirm-appointment-thumbs-inner">
                <a href="#" onClick={(e) => removeFile(e, i) } className="confirm-appointment-delete-image">X</a>
                <div className="confirm-appointment-image-wrapper">
                    <img
                        src={file.preview}
                        onLoad={() => { URL.revokeObjectURL(file.preview) }}
                        className="confirm-appointment-img"
                    />
                </div>
            </div>
        </div>
    ));

    useEffect(() => {
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    useEffect(() => {
        handleFileUpload(files);
    }, [files]);

    return (
        <div className="mt-4">
            <h4 className="text-muted">Upload your ID</h4>
            <p>In order to provide the most HIPAA compliant and Board certified recommendation, the Clinician must be able to verify your identity and date of birth. Each licensing Board requires documentation to provide to the therapist. This includes your ID, in order to be in compliance with providing a tele-health clinical consultation.</p>
            <section className="uploadContainer mt-3">
                <div {...getRootProps({style})}>
                    <input {...getInputProps()} />
                    <span>Drag 'n' drop your id here, or click to select files</span>
                </div>
                <aside className="confirm-appointment-thumbs-container">
                    {thumbs}
                </aside>
                {rejections.length > 0 && (
                    <div className="mt-2 confirm-appointment-upload-errors">
                        <ul>
                            {rejections.map((file, i) => (
                                <li key={i}>{file.name}: {file.message}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </section>
        </div>
    );
};

export default ConfirmAppoinmentFileUploads;