import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import UserDropdown from './UserDropdown'

import * as paymentActions from '../../actions/paymentActionCreators';
import './style.css';

class Header extends React.Component {
  state = {
    navExpanded: false
  };

  onNavToggle = (expanded) => {
    this.setState({ navExpanded: expanded });
  };

  onNavSelected = (eventKey, syntheticEvent) => {
    this.props.history.push(eventKey);
    this.setState({ navExpanded: false });
  };

  render() {
    const nonGetStartedUrls = ["/questionnaire", "/checkout", "/userinfo", "/letters", "/thankyou", "/services/"];

    return (
      <header className="navigation-wrapper fixed-top">
        <Navbar className="container py-0" expand="lg" bg="white"
                onToggle={this.onNavToggle}
                expanded={this.state.navExpanded}
                onSelect={this.onNavSelected}>
          <Navbar.Brand className="mr-4 mr-xl-5">
            <Nav.Link eventKey="/">
              <img className="header-logo-img" src="/images/logos/logo.svg" alt="logo"/>
            </Nav.Link>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="bn-nav-bar" className="btn">
            {this.state.navExpanded ?
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 6L6 18" stroke="#7EC7D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M6 6L18 18" stroke="#7EC7D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              :
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 12H21" stroke="#7EC7D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3 6H21" stroke="#7EC7D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3 18H21" stroke="#7EC7D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            }
          </Navbar.Toggle>

          <Navbar.Collapse id="bn-nav-bar">
            <Nav className="text-center mt-2 mt-lg-0">
              <Nav.Link eventKey="/how-it-works">
                How It Works
              </Nav.Link>
              <Nav.Link eventKey="/letters">
                ESA Letters
              </Nav.Link>
              <Nav.Link eventKey="/housing-facts">
                Housing Facts
              </Nav.Link>
              <Nav.Link eventKey="/FAQ">
                FAQ
              </Nav.Link>
              <Nav.Link eventKey="/contact">
                Contact Us
              </Nav.Link>
            </Nav>

            {!nonGetStartedUrls.includes(this.props.location.pathname) &&
              <UserDropdown/>
            }
          </Navbar.Collapse>
        </Navbar>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    payment: state.payment
  }
}

export default withRouter(connect(mapStateToProps, paymentActions)(Header));