import { reducer as formReducer } from 'redux-form'
import dashboard from './dashboardReducer'
import session from './sessionReducer'
import password from './passwordReducer'
import payment from './paymentReducer'
import contact from './contactReducer'
import schedule from './scheduleReducer'
import questions from './questionReducer'
import appointment from './appointmentReducer'
import { combineReducers } from 'redux';

export const rootReducer = combineReducers({
  form: formReducer,
  session,
  password,
  dashboard,
  payment,
  contact,
  schedule,
  questions,
  appointment
});
