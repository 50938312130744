import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {FormGroup, Button, Alert, Form, Col, FormLabel, FormControl} from 'react-bootstrap';

import "./ContactUsForm.css"
import {FieldInput, FieldTextArea} from '../formFields';
import {required, email} from '../formValidations';

let ContactUsForm = ({ handleSubmit, errors, saving }) => {
  return (
    <div className="contactus-form">
      <Form onSubmit={handleSubmit}>
        { errors.length > 0 &&
        <Alert bsStyle="danger">
          {errors.map(error => <li><strong>{error}</strong></li>)}
        </Alert>
        }

        <FormGroup controlId="formHorizontalEmail">
          <Col as={FormLabel} lg={3}>
            Your Email*
          </Col>
          <Col lg={9}>
            <Field
              component={ FieldInput }
              name="contact_us[email]"
              placeholder="Email"
              validate={[required, email]}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="formHorizontalName">
          <Col as={FormLabel} lg={3}>
            Your Name*
          </Col>
          <Col lg={9}>
            <Field
              component={ FieldInput }
              name="contact_us[name]"
              placeholder="Name"
              validate={required}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="formHorizontalSubject">
          <Col as={FormLabel} lg={3}>
            Subject
          </Col>
          <Col lg={9}>
            <Field
              component={ FieldInput }
              name="contact_us[subject]"
              placeholder="Subject"
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="formHorizontalMessage">
          <Col as={FormLabel} lg={3}>
            Message*
          </Col>
          <Col lg={9}>
            <Field
              name="contact_us[content]"
              component={FieldTextArea}
              type="text"
              rows={5}
              style={{ height: 200 }}
              placeholder="Your message"
              validate={required}/>
          </Col>
        </FormGroup>

        <FormGroup>
          <Col>
            <Button className="btn btn-primary btn-action btn-custom" disabled={saving} type="submit">Submit</Button>
          </Col>
        </FormGroup>
      </Form>
    </div>
  )
}

const createReduxForm = reduxForm({ form: 'contact-us' })

ContactUsForm = createReduxForm(ContactUsForm)

export default ContactUsForm
