export const ADD_QUESTIONS = 'ADD_QUESTIONS';
export const ADD_ANIMAL_INFO = 'ADD_ANIMAL_INFO';
export const ADD_ADDITIONAL_ANIMALS_INFO = 'ADD_ADDITIONAL_ANIMALS_INFO';
export const ADD_ADDITION_INFO = 'ADD_ADDITION_INFO';
export const ADD_USER_QUESTION_INFO = 'ADD_USER_QUESTION_INFO';

export function addQuestions(payload) {
  return {
    type: ADD_QUESTIONS,
    payload
  };
}

export function addAnimalInfo(payload) {
  return {
    type: ADD_ANIMAL_INFO,
    payload
  };
}

export function addAdditionalAnimalsInfo(payload) {
  return {
    type: ADD_ADDITIONAL_ANIMALS_INFO,
    payload
  };
}

export function addUserQuestionInfo(payload) {
  return {
    type: ADD_USER_QUESTION_INFO,
    payload
  };
}

export function addAdditionInfo(payload) {
  return {
    type: ADD_ADDITION_INFO,
    payload
  };
}
