import React from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sessionActions from "../../actions/sessionActionCreators";

class UserDropdown extends React.Component {
  render () {
    const { session, sessionActions } = this.props;
    const type = sessionStorage.getItem("accountType");
    const email = sessionStorage.getItem("email");
    const dashboardLink = `/${type === "Provider" ? "provider/dashboard" : "patient"}`;
    const title = `${type} (${email})`;
    return session.logged_in ?
      (
        <NavDropdown id="user-dropdown" title={title}>
          <NavDropdown.Item eventKey={dashboardLink}>Dashboard</NavDropdown.Item>
          <NavDropdown.Item eventKey={'/'} onClick={() => sessionActions.logOutAccount()}>Sign Out</NavDropdown.Item>
        </NavDropdown>
      ) :
      (
        <div className="header-right-actions">
          <Nav.Link className="btn btn-primary" eventKey="/letters">Get started</Nav.Link>
        </div>
      )
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.session
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sessionActions: bindActionCreators(sessionActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDropdown);
