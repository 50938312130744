import React from 'react';
import './style.css';

class ConfirmAppointmentText extends React.Component {
    render() {
        return (
            <div className="confirm-appointment-text">
                <h2>HIPAA AUTHORIZATION TO SHARE HEALTH INFORMATION</h2>

                <p>By signing below, I authorize the licensed mental health professional and their employees and agents (collectively "Licensed Mental Health Professional") to give out medical information about me as described below and I authorize the provision of any medical/mental health information I provide to the licensed mental health professional.</p>

                <p>Description of information to be used or disclosed: the Emotional Support Animal letter (the "Letter") drafted by the Licensed Mental Health Professional, if one, and all records and medical or mental health information related to the Letter or related to the evaluation by the Licensed Mental Health Professional including without limitation all information, opinions, diagnoses, assessments, notes and documentation related to such assessment, treatment and the Letter including any pre-screening or demographic information provided to Ms. Garmon by me.</p>

                <p>Purpose of the disclosure: I am requesting that this information be disclosed for any purpose deemed necessary or advisable by Ms. Garmon.</p>

                <p>I understand that if my information is given out as allowed on this form, The online agency is not subject to federal privacy laws and may re-disclose my information. Therefore, those laws will not protect my information.</p>

                <p>I agree that Ms. Garmon will request a Release of Information to coordinate care with current or past treating providers, prior to making a final assessment for recommendation. Ms. Garmon will attempt for (2) weeks to contact the past provider and if no response is given with those 2 weeks, my assessment request will be denied until coordination of care can happen.</p>

                <h2>INFORMED CONSENT FOR TELE-THERAPY SERVICES</h2>

                <p>I understand that the company will provide administrative services to connect me with a licensed mental health professional via our online platform to provide behavioral healthcare, including assessment, diagnosis, therapy, follow-up and/or education teletherapy includes consultation, treatment, transfer of personal and health information, emails, telephone conversations and education using interactive audio, video and data communications (“Telehealth”). Telehealth involves the use of electronic communications. I hereby consent to engage in Telehealth services with my LHMP.</p>

                <p>I understand that the expected benefits of Telehealth are improved access to mental health care enabling me to remain at a remote site while the LMHP is at a distant site, more efficient evaluation and management and obtaining the expertise of a distant specialist who is licensed in the state where I reside.</p>

                <p>I understand and agree that this website is not an emergency-response or emergency-monitoring service and any person who is aware of an emergency or believes that a person may be at risk of injury or death or who may harm themselves or another person should immediately contact an appropriate emergency responder or dial “911” on a phone. The online agency is under no obligation to monitor or respond to communications made to, on or through this website and no user or person should rely on the website for medical or behavioral health advice or emergency services.</p>

                <p>I understand that I have a right to confidentiality with Telehealth under the same laws that protect the confidentiality of my personal and health information for in-person behavioral health services. Any information disclosed by me during my remote Telehealth, therefore, is generally confidential to the extent provided by law.</p>

                <p>I acknowledge and consent to utilizing electronic communication (email, phone, messaging on the platform) that may not be protected by security measures or HIPAA compliant (using gmail and personal phone lines not protected by a password, etc). If I have any questions regarding such use, I will ask my provider. I have the right to end treatment if I am not comfortable with this type of communication.</p>

                <p>As with any medical care, there are potential risks associated with the use of Telehealth. These risks include, but may not be limited to: (a) in rare cases, information transmitted may not be sufficient (e.g. poor resolution of images) to allow for appropriate professional decision making by the LMHP; (b) delays in evaluation and treatment could occur due to deficiencies or failures of the equipment; and (c) the possibility of disruption, distortion or unauthorized access during transmission of personal information due to internet/electronic/technical failures beyond the control of my LMHP.</p>

                <p>By signing this form, I understand the following:</p>
                <ul>
                    <li>I understand that Telehealth is the use of electronic communication technologies by a health and/or therapeutic provider to deliver services to an individual when he/she is located at a different site than the provider; and hereby consent to the LMHP to provide mental and social services to me via Telehealth.</li>
                    <li>I understand that I may be required to have certain system requirements to access electronic Telehealth services via the method chosen. I understand that I am solely responsible for any cost to obtain any additional/necessary system requirements, accessories, or software to use tele- behavioral health services.</li>
                    <li>I understand that I have the right to withhold or withdraw my consent to the use of Telehealth by the LMHP at any time, without affecting my right to future care or treatment.</li>
                    <li>I understand that a variety of alternative methods of health care may be available to me, and that I may choose one or more of these at any time.</li>
                    <li>I represent and warrant that all required information I provided to the LMHP is truthful and accurate, and that I will maintain the accuracy of such information.</li>
                    <li>I understand that there are, by law, exceptions to confidentiality by a LMHP including, but not limited to, reporting child, elder and dependent adult abuse; expressed threats of violence toward an ascertainable victim; my own mental or emotional state informing a clear danger to myself or others; where I make my mental or emotional state an issue in a legal proceeding; where otherwise required by law.</li>
                    <li>I understand that I am solely responsible for the privacy and confidentiality in my surrounding environment while engaged in telehealth and will exercise appropriate privacy measures.</li>
                    <li>I agree that if it becomes clear to the LMHP, in his or her sole professional opinion, that the Telehealth modality is unable to provide all pertinent clinical information during the Telehealth encounter, the LMHP will advise me prior to the conclusion of the live Telehealth encounter and will advise me regarding the need for me to obtain an additional in-person evaluation reasonably able to meet my needs and may make a referral to a LMHP in my area.</li>
                    <li>I understand that, if Ms. Garmon determines a higher level of care is needed, or previous history of mental health actions are too severe or requires follow up consultations, then my provider (Ms. Garmon) has the right to refer my clinical needs out to a local provider (i.e. history of self-harm, suicidality, concern for accuracy or truthfulness of the information submitted to Ms. Garmon)</li>
                    <li>I understand that I have a duty to inform my LMHP of electronic interactions regarding my care that I may have with other healthcare providers.</li>
                </ul>

                <p>I have read and understand the information provided above regarding teletherapy and all of my questions have been answered to my satisfaction.</p>

                <p>By clicking the “I AGREE” button and typing my name at the bottom of this page, I am authorizing my assigned LMHP to assess my mental health via Telehealth and confirming my agreement and understanding of the statements above. I hereby give my informed consent and authorization for my LHMP to use Telehealth in my healthcare.</p>

                <p>I agree that a copy of this form may be treated as a signed original.</p>

                <h2>GARMON COUNSELING TERMS AND CONDITIONS</h2>

                <p>I understand that the company will provide administrative services to connect me with a licensed mental health professional via our online platform to provide behavioral healthcare, including assessment, diagnosis, therapy, follow-up and/or education teletherapy includes consultation, treatment, transfer of personal and health information, emails, telephone conversations and education using interactive audio, video and data communications (“Telehealth”).</p>

                <p>I understand and agree that this website is not an emergency-response or emergency-monitoring service and any person who is aware of an emergency situation or believes that a person may be at risk of injury or death or who may harm themselves or another person should immediately contact an appropriate emergency responder or dial “911” on a phone. monitor or respond to communications.</p>

                <p>By signing this form, I further understand the following:</p>
                <ul>
                    <li>This website does not accept health insurance for payment of any services provided.</li>
                    <li>I understand and agree that Ms. Garmon makes no representations or warranties regarding the qualification of my animal companion or its training or temperament. For purposes of air travel accommodation, I will make my own certifications regarding my animal's training or temperament.</li>
                    <li>By submitting payment to use Ms. Garmon services, you agree and acknowledge that I will make myself available during the time of my identified scheduled consultation. If I miss 2+ scheduled consultations, Ms. Garmon will not be concluding the assessment or further treatment.</li>
                    <li>Ms. Garmon makes no guarantee that every landlord, airline, or other third party will follow the requirements set by the federal rules and regulations which govern ESA, and Ms. Garmon is not responsible for changing federal rules or regulations. If you are denied an accommodation by a landlord, property management agent, airline, or other third party due to a claim that your ESA Letter is not valid, Ms. Garmon will use commercially reasonable efforts to provide you with resources to assist you.</li>
                    <li>You agree to pay all fees or charges to your Account in accordance with the fees, charges, and billing terms in effect at the time Services are rendered. You acknowledge and agree that you shall be personally responsible for all expenses incurred in connection with the Services you request.</li>
                    <li>I represent and warrant that all required information I provided to this website and the LMHP is truthful and accurate, and that I will maintain the accuracy of such information.</li>
                    <li>I understand that results are not guaranteed and I am not guaranteed to receive an ESA letter or that private individuals, companies or governmental entities will agree with any position taken by you, the LMHP or in any ESA Letter.</li>
                    <li>Ms. Garmon will require another assessment if the last clinical contact was/has been more than 5+ months.</li>
                    <li>I understand my recommendation expires one year from the date of the recommendation. I also understand Ms. Garmon reserves the right to rescind or decline the continued recommendation, at any time, based on clinical judgment, change of clinical presentation, change of clinical need or any such clinical decision Ms. Garmon makes. Ms. Garmon will provide me a letter of this if such action occurs.</li>
                    <li>I acknowledge and agree that any pre-screening questionnaire is offered for information and convenience purposes only and does not result in diagnosis. I acknowledge that I will be working to complete other assessments with Ms. Garmon.</li>
                    <li>I understand and agree that by bringing my support animal on a plane or in public/private places, I assume all risks related to my animal’s acts, behavior, and conduct. Ms. Garmon shall be responsible or liable for any damages or liabilities caused by my animal. I further agree that any ESA letter does NOT provide any protections of any kind against liability for the conduct of my animal. I understand that Ms. Garmon is not qualified to evaluate the behavior of my animal and my animal's behavior is completely my responsibility.</li>
                    <li>I represent and warrant that my animal has never harmed me, another individual or other animal and my animal has not demonstrated any behaviors which can be interpreted as aggression.</li>
                    <li>I understand that an emotional support animal is not a service animal, therapy animal or guard animal.</li>
                    <li>I declare that I am physically located in and reside in the state indicated in my shipping address and acknowledge that my LMHP must be licensed in the state where I am located at the time of the assessment as LMHP cannot assess across state lines.</li>
                    <li>I declare that I am in a private space, or space I decide upon, during the assessment. Ms. Garmon can make no accommodations for the security of your surroundings, and I understand this is my obligation.</li>
                    <li>I declare I will be in a space that allows for full disclosure and clinical communication between Ms. Garmon and myself. Ms. Garmon reserves the right to reschedule the clinical time together if Ms. Garmon is concerned about any of the above issues.</li>
                    <li>I declare that it is my responsibility to work with my providers to ascertain whether the services of Ms. Garmon meet the qualifications and needs of what I am looking to accomplish (i.e. verifying my provider is comfortable with an out-of-state provider or provider sought out aside from my current network of treating providers / care providers.</li>
                </ul>

                <h2>About My Treating Provider</h2>

                <p>
                    Krystal I. Garmon, LPC, LMHC<br />
                    (425) 499-8588<br />
                    <a href="mailto:garmoncounseling@gmail.com">garmoncounseling@gmail.com</a>
                </p>

                <p>Ms. Garmon is in Georgia. She provides telehealth services. She can be reached via cell phone and office address during normal business hours (Monday through Friday 9am EST to 4pm EST).</p>

                <p>All Inquiries will be responded to within (48) business hours and emergency needs will be directed via voicemail. Ms. Garmon will require an appropriate release of information to communicate with providers. You will be given a mental health diagnosis upon treatment and a plan to commence treatment.</p>

                <p>Krystal Garmon, LPC, LMHC Licensure: <br />
                (Texas) #83616; (Georgia) #LPC010830; (Ohio) #E.2202918; (Vermont) # 68.0134418; (Connecticut) #4349; (New Hampshire) #2254; (Maine) LPC010830; (Washington) LH60573417; (South Dakota) LPC- MH30621; (Kansas) #2851</p>

                <p>Degrees:</p>
                <ul>
                    <li>Masters in Mental Health Counseling, Argosy University, Florida, 2011</li>
                    <li>Bachelors of Sociology, 2005, Flagler College, Florida</li>
                </ul>

                <p>Additional Training:</p>
                <ul>
                    <li>Human Animal Bonding, AMVR, 2019</li>
                    <li>Trauma Therapy Certificate, University of Buffalo, NY, 2016</li>
                </ul>

                <p>The practice of licensed or registered persons in the field of psychotherapy is regulated by the Mental Health Licensing Section of the Division of Professions and Occupations. All Licensed Mental Health therapists must complete a master’s degree in the professional field; complete (2) years and 1500 hours of supervised clinical practice. The applicant must also pass a State exam.</p>

                <p>The Board outlines unprofessional actions and the profession is regulated. You have the right to report any questionable act or concern to the Department of Health.</p>

                <p>You are entitled to receive information from your provider about the methods of therapy, the techniques used, the duration of your therapy, if known, and the fee structure. You may seek a second opinion from another therapist or terminate therapy at any time. You may ask questions at any time during treatment. All practicing counselors must be credentialed. If, at any time, credentials do not support diagnosis and treatment, this information will be provided to you. If treatment is not a good fit, Ms. Garmon will work to find the appropriate links to referral sources.</p>

                <p>Ms. Garmon holds an approach to treatment that is client centered and follows the concepts of ACT (Acceptance and Commitment Therapy) Therapy. There will be a variety of techniques utilized during treatment, some to include dialogue, interpretation, cognitive reframing, awareness exercises, visualization. Ms. Garmon will utilize clinical observation, clinical dialogue, and clinical assessments (sharing with you along the way) such as PHQ9- Depression Inventory, GAD7- Anxiety Inventory, PCL-5 – Adult Trauma Screening tool, any other relevant assessment tools, and self-report. Treatment will be discussed prior to utilization and coordination of care with resources and providers is a usual occurrence. Ms. Garmon will offer tele-therapy services via telephone or secure HIPAA compliant website Doxy. Location of services will be confirmed upon meeting.</p>

                <p>If there is a diagnosis utilized, it will be discussed with you, coming from the DSM-IV. Diagnosis are technical terms that describe your mental health concerns and are descriptive in nature.</p>

                <p>For the purposes of clinical practice, Ms. Garmon does not provide counseling services to those individuals under the age of 18 years old. If a Power of Attorney or Guardian resides over an individual over 18 years old, consent must also be reviewed and signed by the other attending individual. Ms. Garmon does not provide clinical recommendations to individuals under 18 years of age.</p>

                <p>For emotional support animal recommendations, Ms. Garmon has the following expectations:</p>

                <ul>
                    <li>Client must be physically located in their residential state of request. If you are not located in your state, you will be rescheduled and responsible for rescheduling. Ms. Garmon will confirm identifying information prior to and during out treatment.</li>
                    <li>Any recommendations are good for a year from the recommendation date, unless otherwise deemed clinically necessary to no longer recommend a certain type of treatment.</li>
                    <li>Client can reach out to Ms. Garmon anytime during the recommendation time frame for follow- up care, treatment, or forms submission. If Ms. Garmon contact information changes, Ms. Garmon will notify me via email.</li>
                    <li>All forms will be completed by Ms. Garmon within (48) business hours of receiving the applicable form.</li>
                    <li>All providers will be connected back with, via email/phone, within (48) business hours by Ms. Garmon, given appropriate Release of Information provided by the client.</li>
                    <li>Ms. Garmon does not review Emotional Support Applications and Assessments for more than (2) two animals. Ms. Garmon does not attest to any health or temperament of any animal. Ms. Garmon will not get involved in any landlord/resident banter.</li>
                    <li>Ms. Garmon reserves the right to clinically state that, whether beneficial of an emotional support recommendation, Ms. Garmon does not clinically suggest that the ESA recommendation be located at the suggested residence/location/dorm room/facility or any other location not beneficial for the overall wellbeing and treatment of the client and ongoing therapeutic needs, whether this be before, during, or after any type of recommendation, based on more current clinical needs and clinical judgment made by Ms. Garmon.</li>
                    <li>Ms. Garmon reserves the right to coordinate care with your previous or current treating provider prior to screening for the need for this type of recommendation. I understand that Ms. Garmon reserves the right to request, receive, and follow up on this release of information/contact with the client’s provider(s) prior to giving this type of recommendation, regardless of the urgency.</li>
                    <li>Any client will have previously ensured that their provider seeking a recommendation is okay with a provider out of state, this is the responsibility of the client. I recognize that Ms. Garmon is on EST time zone. I recognize that I (client) have the responsibility to ensure that working with Ms. Garmon for this type of recommendation is appropriate for the client’s particular situation/need.</li>
                    <li>All requests by Ms. Garmon (such as completing a Release of Information) will be responded to within (48) business hours. I understand that lack of communication with Ms. Garmon within the required time frame after our consultation may end with denial. I, the client, attests that all communication with Ms. Garmon is truthful.</li>
                    <li>All form requests and payment will be routed through a secure link so that the client may request extra support (i.e. phone calls by the provider) or proper forms to be filled out.</li>
                    <li>I understand that, If I cancel less than 24hours in advance, or I do not show for the scheduled consultation, I (client) will be required to pay a reschedule fee, as appropriate, prior to being able to reschedule.</li>
                    <li>If I choose to continue treatment, as recommended, I will schedule a time to meet for 15 minutes with Ms. Garmon and review the applicable forms and consent to further treatment. Based on Ms. Garmon’s assessment of previous treatment, and whether you wish to continue current/begin treatment, Ms. Garmon may then ascertain that the recommendation, as a stand- alone treatment, is not a sufficient treatment modality and may refer you to another provider(s) for ongoing treatment instead. Revoking consent to treatment for the recommendation will revoke the recommendation itself.</li>
                </ul>

                <p>There is no further cost associated with this service to Ms. Garmon. Upon entering a private practice relationship with Ms. Garmon, fee costs will be discussed prior to the session. Session time varies but usually lasts 50-60 minutes. Ms. Garmon does not take healthcare insurance. A financial consent and intake review form will be provided upon entering into the therapeutic relationship.</p>

                <p>Information provided by and to the client during therapy sessions is legally confidential and cannot be released without your consent. There are exceptions to this confidentiality. If a legal exception arises during therapy, if feasible, you will be informed accordingly. Behavioral health professionals are required to report child abuse, any neglect, and elder abuse to authorities.</p>

                <p>There is a “duty to warn” if a client, regardless of age, communicates a serious threat of imminent physical violence against a specific person, location, or entity.</p>

                <p>If you are in imminent danger of self-harm, Ms. Garmon may legally break confidentiality and call the police or county crisis team. Any disclosure will be made aware to you. In a professional relationship, sexual intimacy is never appropriate and should be reported to the board that licenses, registers, or certifies the licensee, registrant, or certificate holder. If you inform Ms. Garmon that a person has engaged in this type of behavior or is impaired in practice in some manner by cognitive, emotional, behavioral, or health issues, then law requires Ms. Garmon to report this accordingly to licensing boards and police authorities.</p>

                <p>You may direct Ms. Garmon to communicate to another provider upon a Release of Information being signed by you. If there are any electronic communications, it will be done with special safeguards to ensure confidentiality in an encrypted format. All communication will be kept in a treatment record.</p>

                <p>It is important for you to know that your records may not be maintained more than 7 years after the date you were last seen by your provider. Records are maintained securely via an encrypted format, only accessed by Ms. Garmon. You have the right to request a copy of your file any time. Notes will notate that you were present, what interventions occurred, and the topics that were discussed, as well as plans for continued care.</p>

                <p>Treatment has potential emotional risks. Approaching feelings or thoughts that you have tried not to think about for a long time may be painful. Making changes in your beliefs or behaviors can be scary. Most people who take the risk to seek treatment will find that treatment is helpful, and Ms. Garmon will support your path along the way.</p>

                <p>Mental Health Treatment is a relationship that works in part because of clearly defined rights and responsibilities held by each person. This frame helps to create the safety to take risks and the support to become empowered to change. As a client, you have certain rights that are important for you to know about because this is your treatment, whereas the main goal is your well-being. There are also certain limitations to those rights that you should be aware of. As a treating provider, Ms. Garmon has corresponding responsibilities to you.</p>

                <p>I have read the preceding information and I understand my rights as a client or as the client. I understand I can ask, and are encouraged to ask questions, and can revoke this consent at any time.</p>
            </div>
        );
    }
}

export default ConfirmAppointmentText;