import React from 'react';
import { Elements } from 'react-stripe-elements';
import CheckoutContainer from './CheckoutContainer'

const StripeCheckoutContainer = ({ history }) => (
  <Elements>
    <CheckoutContainer history={history}/>
  </Elements>
)

export default StripeCheckoutContainer
