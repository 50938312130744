import axios from "axios";
import { SubmissionError } from 'redux-form';
import { history } from '../store.js'

export const SUBMIT_PAYMENT_REQUEST = 'SUBMIT_PAYMENT_REQUEST';
export const SUBMIT_PAYMENT_SUCCESS = 'SUBMIT_PAYMENT_SUCCESS';
export const SUBMIT_PAYMENT_FAILURE = 'SUBMIT_PAYMENT_FAILURE';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CHECK_OUT = 'CHECK_OUT';
export const ADD_USER_INFO = 'ADD_USER_INFO';

export const submitPaymentRequest = () => {
  return {
    type: SUBMIT_PAYMENT_REQUEST
  }
}

export const submitPaymentSuccess = (message) => {
  return {
    type: SUBMIT_PAYMENT_SUCCESS,
    payload: message
  }
}

export const submitPaymentFailure = (errors) => {
  return {
    type: SUBMIT_PAYMENT_FAILURE,
    payload: errors
  }
}

export const addToCart = (data) => {
  return {
    type: ADD_TO_CART,
    payload: data
  }
}

export const removeFromCart = () => {
  return {
    type: REMOVE_FROM_CART
  }
}

export const checkout = (data) => {
  return {
    type: CHECK_OUT,
    payload: data
  }
}

export function addUserInfo(payload) {
  return {
    type: ADD_USER_INFO,
    payload
  };
}

export const submitPayment = (data) => {
  return (dispatch) => {
    dispatch(submitPaymentRequest())
    return axios.post("/charges", data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      dispatch(submitPaymentSuccess(response.message))
      history.push('/thankyou')
    })
    .catch(e => {
      dispatch(submitPaymentFailure(e.response.data))
      if (e.response.status !== 500) {
        throw new SubmissionError(e.response.data)
      }
    });
  };
}
