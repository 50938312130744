import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from 'query-string';

import * as scheduleActions from "../../actions/scheduleActionCreators";
import ConfirmAppointmentContainerConsent from './ConfirmAppointmentContainerConsent';
import ConfirmAppointmentContainerNoConsent from './ConfirmAppointmentContainerNoConsent';

class ConfirmAppointmentContainer extends React.Component {

  componentDidMount() {
    const parsed = queryString.parse(location.search);
    this.props.actions.fetchScheduleInfo({
      appointment: parsed.appointment,
      code: parsed.code
    });
  }

  render() {
    let component = <ConfirmAppointmentContainerNoConsent {...this.props} />

    if (this.props.schedule.appoinment.show_consent) {
      component = <ConfirmAppointmentContainerConsent {...this.props} />
    }

    return component;
  }
}

const mapStateToProps = (state) => {
  return {
    schedule: state.schedule
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(scheduleActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAppointmentContainer)