import { history } from '../store.js'

export const forgotPasswordRequest = () => {
  return {
    type: "FORGOT_PASSWORD_REQUEST"
  }
}
export const forgotPasswordSuccess = (accountType) => {
  return {
    type: "FORGOT_PASSWORD_SUCCESS"
  }
}

export const forgotPasswordFailure = (errors) => {
  return {
    type: "FORGOT_PASSWORD_FAILURE",
    payload: errors
  }
}

export const forgotPassword = (data) => {
  return (dispatch) => {
    dispatch(forgotPasswordRequest())
    return fetch("/accounts/password", {
      method: "POST",
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(data)
    })
      .then(response => {
        if (!response.ok) {
          throw response
        } else {
          return response.json()
        }
      })
      .then(() => {
        dispatch(forgotPasswordSuccess())
      })
      .catch(response =>
        response.json()
          .then((json) =>
            dispatch(forgotPasswordFailure(json.errors))
          )
      );
  };
}

export const resetPasswordRequest = () => {
  return {
    type: "RESET_PASSWORD_REQUEST"
  }
}
export const resetPasswordSuccess = (accountType) => {
  return {
    type: "RESET_PASSWORD_SUCCESS"
  }
}

export const resetPasswordFailure = (errors) => {
  return {
    type: "RESET_PASSWORD_FAILURE",
    payload: errors
  }
}

export const resetPassword = (data) => {
  return (dispatch) => {
    dispatch(resetPasswordRequest())
    return fetch("/accounts/password", {
      method: "PUT",
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(data)
    })
    .then(response => {
      if (!response.ok) {
        throw response
      } else {
        return response.json()
      }
    })
    .then(() => {
      dispatch(resetPasswordSuccess())
    })
    .catch(response =>
      response.json()
        .then((json) =>
          dispatch(resetPasswordFailure(json.errors))
        )
    );
  };
}
