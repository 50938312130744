import React from 'react';
import { Button, Modal, Badge } from 'react-bootstrap';
import {connect} from "react-redux";
import {actionTexts, approvalReasonsText, statusTexts} from "../../../constant";

import '../style.css';

class ArchivedLetterApplicationModal extends React.Component {
	constructor() {
    super()
		this.state = {
    	showModal: false,
      reasonForApproval: [],
			questionInfo: []
    };
	}

	componentDidMount() {
    window.scrollTo(0, 0);
		const { condition } = this.props.letterApplication;

		this.setState({
			questionInfo: this.filterQuestionInfo(condition),
		});
	}

  componentDidUpdate(prevProps, prevState) {
	  if (prevProps.dashboard.isFetching === true
			&& this.props.dashboard.isFetching === false
			&& (this.state.showModal === true || this.state.showReasonModal === true || this.state.showNotesModal === true)) {
			this.setState({ showModal: false, showReasonModal: false, showNotesModal: false });
		}
  }

	filterQuestionInfo(info) {
		let question = JSON.parse(info);
		const questionInfo = [];
		if (question) {
      Object.keys(question.checkableQuestions).map((questionKey, index) => {
				questionInfo.push({
					title: `${questionKey[0].toUpperCase()}${questionKey.slice(1)}`,
					value: question.checkableQuestions[questionKey]
				});
      });

      Object.keys(question).map((additionalQuestionKey, index) => {
				let value = question[additionalQuestionKey];
        if (additionalQuestionKey !== 'checkableQuestions') {
					if (typeof question[additionalQuestionKey] === 'boolean') {
						value = question[additionalQuestionKey] ? 'Yes' : 'No';
					}
					questionInfo.push({
						title: `${additionalQuestionKey[0].toUpperCase()}${additionalQuestionKey.slice(1)}`,
						value
					});
        }
      });
		}
		return questionInfo;
	}

	handleClose = () => {
		this.setState({ showModal: false });
	}

	handleShow = () => {
		this.setState({ showModal: true });
	}

	renderQuestionContent = () => {
		const { questionInfo } = this.state;		
		return (
			<div className='row'>
				{
					questionInfo && questionInfo.map((question, index) => (
						<div className='col-sm-6 col-12' key={index}>
							<div className="d-flex justify-content-between">
								<span className="title">{question.title}</span>
								{
									typeof question.value === "string" ?
										<span className="text-muted">{question.value}</span> :
										<span className="text-muted">{question.value.frequency}&nbsp;-&nbsp;{question.value.duration}</span>
								}
							</div>
						</div>
					))
				}
			</div>			
		);
	}

	render() {
    const { status, reason, letter_application_actions, product, patient, animals } = this.props.letterApplication;

		return (
			<div className="text-right">
				<Button variant="primary" size="sm" onClick={this.handleShow}>
					View
				</Button>

				<Modal show={this.state.showModal} onHide={this.handleClose} size="lg">
					<Modal.Header closeButton>
						<Modal.Title className="applicant-type">Letter: {product}</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<div className=''>
							{
							<div className="pull-right">
								<Badge pill>
									{statusTexts[status]}
								</Badge>
							</div>
							}

							<div className="p-md-3">
								<h6 className="text-muted">Patient Info</h6>
								<hr className="header-underline mb-3" />
								<div className='row'>
									<div className='col-sm-6 col-12'>
										<div className="d-flex justify-content-between">
											<span className="title">Name</span>
											<span className="text-muted">{patient.first_name} {patient.last_name}</span>
										</div>
									</div>

									<div className='col-sm-6 col-12'>
										<div className="d-flex justify-content-between">
											<span className="title">Email</span>
											<span className="text-muted">{patient.email}</span>
										</div>
									</div>

									<div className='col-sm-6 col-12'>
										<div className="d-flex justify-content-between">
											<span className="title">Birthdate</span>
											<span className="text-muted">{patient.profile.birthdate}</span>
										</div>
									</div>

									<div className='col-sm-6 col-12'>
										<div className="d-flex justify-content-between">
											<span className="title">State</span>
											<span className="text-muted">{patient.profile.address_state}</span>
										</div>
									</div>

									<div className='col-sm-6 col-12'>
										<div className="d-flex justify-content-between">
											<span className="title">Phone Number</span>
											<span className="text-muted">{patient.profile.phone}</span>
										</div>
									</div>
								</div>
							</div>
							<hr />
							
							<div className="p-0 p-md-3">
								<h6 className="text-muted">Question Info</h6>
								<hr className="header-underline mb-3" />
								{this.renderQuestionContent()}
							</div>
							<hr />

							<div className="p-0 p-md-3">
								<h6 className="text-muted">Animal Info</h6>
								<hr className="header-underline mb-3" />
								<div className="mb-1">
									{
										animals.map((animalInfo, index) =>
											(<div className='row' key={index}>
												{index ? <span className="col-12 title text-muted">Animal #{index+1}</span> : ''}

												<div className='col-sm-6 col-12'>
													<div className="d-flex justify-content-between">
														<span className="title">Name</span>
														<span className="text-muted">{animalInfo.name}</span>
													</div>
												</div>

												<div className='col-sm-6 col-12'>
													<div className="d-flex justify-content-between">
														<span className="title">Type</span>
														<span className="text-muted">{animalInfo.species}</span>
													</div>
												</div>

												<div className='col-sm-6 col-12'>
													<div className="d-flex justify-content-between">
														<span className="title">Breed</span>
														<span className="text-muted">{animalInfo.breed}</span>
													</div>
												</div>

												<div className='col-sm-6 col-12'>
													<div className="d-flex justify-content-between">
														<span className="title">Age</span>
														<span className="text-muted">{animalInfo.age_years ? `${animalInfo.age_years} years` : ''} {animalInfo.age_months ? `${animalInfo.age_months} months` : ''}</span>
													</div>
												</div>

												<div className='col-sm-6 col-12'>
													<div className="d-flex justify-content-between">
														<span className="title">Weight</span>
														<span className="text-muted">{animalInfo.wght}</span>
													</div>
												</div>

												<div className='col-sm-6 col-12'>
													<div className="d-flex justify-content-between">
														<span className="title">Sex</span>
														<span className="text-muted">{animalInfo.gender}</span>
													</div>
												</div>

												<div className='col-sm-12 col-12'>
													<div className="d-flex flex-column">
														<span className="title">Support</span>
														<span className="text-muted">{animalInfo.support}</span>
													</div>
												</div>
											</div>)
										)
									}
								</div>
							</div>

							<hr />

							{
								status !== 'assigned' &&
								<div className="p-0 p-md-3">
									<div className="reason-container">
										<label className="reason-title mr-2 title">
											Approval Reason:
										</label>
										<p className="reason-value">{
											reason &&
											reason.split(',').map(rstr =>
												approvalReasonsText[rstr]
											).join(', ')
										}</p>
									</div>
									<div className="reason-container">
										<label className="reason-title mr-2 title">
											Notes:
										</label>
										<p>
											<span className="reason-value">{
												letter_application_actions.filter((action) =>
													['add_notes', 'approve_application', 'deny_application'].includes(action.action)
												).map(action => {
													return <span key={action.id}>{new Date(action.done_at).toLocaleDateString("en-US")} - {actionTexts[action.action]}: {action.notes}<br/></span>
												})
											}</span>
										</p>
									</div>
								</div>
							}
						</div>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
  return {
    dashboard: state.dashboard
  }
}

export default connect(mapStateToProps, null)(ArchivedLetterApplicationModal)
