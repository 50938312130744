import React from 'react';
import {Button, Form, FormControl} from 'react-bootstrap';
import InputMask from 'react-input-mask';
import {userQuestionDurationOptions} from "../constant";

export const FieldInput = ({input, meta, type, placeholder, min, max, label, disabled, className, autocomplete}) => {
  return (
    <Form.Group className={className}>
      {label &&
      <Form.Label>{label}</Form.Label>
      }

      <FormControl
        type={type}
        autoComplete={autocomplete}
        placeholder={placeholder}
        min={min}
        max={max}
        value={input.value}
        disabled={disabled}
        onChange={input.onChange}
        className={meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}/>

      {meta.touched && meta.error &&
      <Form.Control.Feedback type="invalid">
        {meta.error}
      </Form.Control.Feedback>
      }
    </Form.Group>
  )
}

export const FieldPhoneInput = ({input, meta, type, placeholder, min, max, label, className}) => {
  return (
    <Form.Group className={className}>
      {label &&
      <Form.Label>{label}</Form.Label>
      }

      <InputMask
        mask="(999) 999-9999"
        placeholder={placeholder}
        value={input.value}
        onChange={input.onChange}
        className={meta.touched ? (meta.error ? "is-invalid form-control" : "is-valid form-control") : "form-control"}/>

      {meta.touched && meta.error &&
      <Form.Control.Feedback type="invalid">
        {meta.error}
      </Form.Control.Feedback>
      }
    </Form.Group>
  )
}

export const FieldSelect = ({input, meta, type, placeholder, options, label, className, defaultValue}) => {
  return (
    <Form.Group className={className}>
      {label &&
      <Form.Label>{label}</Form.Label>
      }

      <FormControl
        as="select"
        value={input.value}
        onChange={input.onChange}
        defaultValue={defaultValue}
        className={"custom-select " + (meta.touched ? (meta.error ? "is-invalid" : "is-valid") : "")}
      >
        {options}
      </FormControl>

      {meta.touched && meta.error &&
      <Form.Control.Feedback type="invalid">
        {meta.error}
      </Form.Control.Feedback>
      }
    </Form.Group>
  )
}

export const FieldTextArea = ({input, meta, type, placeholder, rows, maxLength, label, className}) => {
  return (
    <Form.Group className={className}>
      {label &&
      <Form.Label>{label}</Form.Label>
      }

      <FormControl
        as="textarea" rows={rows} maxLength={maxLength}
        value={input.value}
        onChange={input.onChange}
        placeholder={placeholder}
        className={meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}
      />

      {meta.touched && meta.error &&
      <Form.Control.Feedback type="invalid">
        {meta.error}
      </Form.Control.Feedback>
      }
    </Form.Group>
  )
}

export const FieldBooleanRadio = ({input, meta, type, placeholder, min, max, label, className}) => {
  return (
    <Form.Group className={className}>
      {label &&
      <Form.Label>{label}</Form.Label>
      }

      <div className="row mx-gutters-1">
        <div className="col-6">
          <Button variant={input.value === false ? "primary" : "outline-primary"} className="btn-block"
                  onClick={() => {input.onChange(false)}}>
            No
          </Button>
        </div>

        <div className="col-6">
          <Button variant={input.value === true ? "primary" : "outline-primary"} className="btn-block"
                  onClick={() => {input.onChange(true)}}>
            Yes
          </Button>
        </div>
      </div>

      {meta.touched && meta.invalid &&
        <p className="text-danger text-small">{meta.error}</p>
      }
    </Form.Group>
  )
}

export const FieldRadio = ({input, meta, type, placeholder, min, max, label, className, disabled}) => {
  return (
    <Form.Group className={className}>
      {label &&
      <Form.Label>{label}</Form.Label>
      }

      <div className={"custom-radio-buttons-group " + (meta.touched ? (meta.error ? "is-invalid" : "is-valid") : "")}>
        <div className={"list-group list-group-horizontal custom-radio "}>{
          userQuestionDurationOptions.map((option, index) => (
            <a onClick={() => {input.onChange(option.value)}} className={("list-group-item " + (option.value === input.value ? "active " : " ") + (disabled ? "disabled" : ''))} key={index}>{option.name}</a>
          ))
        }
        </div>
      </div>

      {meta.touched && meta.invalid &&
      <p className="text-danger text-small">{meta.error}</p>
      }
    </Form.Group>
  )
}
