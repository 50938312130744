import {
  SUBMIT_APPOINTMENT_REQUEST,
  SUBMIT_APPOINTMENT_SUCCESS,
  SUBMIT_APPOINTMENT_FAILURE,
} from '../actions/appointmentActionCreators';

const initialState = {
  isFetching: false,
  submitting: false,
  serviceTypes: [],
  errors: null
};

const appointmentReducer = (state = initialState, action) => {
  const localStorageValue = JSON.parse(localStorage.getItem('ESA'));

  switch(action.type){
    case SUBMIT_APPOINTMENT_REQUEST:
      return {...state, errors: null, submitting: true}
    case SUBMIT_APPOINTMENT_FAILURE:
      return {...state, errors: action.payload, submitting: false}
    case SUBMIT_APPOINTMENT_SUCCESS:
      return {...state, submitting: false}
    case "FETCH_SERVICE_TYPES_REQUEST":
      return {...state, isFetching: true}
    case "FETCH_SERVICE_TYPES_SUCCESS":
      return {...state, serviceTypes: [...action.payload], isFetching: false}
    case "FETCH_SERVICE_TYPES_FAILURE":
      return {...state, errors: [...action.payload]}
    default:
      return state
  }
}

export default appointmentReducer
