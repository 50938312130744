import { history } from '../store.js'
import {toast} from "react-toastify";
import axios from "axios";

export const SUBMIT_CONTACT_REQUEST = 'SUBMIT_CONTACT_REQUEST';
export const SUBMIT_CONTACT_SUCCESS = 'SUBMIT_CONTACT_SUCCESS';
export const SUBMIT_CONTACT_FAILURE = 'SUBMIT_CONTACT_FAILURE';

export const submitContactRequest = () => {
  return {
    type: SUBMIT_CONTACT_REQUEST
  }
}

export const submitContactSuccess = () => {
  return {
    type: SUBMIT_CONTACT_SUCCESS
  }
}

export const submitContactFailure = () => {
  return {
    type: SUBMIT_CONTACT_FAILURE
  }
}

export const submitContact = (message) => {
  return (dispatch) => {
    dispatch(submitContactRequest())
    return axios.post("/contact_us", {
      ...message
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      dispatch(submitContactSuccess())
    })
    .catch(e => {
      dispatch(submitContactFailure(e.response.data))
      toast.error(e.response.data.errors);
    });
  }
}
