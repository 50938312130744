import {
  SUBMIT_PAYMENT_REQUEST,
  SUBMIT_PAYMENT_SUCCESS,
  SUBMIT_PAYMENT_FAILURE,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CHECK_OUT,
  ADD_USER_INFO
} from '../actions/paymentActionCreators';
import { ESAStore } from '../constant';

const initialState = {
  submitting: false,
  errors: null,
  productId: ESAStore.product.id,
  checkout: null,
  userInfo: ESAStore.userInfo
};

const paymentReducer = (state = initialState, action) => {
  const localStorageValue = JSON.parse(localStorage.getItem('ESA'));

  switch(action.type){
    case SUBMIT_PAYMENT_REQUEST:
      return {...state, errors: null, submitting: true}
    case SUBMIT_PAYMENT_FAILURE:
      return {...state, errors: action.payload, submitting: false}
    case SUBMIT_PAYMENT_SUCCESS:
      return {...state, submitting: false}
    case ADD_TO_CART:
      localStorageValue.product.id = action.payload;
      localStorage.setItem('ESA', JSON.stringify(localStorageValue));
      return {...state, productId: action.payload}
    case REMOVE_FROM_CART:
      localStorageValue.product.id = null;
      localStorage.setItem('ESA', JSON.stringify(localStorageValue));
      return {...state, productId: null}
    case CHECK_OUT:
      return {...state, checkout: action.payload}
    case ADD_USER_INFO:
      delete action.payload.validEmail;
      localStorageValue.userInfo = {
        ...localStorageValue.userInfo,
        ...action.payload
      };
      localStorage.setItem('ESA', JSON.stringify(localStorageValue));
      return {...state, userInfo: { ...state.userInfo, ...action.payload}}
    default:
      return state
  }
}

export default paymentReducer
