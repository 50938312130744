import 'es6-set/implement';
import 'es6-map/implement';
import ReactOnRails from 'react-on-rails';

import App from '../bundles/Main/App';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  App,
});
