import { history } from '../store.js'
import {toast} from "react-toastify";
import axios from "axios";
import {SubmissionError} from "redux-form";
import {submitPaymentFailure, submitPaymentSuccess} from "./paymentActionCreators";

const requestHeaders = () => {
  return {'AUTHORIZATION': `Bearer ${sessionStorage.jwt}`}
}

export const fetchLetterApplicationsRequest = () => {
  return {
    type: "FETCH_LETTER_APPLICATIONS_REQUEST"
  }
}

export const fetchLetterApplicationsSuccess = (letterApplications) => {
  return {
    type: "FETCH_LETTER_APPLICATIONS_SUCCESS",
    payload: letterApplications
  }
}

export const fetchLetterApplicationsFailure = (errors) => {
  return {
    type: "FETCH_LETTER_APPLICATIONS_FAILURE",
    payload: errors
  }
}

export const fetchScheduleSuccess = (schedule) => {
  return {
    type: "FETCH_SCHEDULE_SUCCESS",
    payload: schedule
  }
}

export const fetchScheduleFailure = (errors) => {
  return {
    type: "FETCH_SCHEDULE_FAILURE",
    payload: errors
  }
}

export const fetchArchivedLetterApplicationsRequest = () => {
  return {
    type: "FETCH_ARCHIVED_LETTER_APPLICATIONS_REQUEST"
  }
}

export const fetchArchivedLetterApplicationsSuccess = (letterApplications) => {
  return {
    type: "FETCH_ARCHIVED_LETTER_APPLICATIONS_SUCCESS",
    payload: letterApplications
  }
}

export const fetchArchivedLetterApplicationsFailure = (errors) => {
  return {
    type: "FETCH_ARCHIVED_LETTER_APPLICATIONS_FAILURE",
    payload: errors
  }
}

export const fetchArchivedAppointmentsRequest = () => {
  return {
    type: "FETCH_ARCHIVED_APPOINTMENTS_REQUEST"
  }
}

export const fetchArchivedAppointmentsSuccess = (appointments) => {
  return {
    type: "FETCH_ARCHIVED_APPOINTMENTS_SUCCESS",
    payload: appointments
  }
}

export const fetchArchivedAppointmentsFailure = (errors) => {
  return {
    type: "FETCH_ARCHIVED_APPOINTMENTS_FAILURE",
    payload: errors
  }
}

export const approveLetterApplicationRequest = () => {
  return {
    type: "APPROVE_LETTER_APPLICATION_REQUEST"
  }
}

export const approveLetterApplicationSuccess = (letterApplication) => {
  return {
    type: "APPROVE_LETTER_APPLICATION_SUCCESS",
    payload: letterApplication
  }
}

export const approveLetterApplicationFailure = (errors) => {
  return {
    type: "APPROVE_LETTER_APPLICATION_FAILURE",
    payload: errors
  }
}

export const denyLetterApplicationRequest = () => {
  return {
    type: "DENY_LETTER_APPLICATION_REQUEST"
  }
}

export const denyLetterApplicationSuccess = (letterApplication) => {
  return {
    type: "DENY_LETTER_APPLICATION_SUCCESS",
    payload: letterApplication
  }
}

export const denyLetterApplicationFailure = (errors) => {
  return {
    type: "DENY_LETTER_APPLICATION_FAILURE",
    payload: errors
  }
}

export const unableLetterApplicationSuccess = (letterApplication) => {
  return {
    type: "UNABLE_LETTER_APPLICATION_SUCCESS",
    payload: letterApplication
  }
}

export const unableLetterApplicationFailure = (errors) => {
  return {
    type: "UNABLE_LETTER_APPLICATION_FAILURE",
    payload: errors
  }
}

export const fetchServiceTransactionsRequest = () => {
  return {
    type: "FETCH_APPOINTMENTS_REQUEST"
  }
}

export const fetchServiceTransactionsSuccess = (appointments) => {
  return {
    type: "FETCH_APPOINTMENTS_SUCCESS",
    payload: appointments
  }
}

export const fetchServiceTransactionsFailure = (errors) => {
  return {
    type: "FETCH_APPOINTMENTS_FAILURE",
    payload: errors
  }
}

export const submitServiceTransactionNotesRequest = () => {
  return {
    type: "SUBMIT_APPOINTMENT_NOTES_REQUEST"
  }
}

export const submitServiceTransactionNotesSuccess = (appointment) => {
  return {
    type: "SUBMIT_APPOINTMENT_NOTES_SUCCESS",
    payload: appointment
  }
}

export const submitServiceTransactionNotesFailure = (errors) => {
  return {
    type: "SUBMIT_APPOINTMENT_NOTES_FAILURE",
    payload: errors
  }
}

export const finishServiceTransactionRequest = () => {
  return {
    type: "FINISH_APPOINTMENT_SERVICE_REQUEST"
  }
}

export const finishServiceTransactionSuccess = (appointment) => {
  return {
    type: "FINISH_APPOINTMENT_SERVICE_SUCCESS",
    payload: appointment
  }
}

export const finishServiceTransactionFailure = (errors) => {
  return {
    type: "FINISH_APPOINTMENT_SERVICE_FAILURE",
    payload: errors
  }
}

export const submitServiceTransactionInstructionsRequest = () => {
  return {
    type: "SUBMIT_APPOINTMENT_TIMES_REQUEST"
  }
}

export const submitServiceTransactionInstructionsSuccess = (appointment) => {
  return {
    type: "SUBMIT_APPOINTMENT_TIMES_SUCCESS",
    payload: appointment
  }
}

export const submitServiceTransactionInstructionsFailure = (errors) => {
  return {
    type: "SUBMIT_APPOINTMENT_TIMES_FAILURE",
    payload: errors
  }
}

export const submitOrderNotesRequest = () => {
  return {
    type: "SUBMIT_ARCHIVED_APPLIACTION_NOTES_REQUEST"
  }
}

export const submitOrderNotesSuccess = (letterApplication) => {
  return {
    type: "SUBMIT_ARCHIVED_APPLIACTION_NOTES_SUCCESS",
    payload: letterApplication
  }
}

export const submitOrderNotesFailure = (errors) => {
  return {
    type: "SUBMIT_ARCHIVED_APPLIACTION_NOTES_FAILURE",
    payload: errors
  }
}

export const fetchLetterApplications = () => {
  return (dispatch) => {
    dispatch(fetchLetterApplicationsRequest())
    return fetch("/letter_applications", {
      method: "GET",
      headers: new Headers(requestHeaders())
    })
    .then(response => {
      if (!response.ok) {
        throw response
      } else {
        return response.json()
      }
    })
    .then(json => {
      dispatch(fetchLetterApplicationsSuccess(json))
    })
    .catch(response =>
      response.json()
      .then((json) =>
        dispatch(fetchLetterApplicationsFailure(json.errors))
      )
    );
  };
}

export const fetchServiceTransactions = () => {
  return (dispatch) => {
    dispatch(fetchServiceTransactionsRequest())
    return fetch("/service_transactions", {
      method: "GET",
      headers: new Headers(requestHeaders())
    })
      .then(response => {
        if (!response.ok) {
          throw response
        } else {
          return response.json()
        }
      })
      .then(json => {
        dispatch(fetchServiceTransactionsSuccess(json))
      })
      .catch(response =>
        response.json()
          .then((json) =>
            dispatch(fetchServiceTransactionsFailure(json.errors))
          )
      );
  };
}

export const fetchSchedule = () => {
  return (dispatch) => {
    dispatch(fetchServiceTransactionsRequest())
    return fetch("/schedules", {
      method: "GET",
      headers: new Headers(requestHeaders())
    })
    .then(response => {
      if (!response.ok) {
        throw response
      } else {
        return response.json()
      }
    })
    .then(json => {
      dispatch(fetchScheduleSuccess(json))
    })
    .catch(response =>
      response.json()
      .then((json) =>
        dispatch(fetchScheduleFailure(json.errors))
      )
    );
  };
}

export const submitServiceTransactionNotes = (transactionId, notes) => {
  return (dispatch) => {
    dispatch(submitServiceTransactionNotesRequest())
    return axios.post(`/service_transactions/${transactionId}/submit_notes`, {
      id: transactionId,
      notes: notes
    }, {
      headers: {
        ...requestHeaders(),
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        dispatch(submitServiceTransactionNotesSuccess(response.data))
        toast.info("Transactional note added!");
      })
      .catch(e => {
        dispatch(submitServiceTransactionNotesFailure(e.response.data))
        toast.error(e.response.data.errors);
      });
  }
}

export const finishServiceTransaction = (transactionId) => {
  return (dispatch) => {
    dispatch(finishServiceTransactionRequest())
    return axios.post(`/service_transactions/${transactionId}/finish_service`, {
      id: transactionId,
      finished: true
    }, {
      headers: {
        ...requestHeaders(),
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        dispatch(finishServiceTransactionSuccess(response.data))
        toast.info("Service transaction finished!");
      })
      .catch(e => {
        dispatch(finishServiceTransactionFailure(e.response.data))
        toast.error(e.response.data.errors);
      });
  }
}

export const submitServiceTransactionInstructions = (transactionId, appTimes) => {
  return (dispatch) => {
    dispatch(submitServiceTransactionInstructionsRequest())
    return axios.patch(`/service_transactions/${transactionId}/`, {
      id: transactionId,
      notes: appTimes
    }, {
      headers: {
        ...requestHeaders(),
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        dispatch(submitServiceTransactionInstructionsSuccess(response.data))
        toast.info("Instructions updated!");
      })
      .catch(e => {
        dispatch(submitServiceTransactionInstructionsFailure(e.response.data))
        toast.error(e.response.data.errors);
      });
  }
}

export const submitOrderNotes = (orderId, notes) => {
  return (dispatch) => {
    dispatch(submitOrderNotesRequest())
    return axios.post(`/letter_applications/${orderId}/submit_notes`, {
      id: orderId,
      notes: notes
    }, {
      headers: {
        ...requestHeaders(),
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        dispatch(submitOrderNotesSuccess(response.data))
        toast.info("New case note added!");
      })
      .catch(e => {
        dispatch(submitOrderNotesFailure(e.response.data))
        toast.error(e.response.data.errors);
      });
  }
}

export const fetchArchivedLetterApplications = (page, start, end, keyword, approved = false) => {
  return (dispatch) => {
    dispatch(fetchArchivedLetterApplicationsRequest())
    return fetch(`/letter_applications/archived?page=${page}&start=${start != null ? start : ''}&end=${end != null ? end : ''}&keyword=${keyword ? keyword : ''}&approved=${approved}`, {
      method: "GET",
      headers: new Headers(requestHeaders())
    })
      .then(response => {
        if (!response.ok) {
          throw response
        } else {
          return response.json()
        }
      })
      .then(json => {
        dispatch(fetchArchivedLetterApplicationsSuccess(json))
      })
      .catch(response =>
        response.json()
          .then((json) =>
            dispatch(fetchArchivedLetterApplicationsFailure(json.errors))
          )
      );
  };
}

export const approveLetterApplication = (letterApplication) => {
  return (dispatch) => {
    dispatch(approveLetterApplicationRequest())
    return axios.patch(`/letter_applications/${letterApplication.id}`, {
      letter_application: {
        status: "approved",
        reason: letterApplication.reason.toString(),
        notes: letterApplication.notes.toString(),
        sign_name: letterApplication.signature.toString()
      }
    }, {
      headers: {
        ...requestHeaders(),
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      dispatch(approveLetterApplicationSuccess(response.data))
      toast.info("The application approved!");
    })
    .catch(e => {
      dispatch(approveLetterApplicationFailure(e.response.data))
      toast.error(e.response.data.errors);
    });
  }
}

export const denyLetterApplication = (letterApplication) => {
  return (dispatch) => {
    dispatch(denyLetterApplicationRequest())
    return axios.patch(`/letter_applications/${letterApplication.id}`, {
      letter_application: {
        status: "denied",
        notes: letterApplication.notes.toString()
      }
    }, {
      headers: {
        ...requestHeaders(),
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      dispatch(denyLetterApplicationSuccess(response.data))
      toast.info("The application denied!");
    })
    .catch(e => {
      dispatch(denyLetterApplicationFailure(e.response.data))
      toast.error(e.response.data.errors);
    })
  }
}

export const unableLetterApplication = (letterApplication) => {
  return (dispatch) => {
    dispatch(denyLetterApplicationRequest())
    return axios.patch(`/letter_applications/${letterApplication.id}`, {
      letter_application: {
        flag: "no-answer",
        notes: letterApplication.notes.toString()
      }
    }, {
      headers: {
        ...requestHeaders(),
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      dispatch(unableLetterApplicationSuccess(response.data))
      toast.info("The application marked as unable to complete!");
    })
    .catch(e => {
      dispatch(unableLetterApplicationFailure(e.response.data))
      toast.error(e.response.data.errors);
    })
  }
}

export const fetchArchivedAppointments = (page, start, end, keyword) => {
  return (dispatch) => {
    dispatch(fetchArchivedAppointmentsRequest())
    return fetch(`/service_transactions/archived?page=${page}&start=${start != null ? start : ''}&end=${end != null ? end : ''}&keyword=${keyword ? keyword : ''}`, {
      method: "GET",
      headers: new Headers(requestHeaders())
    })
      .then(response => {
        if (!response.ok) {
          throw response
        } else {
          return response.json()
        }
      })
      .then(json => {
        dispatch(fetchArchivedAppointmentsSuccess(json))
      })
      .catch(response =>
        response.json()
          .then((json) =>
            dispatch(fetchArchivedAppointmentsFailure(json.errors))
          )
      );
  };
}
