const sessionReducer = (state = {logged_in: !!sessionStorage.jwt, logging_in: false, errors: []}, action) => {
  switch(action.type){
    case "LOGIN_REQUEST":
      return {...state, logging_in: true}
    case "REGISTER_SUCCESS":
    case "LOGOUT_ACCOUNT":
      return {...state, logged_in: !!sessionStorage.jwt}
    case "LOGIN_SUCCESS":
      return {...state, logged_in: !!sessionStorage.jwt, logging_in: false, errors: []}
    case "LOGIN_FAILURE":
      return {...state, logging_in: false, errors: [action.payload]}
    default:
      return state
  }
}

export default sessionReducer
