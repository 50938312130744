import React from 'react';
import { Button, Modal, FormControl, Badge } from 'react-bootstrap';
import Select from 'react-select';
import { approvalReasons } from '../../../constant';
import '../style.css';
import {connect} from "react-redux";
import moment from 'moment';

import ConfirmAppointmentText from '../../../containers/ConfirmAppointment/ConfirmAppointmentText';

class LetterApplicationModal extends React.Component {
	constructor() {
    	super()
		this.state = {
    		showModal: false,
			showReasonModal: false,
			denyNotes: "",
      		reasonForApproval: [],
			showNotesModal: false,
			approvalNotes: "",
			approvalSignature: "",
			showUnableModal: false,
			unableNotes: "",
			questionInfo: [],
			showConsent: false
    	};
	}

	componentDidMount() {
    	window.scrollTo(0, 0);
		const { condition } = this.props.letterApplication;

		this.setState({
			questionInfo: this.filterQuestionInfo(condition),
		});
	}

  componentDidUpdate(prevProps, prevState) {
	  if (prevProps.dashboard.isFetching === true
			&& this.props.dashboard.isFetching === false
			&& (this.state.showModal === true || this.state.showReasonModal === true || this.state.showNotesModal === true)) {
			this.setState({ showModal: false, showReasonModal: false, showNotesModal: false });
    }

    if (prevProps.scheduleSelected != this.props.scheduleSelected) {
      if (this.props.scheduleSelected) this.handleShow();
    }
  }

	filterQuestionInfo(info) {
		let question = JSON.parse(info);
		const questionInfo = [];
		if (question) {
		Object.keys(question.checkableQuestions).map((questionKey, index) => {
					questionInfo.push({
						title: `${questionKey[0].toUpperCase()}${questionKey.slice(1)}`,
						value: question.checkableQuestions[questionKey]
					});
		});

      	Object.keys(question).map((additionalQuestionKey, index) => {
				let value = question[additionalQuestionKey];
				if (additionalQuestionKey !== 'checkableQuestions') {
							if (typeof question[additionalQuestionKey] === 'boolean') {
								value = question[additionalQuestionKey] ? 'Yes' : 'No';
							}
							questionInfo.push({
								title: `${additionalQuestionKey[0].toUpperCase()}${additionalQuestionKey.slice(1)}`,
								value
							});
				}
      		});
		}
		return questionInfo;
	}

	handleClose = () => {
    	this.setState({ showModal: false });
    	this.props.scheduleClose();
	}

	handleShow = () => {
		this.setState({ showModal: true });
	}

	handleReasonModalClose = () => {
		this.setState({ showReasonModal: false });
	}

	handleReasonModalShow = () => {
		this.setState({ showModal: false, showReasonModal: true, showUnableModal: false });
	}

	handleUnableModalShow = () => {
		this.setState({ showModal: false, showReasonModal: false, showUnableModal: true });
	}

	handleNotesModalClose = () => {
		this.setState({ showNotesModal: false });
	}

	handleNotesModalShow = () => {
		this.setState({ showModal: false, showNotesModal: true });
	}

	handleUnableModalClose = () => {
		this.setState({ showUnableModal: false });
	}

	onChangeApprovalReason = (selectedOption) => {
		this.setState({ reasonForApproval: selectedOption });
	}

	onChangeDenyReasonNotes = (value) => {
		this.setState({
      		denyNotes: value
		});
	}

	onChangeUnableReasonNotes = (value) => {
		this.setState({
      		unableNotes: value
		});
  	}

	onChangeApprovalNotes = (value) => {
		this.setState({
			approvalNotes: value
		});
	}

	onChangeSignature = (value) => {
		this.setState({
			approvalSignature: value
		});
	}

	approveApplication = () => {
		const { reasonForApproval, approvalNotes, approvalSignature } = this.state;
		var approvedReasons = [];
		reasonForApproval.map(reason => {
			approvedReasons.push(reason.value);
		});

		this.props.onApprove({
			id: this.props.letterApplication.id,
			reason: approvedReasons,
			notes: approvalNotes,
			signature: approvalSignature
		});
	}

	denyApplication = () => {
		const { denyNotes } = this.state;
		this.props.onDeny({
			id: this.props.letterApplication.id,
			notes: denyNotes
		});
	}

	unableApplication = () => {
		const { unableNotes } = this.state;
		this.props.onUnable({
			id: this.props.letterApplication.id,
			notes: unableNotes
		});
	}

	renderQuestionContent = () => {
		const { questionInfo } = this.state;
		return (
			<div className='row'>
				{
					questionInfo && questionInfo.map((question, index) => (
						<div className='col-sm-6 col-12' key={index}>
							<div className="d-flex justify-content-between">
								<span className="title">{question.title}</span>
								{
									typeof question.value === "string" ?
									<span className="text-muted">{question.value}</span> :
									<span className="text-muted">{question.value.frequency}&nbsp;-&nbsp;{question.value.duration}</span>
								}
							</div>
						</div>
					))
				}
			</div>
		);
	}

	handleShowConsent = () => {
		this.setState({ showConsent: !this.state.showConsent });
	}

	render() {
		const { status, letter, reason, notes, product, patient, animals, appointment, schedules, letter_application_actions, documents_urls } = this.props.letterApplication;
		const { denyNotes, reasonForApproval, unableNotes } = this.state;
		let disabledApproveBtn = !reasonForApproval.length;

		const appointments = schedules.filter(el => el.confirmed == 1).map((el, i) => {
			return <div className='row' key={i}>
				<div className='col-sm-6 col-12'>
					<div className="d-flex justify-content-between">
						<span className="title">Date/Time</span>
						<span className="text-muted">{moment.utc(el.start_time).local().format("M/D/YYYY, h:mm a")}</span>
					</div>
				</div>
				<div className='col-sm-6 col-12'>
					<div className="d-flex justify-content-between">
						<span className="title">Consent</span>
						<span className="text-muted">{el.sign}</span>
					</div>
				</div>
				{el.sign_date && (
					<>
						<div className='col-sm-6 col-12'></div>
						<div className='col-sm-6 col-12'>
							<div className="d-flex justify-content-between">
								<span className="title">Consent Date</span>
								<span className="text-muted">{moment.utc(el.sign_date).local().format("M/D/YYYY, h:mm a")}</span>
							</div>
						</div>
					</>
				)}
			</div>
		});

		const flag_notes = letter_application_actions.filter(el => el.action === 'mark_as_no_answer').map((el, i) => {
			return <div className='col-sm-6 col-12' key={i}>
				<div className="d-flex justify-content-between">
					<span className="title">{moment(el.done_at).format("M/D/YYYY, h:mm a")}</span>
					<span className="text-muted">{el.notes}</span>
				</div>
			</div>;
		});

		return (
			<div className="text-right">
				<Button variant="primary" onClick={this.handleShow}>
					{ status === "assigned" ? "Review Case" : "View" }
				</Button>

				<Modal show={this.state.showModal} onHide={this.handleClose} size="lg">
					<Modal.Header closeButton>
						<Modal.Title className="applicant-type">Letter: {product}</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<div className=''>
							{status === "approved" &&
							<div className="pull-right">
								<Badge pill variant="success">
									APPROVED
								</Badge>
							</div>
							}
							{status === "denied" &&
							<div className="pull-right">
								<Badge pill variant="danger">
									DENIED
								</Badge>
							</div>
							}
							{status === "generated" &&
							<div className="pull-right">
								<Badge pill variant="warning">
									LETTERS UPLOADED
								</Badge>
							</div>
							}

							{this.props.dashboard.schedule_settings.legacy === false && appointments.length > 0 &&
								<div className="p-md-3">
									<h6 className="text-muted">Appointment {flag_notes.length > 0 ? '*' : ''}</h6>
									<hr className="header-underline mb-3" />
									{appointments}
								</div>
							}

              				{appointment !== null &&
								<div className="p-md-3">
									<h6 className="text-muted">Appointment {flag_notes.length > 0 ? '*' : ''}</h6>
									<hr className="header-underline mb-3" />
									<div className='row'>
										<div className='col-sm-6 col-12'>
											<div className="d-flex justify-content-between">
												<span className="title">Date/Time</span>
												<span className="text-muted">{moment.utc(appointment).local().format("M/D/YYYY, h:mm a")}</span>
											</div>
										</div>
									</div>
								</div>
							}

							{flag_notes.length > 0 &&
								<div className="p-md-3">
									<h6 className="text-muted">Notes</h6>
									<hr className="header-underline mb-3" />
									<div className='row'>
										{flag_notes}
									</div>
								</div>
							}

							<div className="p-md-3">
								<h6 className="text-muted">Patient Info</h6>
								<hr className="header-underline mb-3" />
								<div className='row'>
									<div className='col-sm-6 col-12'>
										<div className="d-flex justify-content-between">
											<span className="title">Name</span>
											<span className="text-muted">{patient.first_name} {patient.last_name}</span>
										</div>
									</div>

									<div className='col-sm-6 col-12'>
										<div className="d-flex justify-content-between">
											<span className="title">Email</span>
											<span className="text-muted">{patient.email}</span>
										</div>
									</div>

									<div className='col-sm-6 col-12'>
										<div className="d-flex justify-content-between">
											<span className="title">Birthdate</span>
											<span className="text-muted">{patient.profile.birthdate}</span>
										</div>
									</div>

									<div className='col-sm-6 col-12'>
										<div className="d-flex justify-content-between">
											<span className="title">State</span>
											<span className="text-muted">{patient.profile.address_state}</span>
										</div>
									</div>

									<div className='col-sm-6 col-12'>
										<div className="d-flex justify-content-between">
											<span className="title">Phone Number</span>
											<span className="text-muted">{patient.profile.phone}</span>
										</div>
									</div>
								</div>
							</div>
							<hr />

							<div className="p-0 p-md-3">
								<h6 className="text-muted">Question Info</h6>
								<hr className="header-underline mb-3" />
								{this.renderQuestionContent()}
							</div>
							<hr />

							<div className="p-0 p-md-3">
								<h6 className="text-muted">Animal Info</h6>
								<hr className="header-underline mb-3" />
								<div className="mb-1">
									{
										animals.map((animalInfo, index) =>
											(<div className='row' key={index}>
												{index ? <span className="col-12 title text-muted">Animal #{index+1}</span> : ''}

												<div className='col-sm-6 col-12'>
													<div className="d-flex justify-content-between">
														<span className="title">Name</span>
														<span className="text-muted">{animalInfo.name}</span>
													</div>
												</div>

												<div className='col-sm-6 col-12'>
													<div className="d-flex justify-content-between">
														<span className="title">Type</span>
														<span className="text-muted">{animalInfo.species}</span>
													</div>
												</div>

												<div className='col-sm-6 col-12'>
													<div className="d-flex justify-content-between">
														<span className="title">Breed</span>
														<span className="text-muted">{animalInfo.breed}</span>
													</div>
												</div>

												<div className='col-sm-6 col-12'>
													<div className="d-flex justify-content-between">
														<span className="title">Age</span>
														<span className="text-muted">{animalInfo.age_years ? `${animalInfo.age_years} years` : ''} {animalInfo.age_months ? `${animalInfo.age_months} months` : ''}</span>
													</div>
												</div>

												<div className='col-sm-6 col-12'>
													<div className="d-flex justify-content-between">
														<span className="title">Weight</span>
														<span className="text-muted">{animalInfo.wght}</span>
													</div>
												</div>

												<div className='col-sm-6 col-12'>
													<div className="d-flex justify-content-between">
														<span className="title">Sex</span>
														<span className="text-muted">{animalInfo.gender}</span>
													</div>
												</div>

												<div className='col-sm-12 col-12'>
													<div className="d-flex flex-column">
														<span className="title">Support</span>
														<span className="text-muted">{animalInfo.support}</span>
													</div>
												</div>
											</div>)
										)
									}
								</div>
							</div>

							{documents_urls.length > 0 && (
								<div className="p-md-3">
									<h6 className="text-muted">Documents</h6>
									<hr className="header-underline mb-3" />
									<div className='row'>
										<div className='col-12'>
											{documents_urls.map((document, i) => {
												return (
													<div className="d-flex justify-content-between" key={i}>
														<a
															href={document.url}
															className="text-muted"
															target='_blank'
														>{document.name}</a>
													</div>
												)
											})}
										</div>
									</div>
								</div>
							)}

							{this.props.dashboard.schedule_settings.show_consent === true && appointments.length > 0 && (
								<div className="p-md-3">
									<h6 className="text-muted">Consent Form</h6>
									<hr className="header-underline mb-3" />
									<div className="row mb-3">
										<div className="col-12">
											<a className="text-muted" onClick={this.handleShowConsent} href="#">
												{this.state.showConsent ? 'Hide' : 'Show'} Consent Form
											</a>
										</div>
									</div>
									{this.state.showConsent && <ConfirmAppointmentText />}
								</div>
							)}
						</div>
					</Modal.Body>

					<Modal.Footer className="d-flex flex-column">
						{(status === "approved" || status === "denied") &&
						<p className="align-self-start mb-0 text-muted text-small">*You can approve or deny the archived order again.</p>
						}
						<div className="d-flex justify-content-between align-items-lg-end w-100" >
							<div className="w-100">
								<Select
									value={reasonForApproval}
									onChange={this.onChangeApprovalReason}
									options={approvalReasons}
									isMulti='true'
									classNamePrefix="Select"
									className='react-select-container'
									placeholder='Select Approval Reasons'
								/>
							</div>
						</div>
						<div className="w-100 mt-3 d-flex justify-content-between">
							<div>
								<Button
									variant="primary"
									disabled={disabledApproveBtn}
									className="mr-2 aa-primary"
									onClick={this.handleNotesModalShow}
									>
									Approve
								</Button>
								{ (status === "approved" && letter) ? (
										<Button
											variant="outline-primary"
											href={`/letters/${letter.id}.pdf`}
											target="_blank"
											>
											View Letter
										</Button>
									) : null
								}
							</div>
							<div>
								<Button
									className="mr-2"
									variant="outline-danger"
									onClick={this.handleReasonModalShow}
									>
									Deny
								</Button>
								<Button
									variant="outline-danger"
									onClick={this.handleUnableModalShow}
								>
									No Answer
								</Button>
							</div>
						</div>
					</Modal.Footer>
				</Modal>

				<Modal show={this.state.showReasonModal} onHide={this.handleReasonModalClose}>
					<Modal.Header closeButton>
					<Modal.Title>Reason for Deny</Modal.Title>
					</Modal.Header>

					<Modal.Body>
					<div className='applicant-detail-container'>
									<FormControl
										as="textarea"
										placeholder="Please provide case notes and reason for denial."
										onChange={(e) => this.onChangeDenyReasonNotes(e.target.value)}
									/>
								</div>
					</Modal.Body>

					<Modal.Footer>
								<Button
									variant="danger"
									disabled={!denyNotes}
									onClick={this.denyApplication}
								>
									Deny
								</Button>
					</Modal.Footer>
				</Modal>

				<Modal show={this.state.showNotesModal} onHide={this.handleNotesModalClose}>
					<Modal.Header closeButton>
						<Modal.Title>Notes</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<div className='applicant-detail-container'>
							<FormControl
								as="textarea"
								placeholder="Provide case and approval notes here."
								onChange={(e) => this.onChangeApprovalNotes(e.target.value)}
							/>
							<FormControl
								className="mt-3"
								as="textarea"
								placeholder="Type in your name for digital signature."
								onChange={(e) => this.onChangeSignature(e.target.value)}
								required
							/>
						</div>
					</Modal.Body>

					<Modal.Footer className={"justify-content-between"}>
						<p className="mb-0">*Approve issuing requested authorization letter(s)</p>
						<Button
							variant="primary"
							onClick={this.approveApplication}
							disabled={!this.state.approvalSignature}
						>
							Approve
						</Button>
					</Modal.Footer>
				</Modal>

				<Modal show={this.state.showUnableModal} onHide={this.handleUnableModalClose}>
					<Modal.Header closeButton>
					<Modal.Title>Note</Modal.Title>
					</Modal.Header>

					<Modal.Body>
					<div className='applicant-detail-container'>
						<FormControl
							as="textarea"
							placeholder="Please provide your comment."
							onChange={(e) => this.onChangeUnableReasonNotes(e.target.value)}
						/>
					</div>
					</Modal.Body>

					<Modal.Footer>
						<Button
							variant="danger"
							disabled={!unableNotes}
							onClick={this.unableApplication}
						>
							Submit
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
  return {
    dashboard: state.dashboard
  }
}

export default connect(mapStateToProps, null)(LetterApplicationModal)
