
export const required = value => value ? undefined : 'Required'
export const requiredSelection = value => value >= 0 ? undefined : 'Required'
export const requiredBoolean = value => (value === true || value === false) ? undefined : 'Required'
export const  maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
export const maxLength15 = maxLength(15)
export const  minLength = min => value =>
  value && value.length < min ? `Must be at least ${min} characters` : undefined
export const minLength8 = minLength(8)
export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
export const maxValue = max => value =>
  value && value > max ? `Must be at most ${max}` : undefined
export const minValue18 = minValue(18)
export const maxValue11 = maxValue(11)
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    'Invalid email address' : undefined
export const tooOld = value =>
  value && value > 65 ? 'You might be too old for this' : undefined
export const aol = value =>
  value && /.+@aol\.com/.test(value) ?
    'Really? You still use AOL for your email?' : undefined
export const symdigits = value =>
  value && !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,50}$/.test(value) ?
    'Must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character' : undefined
