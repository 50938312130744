import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reset } from 'redux-form';
import queryString from 'query-string';

import PasswordResetForm from '../../components/PasswordForm/PasswordResetForm';
import * as passwordActions from '../../actions/passwordActionCreators'
import {Alert, Button} from "react-bootstrap";


class PasswordResetContainer extends React.Component {
  state = {
    message: "",
    submitted: false
  };

  handleSubmit = (formProps) => {
    const parsed = queryString.parse(this.props.location.search);
    this.props.passwordActions.resetPassword({...formProps, account: {...formProps.account, reset_password_token: parsed.token}});
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.password.resetRequesting && !nextProps.password.resetRequesting) {
      if (nextProps.password.errors.length)
        this.setState({message: "Please correct the form and submit again."})
      else
        this.setState({message: "Your password has been reset successfully! Go to Login page and login with your new password.", submitted: true})
    }
  }

  render() {
    const { errors, failed } = this.props.password;

    return (
      <div className="container login-container bn-space-top bn-space-bottom">
        <h2 className="page-header">Reset your password</h2>
        <hr className="header-underline"/>

        <p className="text-muted">Please enter your new password using the form below and then click on <b>Submit</b> button.</p>

        {this.state.message !== "" && !failed &&
        <Alert variant='success'>
          <span><strong>{this.state.message}</strong></span>
        </Alert>
        }

        {!this.state.submitted &&
          <PasswordResetForm
            onSubmit={this.handleSubmit}
            errors={errors} />
        }

        {this.state.submitted &&
        <Button variant="primary"
                onClick={() => {
                  this.props.history.push('/login')
                }}>
          Return To Login
        </Button>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    password: state.password
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    passwordActions: bindActionCreators(passwordActions, dispatch),
    reset: bindActionCreators(reset, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetContainer)
