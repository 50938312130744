import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reset } from 'redux-form';

import LoginForm from '../../components/LoginForm/LoginForm';
import * as sessionActions from '../../actions/sessionActionCreators'
import './style.css';

class LoginContainer extends React.Component {

  handleSubmit = (e) => {
    this.props.sessionActions.logInAccount(e)
    this.props.reset("login")
  }

  render() {
    const { errors } = this.props.session;

    return (
      <div className="container login-container page-space-top page-space-bottom">
        <h2 className="page-header">Provider Login</h2>
        <hr className="header-underline"/>

        <p className="text-muted">Don't have an account? Contact us to register your provider account.</p>

        <LoginForm
          onSubmit={this.handleSubmit}
          errors={errors} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.session
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sessionActions: bindActionCreators(sessionActions, dispatch),
    reset: bindActionCreators(reset, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
