import {
  SUBMIT_CONTACT_REQUEST,
  SUBMIT_CONTACT_SUCCESS,
  SUBMIT_CONTACT_FAILURE,
} from '../actions/contactActionCreators';

const initialState = {
  saving: false,
  failed: false,
  errors: []
};

const contactReducer = (state = initialState, action) => {
  switch(action.type){
    case SUBMIT_CONTACT_REQUEST:
      return {...state, saving: true, failed: false}
    case SUBMIT_CONTACT_FAILURE:
      return {...state, saving: false, failed: true}
    case SUBMIT_CONTACT_SUCCESS:
      return {...state, saving: false}
    default:
      return state
  }
}

export default contactReducer
