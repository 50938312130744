import React from 'react';
import {connect} from 'react-redux';
import "./style.css";
import PersonalInfoForm from "../../components/QuestionsForm/PersonalInfoForm";

class QuestionsContainer extends React.Component {

  render() {
    return (
      <div className="container page-space-top page-space-bottom">
        <div className="row">
          <div className="col-lg-7">
            <h2 className="page-header">Patient Information</h2>
            <hr className="header-underline"/>
          </div>
        </div>

        <p className="text-muted text-medium mb-4">
          Please input patient information for approval of your ESA letter by a licensed practitioner. This information is 100% confidential with full HIPAA privacy compliance.
        </p>

        <PersonalInfoForm history={this.props.history}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    questions: state.questions
  }
}

export default connect(mapStateToProps)(QuestionsContainer);
