import React from 'react';
import { connect } from 'react-redux';
import {Button, Form, ListGroup, Pagination, InputGroup, Modal, FormControl} from 'react-bootstrap';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import * as dashboardActions from '../../actions/dashboardActionCreators'

class ArchivedAppointments extends React.Component {
  constructor() {
    super()
    this.state = {
      activePage: 1,
      searchPatientName: "",
      startDate: null,
      endDate: new Date(),
      showNotesModal: false,
      selectedOrderId: null,
      openCaseId: 0
    }
  }

  componentDidMount = () => {
    this.props.fetchArchivedAppointments(1, this.state.startDate, this.state.endDate, this.state.searchPatientName);
  }

  render(){
    const { archivedAppointments, isFetching, archivedActivePage, archivedTotalPages, count, totalCount } = this.props.dashboard;

    let items = [];
    let startPage, endPage;
    if (archivedTotalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = archivedTotalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (archivedActivePage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (archivedActivePage + 4 >= archivedTotalPages) {
        startPage = archivedTotalPages - 9;
        endPage = archivedTotalPages;
      } else {
        startPage = archivedActivePage - 5;
        endPage = archivedActivePage + 4;
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item key={number} active={number === archivedActivePage} onClick={() => {this.props.fetchArchivedLetterApplications(number, this.state.startDate, this.state.endDate, this.state.searchPatientName)}}>
          {number}
        </Pagination.Item>
      );
    }

    return (
      <div className="container page-space-top-sm page-space-bottom">
        <div className="d-flex justify-content-between">
          <h4>Archived Service Transactions</h4>
          <div className="mb-0">
            <Button variant="outline-primary"
                    onClick={() => {this.props.history.push('/provider/dashboard')}}>
              <i className="fa fa-chevron-left"/>
              &nbsp;Back To Dashboard
            </Button>
          </div>
        </div>
        <hr className="header-underline mb-1"/>

        <div className="row">
          <div className="col-12 form-inline justify-content-end mb-2">
            <InputGroup className="search-patient-box mr-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend">
                  <span className="fa fa-search"></span>
                </InputGroup.Text>
              </InputGroup.Prepend>

              <Form.Control
                type="text"
                placeholder="Search Patient"
                aria-describedby="inputGroupPrepend"
                name="search"
                value={this.state.searchPatientName}
                onChange={e => {
                  this.setState({searchPatientName: e.target.value});
                  this.props.fetchArchivedAppointments(1, this.state.startDate, this.state.endDate, e.target.value);
                }}
              />
            </InputGroup>

            <DateTime
              value={this.state.startDate}
              onChange={date => {
                this.setState({startDate: date ? new Date(date) : null});
                this.props.fetchArchivedAppointments(1, new Date(date), this.state.endDate, this.state.searchPatientName);
              }}
              timeFormat={false}
              closeOnSelect={true}
              inputProps={{ placeholder: 'From'}}/>

            <span className="text-muted text-large">&nbsp;~&nbsp;</span>

            <DateTime
              value={this.state.endDate}
              onChange={date => {
                this.setState({endDate: date ? new Date(date) : null});
                this.props.fetchArchivedLetterApplications(1, this.state.startDate, new Date(date), this.state.searchPatientName);
              }}
              timeFormat={false}
              closeOnSelect={true}
              inputProps={{ placeholder: 'To'}}/>
          </div>
        </div>

        <ListGroup className="mb-2">
          <ListGroup.Item key={0}>
            <div className="row">
              <div className="col-6 col-md-3">
                <label className="text-muted mb-0"><small>Service Type</small></label>
              </div>

              <div className="col-6 col-md-1">
                <label className="text-muted mb-0"><small>Requested</small></label>
              </div>

              <div className="col-6 col-md-1">
                <label className="text-muted mb-0"><small>Completed</small></label>
              </div>

              <div className="col-6 col-md-2">
                <label className="text-muted mb-0"><small>Patient Name</small></label>
              </div>

              <div className="col-6 col-md-3">
                <label className="text-muted mb-0"><small>Patient Email</small></label>
              </div>

              <div className="col-6 col-md-2">
                <label className="text-muted mb-0"><small>Patient Phone</small></label>
              </div>
            </div>
          </ListGroup.Item>
          {isFetching ?
            <p className="text-muted">Loading...</p>
            :
            archivedAppointments.map(service_transaction =>
              <ListGroup.Item key={service_transaction.id}>
                <div className="row mb-2">
                  <div className="col-6 col-md-3">
                    <p className="mb-0 text-medium">{
                      service_transaction.service_type.title
                    }</p>
                  </div>

                  <div className="col-6 col-md-1">
                    <p className="mb-0 text-medium">{new Date(service_transaction.created_at).toLocaleDateString("en-US")}</p>
                  </div>

                  <div className="col-6 col-md-1">
                    <p className="mb-0 text-medium">{new Date(service_transaction.finished_at).toLocaleDateString("en-US")}</p>
                  </div>

                  <div className="col-6 col-md-2">
                    <p className="mb-0 text-medium">{service_transaction.service_request.patient_name}</p>
                  </div>

                  <div className="col-6 col-md-3">
                    <p className="mb-0 text-medium">{service_transaction.service_request.patient_email}</p>
                  </div>

                  <div className="col-6 col-md-2">
                    <p className="mb-0 text-medium">{service_transaction.service_request.patient_phone}</p>
                  </div>
                </div>

                <hr className="mb-2 mt-2"/>

                <div className="row">
                  <div className="col-12 col-md-3 border-right">
                    <label className="text-muted mb-0"><small>Instructions</small></label>
                    <p className="mb-0 text-medium">{service_transaction.notes}</p>
                  </div>

                  <div className="col-12 col-md-9">
                    <label className="text-muted mb-0"><small>Notes:</small></label>
                    {
                      service_transaction.service_transaction_notes.map(notes => {
                        return <p key={notes.id} className="mb-0">{new Date(notes.created_at).toLocaleDateString("en-US") + ' - ' +  notes.content}</p>
                      })
                    }
                  </div>
                </div>
              </ListGroup.Item>
            )
          }
          {
            archivedAppointments.length === 0 &&
            <p className="text-muted">No archived service transactions.</p>
          }
        </ListGroup>

        <p className="pull-left text-muted ">Showing <b>{count}</b> items of <b>{totalCount}</b> in total</p>

        <Pagination className="justify-content-end">
          <Pagination.Item className={archivedActivePage === 1 ? 'disabled' : ''}
                           onClick={() => {this.props.fetchArchivedAppointments(1, this.state.startDate, this.state.endDate, this.state.searchPatientName)}}>
            First
          </Pagination.Item>
          <Pagination.Item className={archivedActivePage === 1 ? 'disabled' : ''}
                           onClick={() => {this.props.fetchArchivedAppointments(archivedActivePage - 1, this.state.startDate, this.state.endDate, this.state.searchPatientName)}}>
            Previous
          </Pagination.Item>
          {items}
          <Pagination.Item className={archivedActivePage === archivedTotalPages ? 'disabled' : ''}
                           onClick={() => {this.props.fetchArchivedAppointments(archivedActivePage + 1, this.state.startDate, this.state.endDate, this.state.searchPatientName)}}>
            Next
          </Pagination.Item>
          <Pagination.Item className={archivedActivePage === archivedTotalPages ? 'disabled' : ''}
                           onClick={() => {this.props.fetchArchivedAppointments(archivedTotalPages, this.state.startDate, this.state.endDate, this.state.searchPatientName)}}>
            Last
          </Pagination.Item>
        </Pagination>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    dashboard: state.dashboard
  }
}

export default connect(mapStateToProps, dashboardActions)(ArchivedAppointments)
