import React from 'react';
import { connect } from 'react-redux';
import Dashboard from "./Dashboard";
import ArchivedApplications from "./ArchivedApplications";
import ArchivedAppointments from "./ArchivedAppointments";
import { Route, Switch } from 'react-router';
import './style.css';

class DoctorHomeContainer extends React.Component {

  render() {
    const { match } = this.props;
    return (
      <Switch>
        <Route path={`${match.url}/archived_applications`} component={ArchivedApplications}/>
        <Route path={`${match.url}/archived_services`} component={ArchivedAppointments}/>
        <Route path={`${match.url}/dashboard`} component={Dashboard}/>
      </Switch>
    );
  }
}

export default connect()(DoctorHomeContainer)
