import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { FormGroup, Button, Alert } from 'react-bootstrap';

import { FieldInput } from '../formFields';
import {minLength8, required, symdigits} from "../formValidations";

const validate = values => {
  let errors = {}
  if (values.account) {
    if (values.account.password_confirmation !== values.account.password) {
      errors = {account: {password_confirmation: "Password mismatch"}}
    }
  }

  return errors
}

let PasswordResetForm = ({ handleSubmit, errors }) => (
  <form onSubmit={ handleSubmit }>
    { errors.length > 0 &&
    <Alert variant={'danger'}>
      {errors.map(error => <span><strong>{error}</strong></span>)}
    </Alert>
    }

    <Field
      component={ FieldInput }
      name="account[password]"
      validate={[required, minLength8, symdigits]}
      type="password"
      autocomplete="off"
      placeholder="New Password"
    />
    <Field
      component={ FieldInput }
      name="account[password_confirmation]"
      validate={[required]}
      type="password"
      autocomplete="off"
      placeholder="Confirm New Password"
    />

    <div className="action-container">
      <Button className="btn btn-primary" type="submit">Submit</Button>
    </div>
  </form>
);

const createReduxForm = reduxForm({ form: 'passwordReset', validate})

PasswordResetForm = createReduxForm(PasswordResetForm)

export default PasswordResetForm
