import React from 'react';
import { Button, Alert, Modal, FormControl } from "react-bootstrap";

class ConfirmAppointmentModalConfirm extends React.Component {
  state = {
    name: ''
  };

  updateName = (name) => {
    this.setState({ name });
  }

  handleSubmit = () => {
    this.props.submit(this.state.name);
  }

  createMarkup = (text) => {
    return {__html: text};
  }

  render() {
    const text = this.props.text && this.props.text != '' ? this.props.text : '<p>Confirm appointment</p>';
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.close}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div dangerouslySetInnerHTML={this.createMarkup(text)} />
        </Modal.Body>

        <Modal.Footer className={"justify-content-between"}>
          <FormControl
            placeholder="Please type your name"
            onChange={(e) => this.updateName(e.target.value)}
          />
          <Button
            variant="primary"
            onClick={this.handleSubmit}
            disabled={!this.state.name}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ConfirmAppointmentModalConfirm;
