import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from 'query-string';
import { Button, Alert } from "react-bootstrap";
import moment from "moment";

import * as scheduleActions from "../../actions/scheduleActionCreators";
import ConfirmAppointmentModalConfirm from './ConfirmAppointmentModalConfirm';
import ConfirmAppointmentModalDecline from './ConfirmAppointmentModalDecline';

class ConfirmAppointmentContainer extends React.Component {
  state = {
    message: "",
    submitted: false,
    successful: false,
    confirm: false,
    modalConfirm: false,
    modalDecline: false,
    reschedule: false
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.schedule.sanding && !nextProps.schedule.sanding) {
      if (nextProps.schedule.failed) {
        this.setState({ message: "Something went wrong. Please try again.", submitted: true, successful: false })
      } else {
        let message = `Thank you! Your appointment is ${this.state.confirm ? 'confirmed' : this.state.reschedule ? 'rescheduled' : 'declined'}.`;
        if (nextProps.schedule.message == 'not found') message = 'Appointment not found.';
        if (nextProps.schedule.message == 'used') message = 'Appointment has already been confirmed.';
        this.setState({ message: message, submitted: true, successful: nextProps.schedule.message == '' });
      }
    }

    if (this.props.schedule.loading && !nextProps.schedule.loading) {
      if (!nextProps.schedule.appoinment.success && nextProps.schedule.appoinment.error == 'timeout') {
        this.setState({ reschedule: true });
      }
    }
  }

  handleConfirmation = (message) => {
    this.setState({ modalConfirm: false, modalDecline: false });
    const parsed = queryString.parse(location.search);
    const formData = new FormData();

    formData.append('confirm', this.state.confirm);
    formData.append('appointment', parsed.appointment);
    formData.append('code', parsed.code);

    if (this.state.confirm) formData.append('sign', message);
    if (!this.state.confirm) formData.append('note', message);

    this.props.actions.submitSchedule(formData);
  }

  openModalConfirm = () => {
    this.setState({
      confirm: true,
      modalConfirm: true
    });
  }

  closeModalConfirm = () => {
    this.setState({ modalConfirm: false });
  }

  openModalDecline = () => {
    this.setState({
      confirm: false,
      modalDecline: true
    });
  }

  closeModalDecline = () => {
    this.setState({ modalDecline: false });
  }

  render() {
    const parsed = queryString.parse(location.search);

    let interaction = <React.Fragment>
      <Button onClick={this.openModalConfirm}>Confirm</Button>
      <Button variant="secondary" className="ml-3" onClick={this.openModalDecline}>Decline</Button>
    </React.Fragment>;

    if (this.state.reschedule === true) {
      interaction = <Button onClick={this.openModalDecline}>Reschedule</Button>;
    }

    if (this.state.submitted === true) {
      interaction = <Alert variant={this.state.successful ? 'success' : 'danger'}>{this.state.message}</Alert>;
    }

    let content = <React.Fragment>Loading appointment</React.Fragment>;

    if (!this.props.schedule.loading) {
      if (this.props.schedule.appoinment.success) {
        content = <React.Fragment>
          <p>Appointment: {moment.utc(this.props.schedule.appoinment.date).local().format("M/D/YYYY, h:mm a")}</p>
          {interaction}
        </React.Fragment>;
      } else if (this.state.reschedule) {
        content = <React.Fragment>
          <Alert variant="warning">Appointment: {moment.utc(this.props.schedule.appoinment.date).local().format("M/D/YYYY, h:mm a")} is expired. Please reschedule.</Alert>
          {interaction}
        </React.Fragment>;
      } else {
        content = <Alert variant="danger">{this.props.schedule.failed ? 'Something went wrong. Please try again.' : 'Appointment not found.'}</Alert>;
      }
    }

    return (
      <div className="container page-space-top page-space-bottom">
        <div className="row">
          <div className="col-lg-7">
            <h2 className="page-header text-muted">{parsed.reschedule ? 'Reschedule' : 'Confirm'} Appointment</h2>
            <hr className="header-underline"/>
          </div>
        </div>
        {content}
        <ConfirmAppointmentModalConfirm
          show={this.state.modalConfirm}
          close={this.closeModalConfirm}
          text={this.props.schedule.appoinment.confirmation_text}
          submit={this.handleConfirmation}
        />
        <ConfirmAppointmentModalDecline
          show={this.state.modalDecline}
          close={this.closeModalDecline}
          submit={this.handleConfirmation}
          title={this.state.reschedule ? 'Reschedule' : 'Decline'}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    schedule: state.schedule
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(scheduleActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAppointmentContainer)
