import React from 'react';
import {connect} from "react-redux";
import {Field, FieldArray, reduxForm, formValueSelector, SubmissionError} from 'redux-form';
import {Form, Button, FormGroup, FormControl, Alert, FormLabel, Badge} from 'react-bootstrap';
import {CardElement, injectStripe} from 'react-stripe-elements';
import queryString from 'query-string';

import {productsList, statesList} from '../../constant';
import {submitAppointment, fetchServiceTypes} from "../../actions/appointmentActionCreators";
import {FieldInput, FieldPhoneInput, FieldSelect} from '../formFields';
import {required, email, number, maxValue11} from '../formValidations';
import './AppointmentForm.css';

const getQuantityOptions = () => {
  let arr = [];
  arr.push(<option key={-1} value={""}>{"Quantity"}</option>)
  for (let i = 1; i <= 25; i++) {
    arr.push(<option key={i} value={i}>{i}</option>)
  }
  return arr;
}

const renderQuantitiesField = ({ fields, appointment, queryParsed, meta: { touched, error, submitFailed } }) => (
  <div className="">
    {fields.map((member, index) => (
      <div key={index} className="form-group">
        <div className="row align-items-center mx-gutters-1">
          <Field name={`${member}.service_type`}
                 component={FieldSelect}
                 options={[<option key={0} value={undefined}>Service Type</option>].concat(appointment.serviceTypes.sort((a, b) =>
                 a.id > b.id ? 1 : -1
                ).filter(el =>
                   !queryParsed.service_type || el.id == queryParsed.service_type
                 ).map((st) =>
                   <option key={st.id} value={st.id}>{st.title} - ${st.price}</option>
                 ))}
                 validate={[required]}
                 className="col-12 mb-0" />
        </div>
      </div>
    ))}
    {(touched || submitFailed) && error && <span>{error}</span>}
  </div>
);

class AppointmentForm extends React.Component {
  state = {
    total_amount: 0
  };

  getUSStatesOptions() {
    let arr = [];
    arr.push(<option key={-1} value={""}>{"Select State"}</option>)
    statesList.map((state, index) => {
      arr.push(<option key={index} value={state.value}>{state.value}</option>);
    });
    return arr;
  }

  handleSubmitAppointment = (data) => {

    if (data.sameEmail) {
      data = {...data, billing_email: data.patient_email}
    }

    return this.props.stripe.createToken({
      email: data.email
    })
      .then(({token}) => {
        if (token) {
          return this.props.submitAppointment({
            token: token.id,
            amount: this.props.total_amount,
            ...data
          })
        } else {
          throw 'Token error';
        }
      })
      .catch((e) => {
        throw new SubmissionError({
          _error: 'An error occurred while processing your card.'
        });
      })
  }

  componentDidMount = () => {
    this.props.fetchServiceTypes();
  }

  render() {
    const {handleSubmit, submitting, appointment, error} = this.props;
    const queryParsed = queryString.parse(location.search);

    return (
      <div className="row">
        <div className="col-lg-6 mb-4 mb-lg-0">

          <div className="border-primary border-right pr-lg-4 border-md-none">
            <Field name="patient_name"
                   type='text'
                   component={FieldInput}
                   disabled={queryParsed.patient_name}
                   placeholder="Patient Name"
                   validate={required}
            />

            <Field name="patient_email"
                   type='email'
                   component={FieldInput}
                   disabled={queryParsed.patient_email}
                   placeholder="Patient Email"
                   validate={[required, email]}
            />

            <Field name="patient_phone"
                   type='text'
                   component={FieldPhoneInput}
                   placeholder="Phone Number"
                   validate={required}
            />

            <Field name="provider_name"
                   type='text'
                   component={FieldInput}
                   placeholder="Provider Name"
            />

            <Field name="patient_notes"
                   type='text'
                   component={FieldInput}
                   placeholder="Provide any notes and instructions"
            />
          </div>
        </div>

        <div className="col-lg-6">
          <form onSubmit={handleSubmit(this.handleSubmitAppointment)}>
            {appointment.errors && appointment.errors.global &&
            <Alert variant="danger">
              <strong>{appointment.errors.global}</strong>
            </Alert>
            }

            {error && (!appointment.errors || !appointment.errors.global) &&
              <Alert variant="danger">
                <strong>{error}</strong>
              </Alert>
            }

            <h6 className="text-muted mb-3">
              Service Request
            </h6>

            <FieldArray name="quantities" component={renderQuantitiesField} props={{appointment: appointment, queryParsed: queryParsed}} />

            <hr className="m-0 mb-4"/>

            <div className="custom-checkbox custom-control mb-2">
              <Field name="sameEmail" id="sameEmail" component="input" type="checkbox" className="custom-control-input"/>
              <label htmlFor="sameEmail" className="custom-control-label text-medium text-muted">Same as patient email address</label>
            </div>

            {!this.props.sameEmail &&
            <Field name="billing_email"
                   type='email'
                   component={FieldInput}
                   placeholder="Billing Email"
                   validate={[required, email]} />
            }

            <FormGroup>
              <CardElement className="form-control"/>
            </FormGroup>

            <Button
              variant="primary"
              type="submit"
              disabled={submitting || this.props.total_amount <= 0}>
              {
                submitting ?
                  <div>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                    &nbsp;Processing
                  </div>
                  :
                  "Submit Request & Pay $" + this.props.total_amount
              }
            </Button>
          </form>
        </div>
      </div>

    )
  }
}

const createReduxForm = reduxForm({form: 'appointment'})

AppointmentForm = createReduxForm(AppointmentForm)

const selector = formValueSelector('appointment')
AppointmentForm = connect(state => {
  const quantities = selector(state, 'quantities')
  let total_amount = 0
  if (quantities) {
    total_amount = quantities.reduce((prev, current) => {
      let price = 0;
      const currentSt = state.appointment.serviceTypes.find((st) => current.service_type === st.id.toString());
      if (currentSt)
        price = currentSt.price
      return prev + price * (current.quantity ? parseInt(current.quantity) : 0)
    }, 0)
  }

  const sameEmail = selector(state, 'sameEmail')

  return {
    total_amount: total_amount,
    sameEmail: sameEmail
  }
})(AppointmentForm)

function bindAction(dispatch) {
  return {
    submitAppointment: (data) => dispatch(submitAppointment(data)),
    fetchServiceTypes: () => dispatch(fetchServiceTypes())
  };
}

const mapStateToProps = (state, ownProps) => {
  const parsed = queryString.parse(location.search);
  return {
    appointment: state.appointment,
    initialValues: {
      ...parsed,
      sameEmail: false,
      quantities: [{
        service_type: parsed.service_type,
        quantity: parsed.quantity
      }]
    },
  }
}

export default injectStripe(connect(mapStateToProps, bindAction)(AppointmentForm));
