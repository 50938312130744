import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from 'query-string';
import { Button, Alert, FormControl } from "react-bootstrap";
import moment from "moment";

import * as scheduleActions from "../../actions/scheduleActionCreators";
import ConfirmAppointmentModalDecline from './ConfirmAppointmentModalDecline';
import ConfirmAppointmentText from './ConfirmAppointmentText';
import ConfirmAppoinmentFileUploads from './ConfirmAppoinmentFileUploads';

class ConfirmAppointmentContainer extends React.Component {
  state = {
    message: "",
    sending: false,
    submitted: false,
    successful: false,
    confirm: false,
    modalConfirm: false,
    modalDecline: false,
    reschedule: false,
    consent_sign: "",
    consent_signed: false,
    files: []
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.schedule.sanding && !nextProps.schedule.sanding) {
      if (nextProps.schedule.failed) {
        this.setState({ message: "Something went wrong. Please try again.", submitted: true, successful: false })
      } else {
        let message = `Thank you! Your appointment is ${this.state.confirm ? 'confirmed' : this.state.reschedule ? 'rescheduled' : 'declined'}.`;
        if (nextProps.schedule.message == 'not found') message = 'Appointment not found.';
        if (nextProps.schedule.message == 'used') message = 'Appointment has already been confirmed.';
        this.setState({ message: message, submitted: true, successful: nextProps.schedule.message == '' });
      }
    }

    if (this.props.schedule.loading && !nextProps.schedule.loading) {
      if (!nextProps.schedule.appoinment.success && nextProps.schedule.appoinment.error == 'timeout') {
        this.setState({ reschedule: true });
      }
    }
  }

  handleConfirmation = (message) => {
    this.setState({ modalConfirm: false, modalDecline: false });
    const parsed = queryString.parse(location.search);
    const formData = new FormData();

    formData.append('confirm', this.state.confirm);
    formData.append('appointment', parsed.appointment);
    formData.append('code', parsed.code);
    formData.append('sign', this.state.consent_sign);

    this.state.files.forEach((file) => {
      formData.append('files[]', file);
    });

    if (!this.state.confirm) formData.append('note', message);

    this.props.actions.submitSchedule(formData);
  }

  handleConfirmButton = () => {
    this.setState({
      confirm: true,
      sending: true
    }, () => {
      this.handleConfirmation()
    });
  }

  openModalDecline = () => {
    this.setState({
      confirm: false,
      modalDecline: true
    });
  }

  closeModalDecline = () => {
    this.setState({ modalDecline: false });
  }

  updateConsentSign = (consent_sign) => {
    this.setState({ consent_sign });
  }

  setConsentSigned = () => {
    this.setState({ consent_signed: true });
  }

  handleFileUpload = (files) => {
    this.setState({ files });
  }

  render() {
    const parsed = queryString.parse(location.search);

    let interaction = <React.Fragment>
      <Button onClick={this.handleConfirmButton} disabled={this.state.sending}>
        {this.state.sending ? <div>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
            &nbsp;Sending
          </div> : "Confirm"}
      </Button>
      <Button variant="secondary" className="ml-3" onClick={this.openModalDecline} disabled={this.state.sending}>
        Decline
      </Button>
    </React.Fragment>;

    if (this.state.reschedule === true) {
      interaction = <Button onClick={this.openModalDecline}>Reschedule</Button>;
    }

    if (this.state.submitted === true) {
      interaction = <Alert variant={this.state.successful ? 'success' : 'danger'}>{this.state.message}</Alert>;
    }

    let content = <React.Fragment>Loading appointment</React.Fragment>;

    const consentency = <React.Fragment>
      <ConfirmAppointmentText />
      <ConfirmAppoinmentFileUploads handleFileUpload={this.handleFileUpload} />
      <FormControl
        className="mt-4"
        placeholder="Please type your name here"
        onChange={(e) => this.updateConsentSign(e.target.value)}
      />
      <Button
        variant="primary"
        className="mt-3"
        onClick={this.setConsentSigned}
        disabled={this.state.consent_sign == "" || this.state.files.length == 0}
      >
        Accept Provider
      </Button>
    </React.Fragment>;

    if (!this.props.schedule.loading) {
      if (this.props.schedule.appoinment.success) {
        content = <React.Fragment>
          <p>Appointment: {moment.utc(this.props.schedule.appoinment.date).local().format("M/D/YYYY, h:mm a")}</p>
          {!this.state.consent_signed && <div className="mt-3">{consentency}</div>}
          {this.state.consent_signed && <div className="mt-3">{interaction}</div>}
        </React.Fragment>;
      } else if (this.state.reschedule) {
        content = <React.Fragment>
          <Alert variant="warning">Appointment: {moment.utc(this.props.schedule.appoinment.date).local().format("M/D/YYYY, h:mm a")} is expired. Please reschedule.</Alert>
          {interaction}
        </React.Fragment>;
      } else {
        content = <Alert variant="danger">{this.props.schedule.failed ? 'Something went wrong. Please try again.' : 'Appointment not found.'}</Alert>;
      }
    }

    return (
      <div className="container page-space-top page-space-bottom">
        <div className="row">
          <div className="col-lg-7">
            <h2 className="page-header text-muted">{parsed.reschedule ? 'Reschedule' : 'Confirm'} Appointment</h2>
            <hr className="header-underline"/>
          </div>
        </div>
        {content}
        <ConfirmAppointmentModalDecline
          show={this.state.modalDecline}
          close={this.closeModalDecline}
          submit={this.handleConfirmation}
          title={this.state.reschedule ? 'Reschedule' : 'Decline'}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    schedule: state.schedule
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(scheduleActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAppointmentContainer)