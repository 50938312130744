import React from 'react';
import LetterApplicationModal from './LetterApplicationModal';
import { getUserInfo } from '../../../constant';
import moment from 'moment';

const LetterApplicationRow = ({ letterApplication, onApprove, onDeny, onUnable, scheduleSelected, scheduleClose, legacy }) => {
  let appointments = letterApplication.schedules.filter(el => el.confirmed == 1).map(el => moment.utc(el.start_time).local().format("M/D/YYYY, h:mm a"));

  return(
    <div className="row">
      <div className="col-6 col-md-2">
        <label className="text-muted mb-0"><small>Patient</small></label>
        <p className="mb-0 text-medium">{`${letterApplication.patient.first_name} ${letterApplication.patient.last_name}`}</p>
      </div>

      <div className="col-6 col-md-2">
        <label className="text-muted mb-0"><small>Birth Date</small></label>
        <p className="mb-0 text-medium">{moment(letterApplication.patient.profile.birthdate).local().format("M/D/YYYY")}</p>
      </div>

      <div className="col-6 col-md-2">
        <label className="text-muted mb-0"><small>State</small></label>
        <p className="mb-0 text-medium">{letterApplication.patient.profile.address_state}</p>
      </div>

      <div className="col-6 col-md-2">
        <label className="text-muted mb-0"><small>Submitted</small></label>
        <p className="mb-0 text-medium">{new Date(letterApplication.created_at).toLocaleDateString("en-US")}</p>
      </div>

      <div className="col-6 col-md-2">
        <label className="text-muted mb-0"><small>Appointment</small></label>
        {legacy === false &&
          <p className="mb-0 text-medium">{appointments.length > 0 ? appointments.join(', ') : '-'}</p>
        }
        {letterApplication.appointment &&
          <p className="mb-0 text-medium">{letterApplication.appointment ? moment.utc(letterApplication.appointment).local().format("M/D/YYYY, h:mm a") : '-'}</p>
        }
      </div>

      <div className="col-12 col-md-2 d-flex justify-content-end align-items-center">
        <LetterApplicationModal
          letterApplication={letterApplication}
          onApprove={onApprove}
          onDeny={onDeny}
          onUnable={onUnable}
          scheduleSelected={scheduleSelected}
          scheduleClose={scheduleClose}
        />
      </div>
    </div>
  )
}

export default LetterApplicationRow
