import axios from "axios";

export const SUBMIT_SCHEDULE_REQUEST = 'SUBMIT_SCHEDULE_REQUEST';
export const SUBMIT_SCHEDULE_SUCCESS = 'SUBMIT_SCHEDULE_SUCCESS';
export const SUBMIT_SCHEDULE_FAILURE = 'SUBMIT_SCHEDULE_FAILURE';
export const SUBMIT_SCHEDULE_NOT_FOUND = 'SUBMIT_SCHEDULE_NOT_FOUND';
export const SUBMIT_SCHEDULE_USED = 'SUBMIT_SCHEDULE_USED';

export const FETCH_SCHEDULE_INFO_REQUEST = 'FETCH_SCHEDULE_INFO_REQUEST';
export const FETCH_SCHEDULE_INFO_SUCCESS = 'FETCH_SCHEDULE_INFO_SUCCESS';
export const FETCH_SCHEDULE_INFO_FAILURE = 'FETCH_SCHEDULE_INFO_FAILURE';

export const submitScheduleRequest = () => {
  return {
    type: SUBMIT_SCHEDULE_REQUEST
  }
}

export const submitScheduleSuccess = () => {
  return {
    type: SUBMIT_SCHEDULE_SUCCESS
  }
}

export const submitScheduleNotFound = () => {
  return {
    type: SUBMIT_SCHEDULE_NOT_FOUND
  }
}

export const submitScheduleUsed = () => {
  return {
    type: SUBMIT_SCHEDULE_USED
  }
}

export const submitScheduleFailure = () => {
  return {
    type: SUBMIT_SCHEDULE_FAILURE
  }
}

export const fetchScheduleInfoRequest = () => {
  return {
    type: FETCH_SCHEDULE_INFO_REQUEST
  }
}

export const fetchScheduleInfoSuccess = (schedule) => {
  return {
    type: FETCH_SCHEDULE_INFO_SUCCESS,
    payload: schedule
  }
}

export const fetchScheduleInfoFailure = () => {
  return {
    type: FETCH_SCHEDULE_INFO_FAILURE
  }
}

export const submitSchedule = (data) => {
  return (dispatch) => {
    dispatch(submitScheduleRequest())
    return axios.post("/schedules_confirm", data, {
      headers: {
        'Content-Type': 'multipart/form-data', processData: "false"
      }
    })
    .then(response => {
      if (response.data.success === false) {
        if (response.data.error == 'not found') {
          dispatch(submitScheduleNotFound());
        } else if (response.data.error == 'used') {
          dispatch(submitScheduleUsed());
        }
      } else {
        dispatch(submitScheduleSuccess());
      }
    })
    .catch(e => {
      dispatch(submitScheduleFailure(e.response.data));
    });
  }
}

export const fetchScheduleInfo = (data) => {
  return (dispatch) => {
    dispatch(fetchScheduleInfoRequest());
    return axios.get("/schedules_info", {
      params: {
        ...data
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      dispatch(fetchScheduleInfoSuccess(response.data));
    })
    .catch(e => {
      dispatch(fetchScheduleInfoFailure(e));
    });
  };
}