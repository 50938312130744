import React from 'react';
import { connect } from 'react-redux';
import {FormControl, Alert, FormLabel, Button} from 'react-bootstrap';

import {Field, reduxForm, formValueSelector} from "redux-form";
import {FieldSelect, FieldTextArea, FieldBooleanRadio, FieldRadio} from "../../components/formFields";
import {required, requiredBoolean} from "../../components/formValidations";

import * as questionsActions from '../../actions/questionsActionCreators';
import { userQuestions, userQuestionOptions, userQuestionDurationOptions, ESAStore } from '../../constant';
import './style.css';


export const yesWarning = value => value === true ? 'Please contact suicide hotline 1-800-273-8255 or 911 in case of emergency.' : undefined

class UserQuestionContainer extends React.Component {

  onSubmitUserQuestions = (data) => {
    const {userQuestion} = data;
    const { history } = this.props;

    this.props.dispatch(questionsActions.addUserQuestionInfo(userQuestion));

    history.push('/userinfo/' + history.location.search);
  };

  render() {
    const { handleSubmit, invalid, submitting, pristine, dirty, submitFailed } = this.props;

    return (
      <div className="container page-space-top page-space-bottom">
        <div className="row">
          <div className="col-lg-7">
            <h2 className="page-header">Please answer all questions to proceed</h2>
            <hr className="header-underline"/>
          </div>
        </div>

        <p className="text-muted text-medium mb-4">
          Please answer this questionnaire for approval of your ESA letter by a licensed practitioner. This information is 100% confidential with full HIPAA privacy compliance.
        </p>

        <form onSubmit={handleSubmit(this.onSubmitUserQuestions.bind(this))}>
          {(!pristine && submitFailed) && invalid &&
          <Alert variant="danger">
            Please answer all questions to proceed.
          </Alert>
          }

          <div className="row mx-gutters-2 mb-5">
            {
              userQuestions.map((question, index) => {
                const invisibleDurationField = (this.props.userQuestion && (!this.props.userQuestion.checkableQuestions[question.key].frequency || this.props.userQuestion.checkableQuestions[question.key].frequency === 'Never'));
                return (
                  <div className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-4" key={index}>
                    <FormLabel>
                      {question.title}
                    </FormLabel>
                    <div className="row mx-gutters-1">
                      <div className={"col-12 " + (!invisibleDurationField && "col-sm-5 col-md-4")}>
                        <Field name={'userQuestion.checkableQuestions.'+question.key+'.frequency'}
                               component={FieldSelect}
                               validate={required}
                               options={userQuestionOptions.map((option, index) => (
                                 <option key={index} value={option.value}>{option.name}</option>
                               ))}
                               className="" />
                      </div>
                      {!invisibleDurationField &&
                      <div className="col-12 col-sm-7 col-md-8">
                        <Field name={'userQuestion.checkableQuestions.' + question.key + '.duration'}
                               component={FieldRadio}
                               validate={invisibleDurationField ? null : required}
                               options={userQuestionDurationOptions}
                               className=""/>
                      </div>
                      }
                    </div>
                  </div>
                )
              })
            }

            <Field name="userQuestion.wishDead"
                   label="In the past 6 months, have you had any thought of suicide, wished you were dead, or your family would be better off if you were dead? *"
                   component={FieldBooleanRadio}
                   validate={[requiredBoolean, yesWarning]}
                   className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-5" />

            <Field name="userQuestion.attemptSuicide"
                   label="Have you ever attempted to commit suicide? *"
                   component={FieldBooleanRadio}
                   validate={[requiredBoolean, yesWarning]}
                   className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-5" />

            <Field name="userQuestion.significantEvent"
                   label="Explain which significant events have caused you significant stress this year, and your history, or ongoing symptoms, with any kind of mental health symptoms. *"
                   component={FieldTextArea}
                   validate={required}
                   rows={3}
                   maxLength={4000}
                   placeholder="(ie: loss of employment, financial crisis or hardships, illness or disability, loss of a loved one, relationship breakdown, loss or destruction of home, etc.)"
                   className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-5" />

            <Field name="userQuestion.psychiatrist"
                   label="Have you ever received, or are you currently receiving, psychiatric/psychological treatment or medicines? If so, what for? Please explain the circumstances surrounding treatment if the answer if yes. *"
                   component={FieldTextArea}
                   validate={required}
                   rows={3}
                   maxLength={4000}
                   placeholder="Enter your answer"
                   className="col-lg-6 d-flex flex-column justify-content-between mb-3 mb-lg-5" />

          </div>

          <div className="row mx-gutters-2">
            <div className="col">
              {(!pristine && submitFailed) && invalid &&
              <Alert variant="danger">
                Please answer all questions to proceed.
              </Alert>
              }
              <Button
                type="submit"
                disabled={submitting}
                className="btn btn-primary pull-right btn-action btn-custom">
                Next
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const createReduxForm = reduxForm({form: 'userQuestionnaire'})

UserQuestionContainer = createReduxForm(UserQuestionContainer)

const selector = formValueSelector('userQuestionnaire')
UserQuestionContainer = connect(state => {
  const userQuestion = selector(state, 'userQuestion')

  return {
    userQuestion,
  }
})(UserQuestionContainer)

const mapStateToProps = (state) => {
  return {
    questions: state.questions,
    initialValues: {
      userQuestion: state.questions.userQuestion
    },
  }
}

export default connect(mapStateToProps)(UserQuestionContainer);
