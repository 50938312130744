const passwordReducer = (state = {forgotRequesting: false, resetRequesting: false, errors: [], failed: false}, action) => {
  switch(action.type){
    case "FORGOT_PASSWORD_REQUEST":
      return {...state, forgotRequesting: true}
    case "FORGOT_PASSWORD_SUCCESS":
      return {...state, forgotRequesting: false, failed: false, errors: []}
    case "FORGOT_PASSWORD_FAILURE":
      return {...state, forgotRequesting: false, failed: true, errors: [...action.payload]}
    case "RESET_PASSWORD_REQUEST":
      return {...state, resetRequesting: true}
    case "RESET_PASSWORD_SUCCESS":
      return {...state, resetRequesting: false, failed: false, errors: []}
    case "RESET_PASSWORD_FAILURE":
      return {...state, resetRequesting: false, failed: true, errors: [...action.payload]}
    default:
      return state
  }
}

export default passwordReducer
