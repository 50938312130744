import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reset } from 'redux-form';

import ForgotPasswordForm from '../../components/PasswordForm/ForgotPasswordForm';
import * as passwordActions from '../../actions/passwordActionCreators'
import {Alert} from "react-bootstrap";


class ForgotPasswordContainer extends React.Component {
  state = {
    message: "",
    submitted: false
  };

  handleSubmit = (e) => {
    this.props.passwordActions.forgotPassword(e)
    this.props.reset("login")
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.password.forgotRequesting && !nextProps.password.forgotRequesting) {
      if (nextProps.password.errors.length)
        this.setState({message: "Please correct the form and submit again."})
      else
        this.setState({message: "We've emailed you instructions for settings your password. You should receive them shortly.", submitted: true})
    }
  }

  render() {
    const { errors, failed } = this.props.password;

    return (
      <div className="container login-container bn-space-top bn-space-bottom">
        <h2 className="page-header">Forgot your password?</h2>
        <hr className="header-underline"/>

        <p className="text-muted">Enter your email address below and we'll send you email with instructions to reset password.</p>

        {this.state.message !== "" && !failed &&
        <Alert variant='success'>
          <span><strong>{this.state.message}</strong></span>
        </Alert>
        }

        {!this.state.submitted &&
        <ForgotPasswordForm
          onSubmit={this.handleSubmit}
          errors={errors}/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    password: state.password
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    passwordActions: bindActionCreators(passwordActions, dispatch),
    reset: bindActionCreators(reset, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer)
