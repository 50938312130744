const initialState = {
  isFetching: false,
  letterApplications: [],
  service_transactions: [],
  archivedLetterApplications: [],
  archivedAppointments: [],
  archivedActivePage: 1,
  archivedTotalPages: 1,
  errors: [],
  schedule: [],
  intervals: [],
  schedule_settings: {}
}

const dashboardReducer = (state = initialState , action) => {
  switch(action.type){
    case "FETCH_LETTER_APPLICATIONS_REQUEST":
    case "FETCH_APPOINTMENTS_REQUEST":
    case "FETCH_ARCHIVED_LETTER_APPLICATIONS_REQUEST":
    case "FETCH_ARCHIVED_APPOINTMENTS_REQUEST":
    case "APPROVE_LETTER_APPLICATION_REQUEST":
    case "DENY_LETTER_APPLICATION_REQUEST":
    case "SUBMIT_APPOINTMENT_NOTES_REQUEST":
    case "FINISH_APPOINTMENT_SERVICE_REQUEST":
    case "SUBMIT_ARCHIVED_APPLIACTION_NOTES_REQUEST":
    case "SUBMIT_APPOINTMENT_TIMES_REQUEST":
      return {...state, isFetching: true}

    case "FETCH_LETTER_APPLICATIONS_SUCCESS":
      return {...state, letterApplications: [...action.payload], isFetching: false}
    case "FETCH_APPOINTMENTS_SUCCESS":
      return {...state, service_transactions: [...action.payload], isFetching: false}
    case "FETCH_SCHEDULE_SUCCESS":
      return {...state, schedule: [...action.payload.schedules], schedule_settings: action.payload.settings, intervals: action.payload.intervals, isFetching: false}

    case "APPROVE_LETTER_APPLICATION_SUCCESS":
    case "DENY_LETTER_APPLICATION_SUCCESS":
      return {...state,
        letterApplications: state.letterApplications.filter((app, index) => {
          return app.id !== action.payload.id;
        }), isFetching: false
      }

    case "UNABLE_LETTER_APPLICATION_SUCCESS":
      return {...state,
        letterApplications: state.letterApplications.map((app, index) => {
          if (app.id === action.payload.id) {
            app.flag = action.payload.flag;
            app.letter_application_actions = action.payload.letter_application_actions;
          }
          return app;
        }), isFetching: false
      }

    case "SUBMIT_ARCHIVED_APPLIACTION_NOTES_SUCCESS":
      return {...state,
        archivedLetterApplications: state.archivedLetterApplications.map((app) => {
          if (app.id === action.payload.id) {
            return action.payload
          }
          return app
        }), isFetching: false
      }

    case "FETCH_ARCHIVED_LETTER_APPLICATIONS_SUCCESS":
      return {...state,
        archivedLetterApplications: [...action.payload.applications],
        archivedActivePage: action.payload.page,
        archivedTotalPages: action.payload.pages,
        totalCount: action.payload.total_count,
        count: action.payload.count,
        isFetching: false
      }

    case "FETCH_ARCHIVED_APPOINTMENTS_SUCCESS":
      return {...state,
        archivedAppointments: [...action.payload.service_transactions],
        archivedActivePage: action.payload.page,
        archivedTotalPages: action.payload.pages,
        totalCount: action.payload.total_count,
        count: action.payload.count,
        isFetching: false
      }

    case "SUBMIT_APPOINTMENT_NOTES_SUCCESS":
    case "FINISH_APPOINTMENT_SERVICE_SUCCESS":
    case "SUBMIT_APPOINTMENT_TIMES_SUCCESS":
      return {...state,
        service_transactions: state.service_transactions.map((app) => {
          if (app.id === action.payload.id) {
            return action.payload
          }
          return app
        }), isFetching: false
      }

    case "FETCH_LETTER_APPLICATIONS_FAILURE":
    case "FETCH_APPOINTMENTS_FAILURE":
    case "FETCH_SCHEDULE_FAILURE":
    case "FETCH_ARCHIVED_LETTER_APPLICATIONS_FAILURE":
    case "FETCH_ARCHIVED_APPOINTMENTS_FAILURE":
    case "SUBMIT_APPOINTMENT_NOTES_FAILURE":
    case "FINISH_APPOINTMENT_SERVICE_FAILURE":
    case "SUBMIT_APPOINTMENT_TIMES_FAILURE":
    case "SUBMIT_ARCHIVED_APPLIACTION_NOTES_FAILURE":
      return {...state, errors: [...action.payload]}

    case "DENY_LETTER_APPLICATION_FAILURE":
    case "UNABLE_LETTER_APPLICATION_FAILURE":
    case "APPROVE_LETTER_APPLICATION_FAILURE":
      return {
        ...state,
        errors: [...state.errors, action.payload.errors],
        isFetching: false
      }

    default:
      return state
  }
}

export default dashboardReducer
