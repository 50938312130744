import React from 'react';
import {Button} from 'react-bootstrap';

import './style.css';

export default class HousingPolicyContainer extends React.Component {

  render() {
    return (
      <div className="container page-space-top page-space-bottom">
        <div className="row">
          <div className="col-lg-7">
            <h2 className="page-header">The Fair Housing Act and Emotional Support Animals</h2>
            <hr className="header-underline"/>
          </div>
        </div>

        <div className="mb-3 mb-lg-4">
          <p className="text-muted text-small">
            If you suffer from a mental or emotional disability, you and your animal have rights. These rights include reasonable accommodations to housing covered under FHAA.
          </p>
          <p className="text-muted text-small">
            Individuals suffering from mental or emotional disabilities can bring their animals where there are “No Pet” policies, where there are no animals allowed, without the need for additional animal deposit or animal fee.
          </p>
        </div>

        <div className="mb-3 mb-lg-4">
          <h6 className="">ESA Housing Law</h6>
          <p className="text-muted text-small">
            Under the <b>Fair Housing Amendments Act (FHAA)</b> of 1988 individuals with mental or emotional disabilities accompanied by their Emotional Support Animals must be given access to housing communities that have a "No Pet" policy. This act permits qualified individuals to live with their Emotional Support Animal in their home. Just as a wheelchair provides a person with a physical limitation the equal opportunity to use and enjoy a dwelling, an Emotional Support Animal provides a person with a mental or emotional disability the same opportunity to live independently.
          </p>
          <p className="text-muted text-small">
            Most times, an Emotional Support Animal will be seen as a reasonable accommodation for a person with such a disability. Failure to make reasonable accommodations by changing rules or policies can be a violation of the <b>FHAA</b> unless the accommodation would be an undue financial burden on the landlord or cause a fundamental alteration to the premises.
          </p>
        </div>

        <div className="mb-3 mb-lg-4">
          <h6 className="">ESAs in Housing</h6>
          <p className="text-muted text-small">
            An Emotional Support Animal can be any animal that helps his/her handler alleviate symptoms of mental or emotional disability. There are no restrictions on the type, breed, size or age of the animal and there are no training requirements.
          </p>
          <p className="text-muted text-small">
            The handler must suffer from a mental or emotional disability, this includes, but is not limited to, depression, anxiety, stress, loneliness, or anger. And the animal helps the handler cope with their disability.
          </p>
          <p className="text-muted text-small">
            The law allows landlords to request a letter recommending the use of an Emotional Support Animal written by a doctor or mental health professional from an individual accompanied by an Emotional Support Animal and confirming the existence of mental or emotional disability.
          </p>
          <div className="inner-block">
            <h6><small>ESA Examples</small></h6>
            <ul className="text-muted">
              <li>Animal can help alleviate depression</li>
              <li>Animal can reduce stress</li>
              <li>Animal can calm anxiety</li>
            </ul>
          </div>
        </div>

        <div className="mb-3 mb-lg-4">
          <h6 className="">Housing covered by the Fair Housing Act</h6>
          <p className="text-muted text-small">
            All housing is covered except:<br/>
            1. Rental dwellings of four or less units, where one unit is occupied by the owner;<br/>
            2. Single family homes sold or rented by the owner without the use of an agent;<br/>
            3. Housing owned by private clubs or religious organizations that restrict occupancy to members.<br/>
          </p>
        </div>

        <div className="mb-3 mb-lg-4">
          <h6 className="">Pet Fees</h6>
          <p className="text-muted text-small">
            Emotional Support Animals are not pets and owners do not have to pay pet fees. A security deposit, however, may be required by the landlord along with charges for any damage caused by the animal. The landlord does have the right to remove the Emotional Support Animal for unreasonable nuisance.
          </p>
        </div>

        <div className="mb-3 mb-lg-4">
          <h6 className="">What Landlords Can and Cannot Ask Under the FHAA</h6>
          <p className="text-muted text-small">
            The law allows landlords to request a letter recommending the use of an Emotional Support Animal written by a doctor or mental health professional from an individual accompanied by an Emotional Support Animal and confirming the existence of mental or emotional disability.
          </p>
          <p className="text-muted text-small">
            Few landlords may require the use of their own specific form. AnimalAccess therapists can assist you in completing such forms. Click on the link below to get started with your ESA evaluation.
          </p>
          <Button variant="primary" className="mb-3"
                  onClick={() => {this.props.history.push('/letters')}}>
            Get Started
          </Button>
          <p className="text-muted text-small">
            There are very specific rules and regulations under the FHAA to protect your rights.
          </p>
          <div className="inner-block">
            <p className="text-muted text-small mb-1">The landlord is <b>not allowed</b> to:</p>
            <ul className="text-muted">
              <li>Ask for <b>any extra rent</b> for an ESA</li>
              <li>Ask you <b>about your disability</b></li>
              <li>Require <b>any specific animal training</b></li>
              <li><b>Deny ESAs</b> for any insurance reasons</li>
            </ul>
          </div>
          <p className="text-muted text-small">
            However, landlords may ask you to pay for any damages caused by an ESA and may also evict you due to unruly or out-of-control behavior by an ESA.
          </p>
        </div>

        <div className="mb-3 mb-lg-4">
          <h6 className="">Enforcing your rights</h6>
          <p className="text-muted text-small">
            Under the FHAA you have rights, and landlords must comply. Landlord non-compliance may constitute discrimination with recourse under federal law. If a landlord refuses to give you and your ESA housing, you may take these steps:
          </p>
          <div className="inner-block">
            <ol className="text-muted">
              <li>Make the rental agent and owner of the property aware of the Fair Housing Act laws and potential legal ramifications of their failure to comply.</li>
              <li>File an official complaint against the rental agent and owner of the property with HUD’s Office of Fair Housing and Equal Opportunity (FHEO) by using this link.</li>
              <Button variant="outline-primary" className="my-2" target="_blank"
                      href="https://www.hud.gov/program_offices/fair_housing_equal_opp/online-complaint">
                HUD.GOV
              </Button>
              <li>You may seek legal advise from civil rights attorney for discrimination.</li>
            </ol>
          </div>
          <p className="text-muted text-small">
            <b>You have rights under the Fair Housing Act</b>. Don’t let a landlord refuse your ESA. Fill out the questionnaire in the link below to get qualified for your ESA letters today.
          </p>
          <Button variant="primary"
                  onClick={() => {this.props.history.push('/letters')}}>
            Get Started
          </Button>
        </div>
      </div>
    )
  }
}
