import React from 'react';
import { Link } from 'react-router-dom';
import ReactPixel from "react-facebook-pixel";

export default class ThankyouContainer extends React.Component {

  componentDidMount() {
    ReactPixel.track('Purchase');
  }

  render() {
    return (
      <div className='container page-space-top page-space-bottom'>
        <h2 className="page-header">Thank you</h2>
        <hr className="header-underline"/>

        <p className="text-large">Your request has been received and will be reviewed by a licensed professional.</p>
        <p className='text-large'>
          <i className='fa fa-envelope-open text-primary' />
          &nbsp;&nbsp;You will receive an email confirmation shortly. If you have any questions, please contact us at <a href="mailto:info@animalaccess.com">info@animalaccess.com</a>
        </p>

        <Link className='btn btn-primary mt-5' to='/letters'>Return to Letters</Link>
      </div>
    )
  }
}
