import axios from "axios";
import { SubmissionError } from 'redux-form';
import { history } from '../store.js'

export const SUBMIT_APPOINTMENT_REQUEST = 'SUBMIT_APPOINTMENT_REQUEST';
export const SUBMIT_APPOINTMENT_SUCCESS = 'SUBMIT_APPOINTMENT_SUCCESS';
export const SUBMIT_APPOINTMENT_FAILURE = 'SUBMIT_APPOINTMENT_FAILURE';

export const submitAppointmentRequest = () => {
  return {
    type: SUBMIT_APPOINTMENT_REQUEST
  }
}

export const submitAppointmentSuccess = (message) => {
  return {
    type: SUBMIT_APPOINTMENT_SUCCESS,
    payload: message
  }
}

export const submitAppointmentFailure = (errors) => {
  return {
    type: SUBMIT_APPOINTMENT_FAILURE,
    payload: errors
  }
}

export const submitAppointment = (data) => {
  return (dispatch) => {
    dispatch(submitAppointmentRequest())
    return axios.post("/service_requests", data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      dispatch(submitAppointmentSuccess(response.message))
      history.push('/thankyou')
    })
    .catch(e => {
      dispatch(submitAppointmentFailure(e.response.data))
      if (e.response.status !== 500) {
        throw new SubmissionError(e.response.data)
      }
    });
  };
}

export const fetchServiceTypesRequest = () => {
  return {
    type: "FETCH_SERVICE_TYPES_REQUEST"
  }
}

export const fetchServiceTypesSuccess = (serviceTypes) => {
  return {
    type: "FETCH_SERVICE_TYPES_SUCCESS",
    payload: serviceTypes
  }
}

export const fetchServiceTypesFailure = (errors) => {
  return {
    type: "FETCH_SERVICE_TYPES_FAILURE",
    payload: errors
  }
}

export const fetchServiceTypes = () => {
  return (dispatch) => {
    dispatch(fetchServiceTypesRequest())
    return fetch("/service_types", {
      method: "GET",
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (!response.ok) {
          throw response
        } else {
          return response.json()
        }
      })
      .then(json => {
        dispatch(fetchServiceTypesSuccess(json))
      })
      .catch(response =>
        response.json()
          .then((json) =>
            dispatch(fetchServiceTypesFailure(json.errors))
          )
      );
  };
}
