import React from 'react';
import { Link } from 'react-router-dom';

import './style.css';

export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer container-fluid">
        <div className="container">
          <div className="row text-center py-4 align-items-center">
            <div className="col-12 col-xl">
              <div className="row align-items-center">
                <div className="col-12 col-lg-6 col-xl-auto mb-4 mb-xl-0">
                  <div className="text-center">
                    <a className="navbar-brand mr-0 mr-lg-1" onClick={() => { this.props.history.push('/') }}>
                      <img src="/images/logos/logo-muted.svg" height="30" alt="logo"/>
                    </a>
                  </div>
                </div>

                <div className="col-12 col-lg-6 col-xl mb-4 mb-xl-0">
                  <img src="/images/payment_methods.svg" />
                </div>
              </div>
            </div>

            <div className="col-12 col-xl">
              <div className="row align-items-center">
                <div className="col-12 col-lg-12 col-xl mb-4 mb-xl-0">
                  <Link className="pref-link px-2" to="/terms-of-use">Terms of Use</Link>
                  <Link className="pref-link px-2" to="/terms-of-purchase">Terms of Purchase</Link>
                  <Link className="pref-link px-2" to="/privacy-policy">Privacy Policy</Link>
                </div>

                <div className="col-12 col-lg-12 col-xl-auto">
                  <p className="text-copyright mb-0">© 2019 AnimalAccess</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}