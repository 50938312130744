import React from 'react';

import './style.css';

export default class FAQCard extends React.Component {
  render() {
    var collapseId = Math.random().toString(36).substring(2, 15);

    if (this.props.filter === this.props.category)
      return (
        <div className="col-lg-6">
          <div className="card faq-card mb-3">
            <div className="card-body">
              <div className="align-items-center card-title d-flex flex-row justify-content-between">
                <p>{this.props.question}</p>
                <a className="badge badge-collapse" data-toggle="collapse" href={"#faqCard_" + collapseId}
                   role="button" aria-expanded="false" aria-controls={"faqCard_" + collapseId}>
                </a>
              </div>

              <div className="card-text collapse" id={"faqCard_" + collapseId}>
                <hr/>
                {this.props.children}
              </div>
            </div>
          </div>
        </div>);
    else
      return null;
  }
}
