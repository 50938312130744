import React from 'react';

export default class PurchaseTermsContainer extends React.Component {

  render() {
    return (
      <div className="container page-space-top page-space-bottom">
        <div className="row">
          <div className="col-lg-7">
            <h2 className="page-header text-muted">TERMS OF PURCHASE</h2>
            <hr className="header-underline"/>
          </div>
        </div>

        <p>
          Last Modified: 12/1/2018
        </p>

        <p>
          THESE TERMS OF PURCHASE CONTAIN VERY IMPORTANT INFORMATION REGARDING YOUR RIGHTS AND OBLIGATIONS, AS WELL AS
          THE CONDITIONS, LIMITATIONS, AND EXCLUSIONS THAT APPLY TO YOUR PURCHASE FROM THE SERIES OF WEB PAGES LOCATED
          AT HTTPS://WWW.ANIMALACCESS.COM​ (THE “SITE”).
        </p>

        <p>
          PLEASE READ THESE TERMS OF PURCHASE CAREFULLY. BY PLACING AN ORDER FOR PRODUCTS OR SERVICES FROM OUR SITE, YOU
          ACCEPT AND ARE BOUND BY THESE TERMS OF PURCHASE.

        </p>

        <p>
          YOU MAY NOT ORDER OR OBTAIN PRODUCTS OR SERVICES FROM OUR SITE IF YOU (A) ARE NOT THE OLDER OF AT LEAST 18
          YEARS OF AGE OR OF THE LEGAL AGE TO FORM A BINDING CONTRACT UNDER APPLICABLE LAW OR (B) ARE PROHIBITED FROM
          ACCESSING OR USING THE SITE OR ANY OF ITS CONTENTS, GOODS OR SERVICES BY APPLICABLE LAW.

        </p>

        <p>These Terms of Purchase are an integral part of the ​Website Terms of Use that apply generally to the use of
          our Site. You should also carefully review our ​Privacy Policy before placing an order for products or
          services through this Site. These Terms of Purchase are subject to change by AnimalAccess (“we”, “us”, or “our” as
          the context may require) without prior written notice at any time, in our sole discretion. The latest version
          of these Terms of Purchase will be posted on this Site, and you should review the Terms of Purchase before
          purchasing any products or services that are available through this Site. Your continued use of this Site
          after a posted change in these Terms of Purchase constitutes your acceptance of and agreement to such changes.
        </p>


        <h6>
          1. Order Acceptance and Cancellation​.</h6>
        <p>
          You agree that an order placed through the Site is an offer to buy all of the products and services listed in
          your order. All orders must be accepted by us or we will not be obligated to sell the products or services to
          you. We may choose not to accept any orders in our sole discretion. After we receive your order, we will send
          you a confirmation email to the email address you provide to us with your order number and details of the
          items you have ordered. The formation of a contract of sale between you and us will not take place unless and
          until we have sent a confirmation email. You have the option to cancel your order at any time before we have
          sent your order confirmation email by emailing our Customer Service Department at info@animalaccess.com

        </p>

        <h6>
          2. Prices and Payment Terms​.
        </h6>
        <p>
          (a) All prices posted on this Site are subject to change without notice. The price charged for a product or
          service will be the price in effect at the time the order is placed and will be set out in your order
          confirmation email. Price increases will only apply to orders placed after such changes.
        </p>

        <p>
          (b) Terms of payment are within our sole discretion and payment must be received by us before our acceptance
          of an order. We use a third-party payment processors (the “Payment Processors”) to accept payments. The
          processing of payments will be subject to the terms, conditions and privacy policies of the Payment
          Processors, in addition to these Terms of Purchase. Our current Payment Processors are Stripe, and your
          payments are processed by Payment Processors in accordance with Payment Processors’ terms of service and
          privacy policy. You agree to pay us, through the Payment

          Processors, all charges at the prices then in effect for any purchase in accordance with the applicable
          payment terms.

        </p>

        <p>
          (c) We reserve the right to correct, or to instruct our Payment Processor to correct, any errors or mistakes,
          even if payment has already been requested or received. You represent and warrant that (i) the credit card
          information you supply to our Payment Processor is true, correct, and complete, (ii) you are duly authorized
          to use such credit card for the purchase, (iii) charges incurred by you will be honored by your credit card
          company, and (iv) you will pay charges incurred by you at the posted prices, including all applicable taxes,
          if any.
        </p>

        <h6>
          3. Delivery; Title and Risk of Loss​.
        </h6>

        <p>(a) We will arrange for delivery of the products to you to the email address you provide
          when you place your order.

        </p>
        <p>
          (b) Title and risk of loss pass to you upon our transfer of the contents of your order to email service
          provider. Delivery dates are estimates only and cannot be guaranteed. We are not liable for any delays in
          delivery.
        </p>

        <h6>
          4. Returns and Refunds​.
        </h6>

        <p>
          (a) General Policy.
        </p>

        <p className="ml-3">
          (i) Except for any products or services designated on the Site or in these Terms of Purchase as
          non-returnable, we will accept a return of the products you order on our Site for a refund of your purchase
          price, less the original shipping and handling costs.
        </p>

        <p className="ml-3">
          (ii) You are responsible for all shipping and handling charges on returned items. You bear the risk of loss
          during shipment. We therefore strongly recommend that you (i) insure your return shipment against loss or
          damage and (ii) use a carrier that can provide you with proof of delivery for your protection.
        </p>

        <p className="ml-3">
          (iii) Refunds are processed within approximately two business days of our receipt of your merchandise. Your
          refund will be credited back to the same payment method used to make the original purchase on the Site.

        </p>


        <p>
          (b) Emotional Support Animal (“ESA”) Letters.
        </p>

        <p className="ml-3">
          (i) If you select the option to apply to obtain an ESA letter from our site and one of our licensed mental
          health professionals issues you such a letter, the cost of such ESA letter is not refundable. Similarly, we do
          not refund the cost of a letter due to any entities’ refusal to follow federal or state guidelines to accept
          your ESA letter.
        </p>

        <p className="ml-3">
          (ii) If your application for an ESA letter is not approved by one of our licensed mental health professionals,
          the fee for such letter will be refunded. This policy applies regardless of whether you are a new applicant or
          a renewal applicant and whether you have applied for one or more Emotional Support Animals.
        </p>

        <p>
          (c) Customized Products. Customized products are not refundable (including, but not limited to, identification
          cards, tags, and vests).
        </p>

        <h6>
          5. HEALTH CARE DISCLAIMER​.
        </h6>

        <p>
          NOTHING IN THE PRODUCTS OR SERVICES SHOULD BE CONSIDERED, OR USED AS A SUBSTITUTE FOR, MEDICAL ADVICE,
          DIAGNOSIS OR TREATMENT. THIS SITE AND ITS PRODUCTS AND SERVICES DO NOT CONSTITUTE THE PRACTICE OF ANY MEDICAL,
          NURSING OR OTHER PROFESSIONAL HEALTH CARE ADVICE, DIAGNOSIS OR TREATMENT. YOU SHOULD ALWAYS TALK TO YOUR
          HEALTH CARE PROVIDER FOR DIAGNOSIS AND TREATMENT, INCLUDING YOUR SPECIFIC MEDICAL NEEDS. NONE OF THE PRODUCTS
          OR SERVICES OFFERED THROUGH THIS SITE REPRESENTS OR WARRANTS THAT ANY PARTICULAR SERVICE OR PRODUCT IS SAFE,
          APPROPRIATE OR EFFECTIVE FOR YOU. WE ADVISE YOU TO ALWAYS SEEK THE ADVICE OF A PHYSICIAN OR OTHER QUALIFIED
          HEALTH CARE PROVIDER WITH ANY QUESTIONS REGARDING PERSONAL HEALTH OR MEDICAL CONDITIONS. IF YOU HAVE OR
          SUSPECT THAT YOU HAVE A MEDICAL PROBLEM OR CONDITION, PLEASE CONTACT A QUALIFIED HEALTH CARE PROFESSIONAL
          IMMEDIATELY. IF YOU ARE IN THE UNITED STATES AND ARE EXPERIENCING A MEDICAL EMERGENCY, PLEASE CALL 911 OR CALL
          FOR EMERGENCY MEDICAL HELP ON THE NEAREST TELEPHONE.
        </p>

        <h6>6. NO WARRANTY​.</h6>

        <p>
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE MAKE NO WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE
          PRODUCTS OR SERVICES OFFERED FOR PURCHASE ON THIS SITE AND/OR PURCHASED BY YOU. ALL PRODUCTS AND SERVICES ARE
          PROVIDED “AS IS” AND “AS AVAILABLE”. WE DISCLAIM ANY AND ALL WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE AND NON-INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
          FROM US, SHALL CREATE ANY WARRANTY.

        </p>

        <h6>7. LIMITATION OF LIABILITY​.</h6>

        <p>
          ​OUR LIABILITY WILL UNDER NO CIRCUMSTANCES EXCEED THE ACTUAL AMOUNT PAID BY YOU FOR THE PRODUCT OR SERVICE
          THAT YOU HAVE PURCHASED THROUGH THE SITE, NOR WILL WE UNDER ANY CIRCUMSTANCES BE LIABLE FOR ANY CONSEQUENTIAL,
          INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES OR LOSSES, WHETHER DIRECT OR INDIRECT. SOME STATES DO NOT ALLOW THE
          EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRECEDING LIMITATION OR EXCLUSION MAY
          NOT APPLY TO YOU.
        </p>

        <h6>8. Goods Not for Resale or Export​.</h6>

        <p>
          You represent and warrant that you are buying products or services from the Site for your own personal or
          household use only, and not for resale or export.
        </p>

        <h6>9. Intellectual Property Use and Ownership​.</h6>

        <p>You acknowledge and agree that we are and will remain the sole and exclusive owner of all intellectual
          property rights in and to each product and service made available on this Site and any related specifications,
          instructions, documentation or other materials, including, but not limited to, all related copyrights,
          patents, and trademarks and other intellectual property rights. You do not and will not have or acquire any
          ownership of these intellectual property rights in or to the products or services made available through this
          Site, or of any intellectual property rights relating to those products or services.</p>

        <h6>10. Privacy​.</h6>
        <p>We respect your privacy and are committed to protecting it. Our ​Privacy Policy​, governs the processing of
          all personal data collected from you in connection with your purchase of products or services through the
          Site.</p>

        <h6>11. Force Majeure​.</h6>
        <p>We will not be liable or responsible to you, nor be deemed to have defaulted or breached these Terms of
          Purchase, for any failure or delay in our performance under these Terms of Purchase when and to the extent
          such failure or delay is caused by or results from acts or circumstances beyond our reasonable control,
          including, without limitation, acts of God, flood, fire, earthquake, explosion, governmental actions, war,
          invasion or hostilities (whether war is declared or not), terrorist threats or acts, riot or other civil
          unrest, national emergency, revolution, insurrection, epidemic, lockouts, strikes or other labor disputes
          (whether or not relating to our workforce), or restraints or delays affecting carriers or inability or delay
          in obtaining supplies of adequate or suitable materials, materials or telecommunication breakdown or power
          outage.</p>
        <h6>12. Governing Law and Jurisdiction.​</h6>
        <p>All matters arising out of or relating to these Terms of Purchase are governed by and construed in accordance
          with the internal laws of the State of Nevada in the United States, without giving effect to any choice or
          conflict of law provision.
        </p>

        <h6>13. DISPUTE RESOLUTION AND BINDING ARBITRATION​.</h6>
        <p>ANY CLAIM, DISPUTE OR CONTROVERSY (WHETHER IN CONTRACT, TORT OR OTHERWISE, WHETHER PRE-EXISTING, PRESENT OR
          FUTURE, AND INCLUDING STATUTORY, CONSUMER PROTECTION, COMMON LAW, INTENTIONAL TORT, INJUNCTIVE AND EQUITABLE
          CLAIMS) BETWEEN YOU AND US ARISING FROM OR RELATING IN ANY WAY TO YOUR PURCHASE OF PRODUCTS OR SERVICES
          THROUGH THE SITE, WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION. THE ARBITRATION WILL BE
          ADMINISTERED BY THE AMERICAN ARBITRATION ASSOCIATION (“AAA”) IN ACCORDANCE WITH THE CONSUMER ARBITRATION RULES
          (THE “AAA RULES”) THEN IN EFFECT.</p>
        <p className="ml-3">YOU AGREE TO AN ARBITRATION ON AN INDIVIDUAL BASIS. IN ANY DISPUTE, YOU WILL NOT BE ENTITLED
          TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST OTHER CUSTOMERS IN COURT OR IN ARBITRATION OR OTHERWISE
          PARTICIPATE IN ANY CLAIM AS A CLASS REPRESENTATIVE, CLASS MEMBER OR IN A PRIVATE ATTORNEY GENERAL CAPACITY.
          THE ARBITRAL TRIBUNAL MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER
          ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING. THE ARBITRAL TRIBUNAL HAS NO POWER TO CONSIDER THE
          ENFORCEABILITY OF THIS CLASS ARBITRATION WAIVER AND ANY CHALLENGE TO THE CLASS ARBITRATION WAIVER MAY ONLY BE
          RAISED IN A COURT OF COMPETENT JURISDICTION.</p>
        <p>IF ANY PROVISION OF THIS ARBITRATION AGREEMENT IS FOUND UNENFORCEABLE, THE UNENFORCEABLE PROVISION WILL BE
          SEVERED AND THE REMAINING ARBITRATION TERMS WILL BE ENFORCED.</p>

        <h6>14. Assignment​.</h6>
        <p>You will not assign any of your rights or delegate any of your obligations under these Terms of Purchase
          without our prior written consent. Any purported assignment or delegation in violation of this Section is null
          and void. No assignment or delegation relieves you of any of your obligations under these Terms of
          Purchase.</p>

        <h6>15. No Waivers​.</h6>
        <p>The failure by us to enforce any right or provision of these Terms of Purchase will not constitute a waiver
          of future enforcement of that right or provision. The waiver of any right or provision will be effective only
          if in writing and signed by a duly authorized representative.</p>

        <h6>16. No Third-Party Beneficiaries​.</h6>
        <p>These Terms of Purchase do not and are not intended to confer any rights or remedies upon any person other
          than you.</p>

        <h6>17. Notices​.</h6>
        <p>(a) To You​. We may provide any notice to you under these Terms of Purchase by: (i) sending a message to the
          email address you provide to us at the time of purchase or within your account with us or (ii) by posting to
          the Site. Notices sent by email will be effective when we send the email and notices we provide by posting
          will be effective upon posting. It is your responsibility to keep your email address current.
        </p>
        <p>(b) To Us​. To give us notice under these Terms of Purchase, you must contact us by sending an email message
          to [INSERT EMAIL ADDRESS]. We may update the email address by posting a notice on the Site. Notices sent by
          email will be effective when you send the email to us.</p>

        <h6>18. Severability​.</h6>
        <p>If any provision of these Terms of Purchase is invalid, illegal, void or unenforceable, then that provision
          will be deemed severed from these Terms of Purchase and will not affect the validity or enforceability of the
          remaining provisions of these Terms of Purchase.</p>

        <h6>19. Entire Agreement​.</h6>
        <p>These Terms of Purchase, our Website Terms of Use and our Privacy Policy will be deemed the final and
          integrated agreement between you and us on the matters contained in these Terms of Purchase.</p>
      </div>
    )
  }
}
