import React from 'react';
import { connect } from 'react-redux';
import { Button, FormControl, ListGroup, Modal, Form } from 'react-bootstrap';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import * as dashboardActions from '../../actions/dashboardActionCreators'
import LetterApplicationRow from './LetterApplication/LetterApplicationRow';

import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

class Dashboard extends React.Component {
  constructor() {
    super()
    this.state = {
      notes: '',
      showNotesModal: false,
      finishModal: false,
      appTimes: '',
      showAppTimesModal: false,
      selectedTransactionId: null,
      scheduleSelected: null
    };
  }

  componentDidMount = () => {
    this.props.fetchLetterApplications();
    this.props.fetchServiceTransactions();
    this.props.fetchSchedule();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dashboard.isFetching === true
      && this.props.dashboard.isFetching === false
      && (this.state.showNotesModal === true || this.state.showAppTimesModal === true || this.state.finishModal === true)) {
      this.setState({ showNotesModal: false, showAppTimesModal: false, finishModal: false });
    }
  }

  onApprove = (letterApplication) => {
    this.props.approveLetterApplication(letterApplication);
  }

  onDeny = (letterApplication) => {
    this.props.denyLetterApplication(letterApplication);
  }

  onUnable = (letterApplication) => {
    this.props.unableLetterApplication(letterApplication);
  }

  handleShow = () => {
    this.setState({ showNotesModal: true });
  }

  handleFinishModalShow = () => {
    this.setState({ finishModal: true });
  }

  handleAppTimesEditModalShow = () => {
    this.setState({ showAppTimesModal: true });
  }

  onChangeNotes = (value) => {
    this.setState({
      notes: value
    });
  }

  onChangeAppTimes = (value) => {
    this.setState({
      appTimes: value
    });
  }

  handleNotesModalClose = () => {
    this.setState({ showNotesModal: false });
  }

  handleFinishModalClose = () => {
    this.setState({ finishModal: false });
  }

  handleAppTimesModalClose = () => {
    this.setState({ showAppTimesModal: false });
  }

  submitNotes = () => {
    const { notes, selectedTransactionId } = this.state;

    this.props.submitServiceTransactionNotes(selectedTransactionId, notes);
  }

  finishServiceTransaction = () => {
    const { selectedTransactionId } = this.state;

    this.props.finishServiceTransaction(selectedTransactionId);
  }

  submitAppTimes = () => {
    const { appTimes, selectedTransactionId } = this.state;

    this.props.submitServiceTransactionInstructions(selectedTransactionId, appTimes);
  }

  selectEvent = (eventInfo) => {
    this.setState({
      scheduleSelected: eventInfo.id
    });
  }

  closeEvent = () => {
    this.setState({
      scheduleSelected: null
    });
  }

  render() {
    let {letterApplications, service_transactions, schedule, schedule_settings, intervals} = this.props.dashboard;

    intervals = intervals.map((e, i) => {
      return <div className='col-sm-6 col-12' key={i}>
      <div className="d-flex justify-content-between">
        <span className="text-muted">
          {e}
        </span>
      </div>
    </div>
    });

    const events = schedule
    .filter(el => typeof el.letter_application != 'undefined')
    .filter(
      el => el.letter_application.flag != 'woc' &&
      el.letter_application.flag != 'no-response' &&
      el.letter_application.flag != 'rescheduling' &&
      el.letter_application.flag != 'email-to-reschedule'
    )
    .map(event => {
      return {
        title: `${event.letter_application.patient.first_name} ${event.letter_application.patient.last_name}`,
        start: moment(event.start_time).local().toDate(),
        end: moment(event.end_time).local().toDate(),
        id: event.letter_application_id
      };
    });

    const applications = letterApplications.filter((app) => app.status === "assigned")
    .filter((app) => app.flag != 'woc' && app.flag != 'no-response' && app.flag != 'rescheduling' && app.flag != 'email-to-reschedule')
    .sort((a, b) => {
      if (!a.appointment) return 1;
      if (!b.appointment) return -1;
      return new Date(a.appointment) - new Date(b.appointment);
    });
    service_transactions = service_transactions.filter((app) => app.finished_at === null )

    return (
      <div className="container page-space-top-sm page-space-bottom">
        <h4>Dashboard</h4>
        <hr className="header-underline"/>
        <h6 className="text-muted">Cases Assigned to You</h6>
        <ListGroup className="mb-3">
          {
            applications.map(application =>
              <ListGroup.Item key={application.id} className={application.flag === 'no-answer' ? 'aa-no-answer' : ''}>
                <LetterApplicationRow
                  letterApplication={application}
                  onApprove={this.onApprove}
                  onDeny={this.onDeny}
                  onUnable={this.onUnable}
                  scheduleSelected={this.state.scheduleSelected == application.id}
                  scheduleClose={this.closeEvent}
                  legacy={this.props.dashboard.schedule_settings.legacy}
                />
              </ListGroup.Item>
            )
          }
          {
            applications.length === 0 &&
            <p className="text-muted">No cases.</p>
          }
        </ListGroup>
        <Button variant="outline-primary"
                onClick={() => {
                  this.props.history.push('/provider/archived_applications')
                }}>
          View Archived Cases
        </Button>

        <hr/>

        <h6 className="text-muted">Service Transactions</h6>
        <ListGroup className="mb-3">
          {
            service_transactions.map(service_transaction =>
              <ListGroup.Item key={service_transaction.id}>
                <div className="row mb-2">
                  <div className="col-6 col-md-3">
                    <label className="text-muted mb-0"><small>Service</small></label>
                    <p className="mb-0 text-medium">{service_transaction.service_type.title}</p>
                  </div>

                  <div className="col-6 col-md-2">
                    <label className="text-muted mb-0"><small>Patient Name</small></label>
                    <p className="mb-0 text-medium">{service_transaction.service_request.patient_name}</p>
                  </div>

                  <div className="col-6 col-md-2">
                    <label className="text-muted mb-0"><small>Patient Email</small></label>
                    <p className="mb-0 text-medium">{service_transaction.service_request.patient_email}</p>
                  </div>

                  <div className="col-6 col-md-2">
                    <label className="text-muted mb-0"><small>Patient Phone</small></label>
                    <p className="mb-0 text-medium">{service_transaction.service_request.patient_phone}</p>
                  </div>

                  <div className="col-6 col-md-3">
                    <label className="text-muted mb-0"><small>Patient Notes</small></label>
                    <p className="mb-0 text-medium">{service_transaction.service_request.patient_notes}</p>
                  </div>
                </div>

                <hr className="mb-2 mt-2"/>

                <div className="row">
                  <div className="col-12 col-md-3 border-right">
                    <Button variant="outline-primary" size="sm" className="pull-right" onClick={() => {this.setState({selectedTransactionId: service_transaction.id, appTimes: service_transaction.notes}); this.handleAppTimesEditModalShow();}}>
                      <i className="fa fa-pencil" />
                    </Button>
                    <label className="text-muted mb-0"><small>Instructions</small></label>
                    <p className="mb-0 text-medium">{service_transaction.notes}</p>
                  </div>

                  <div className="col-12 col-md-9">
                    <label className="text-muted mb-0"><small>Transactional Notes:</small></label>
                    {
                      service_transaction.service_transaction_notes.map(notes => {
                        return <p key={notes.id} className="mb-0">{new Date(notes.created_at).toLocaleDateString("en-US") + ' - ' +  notes.content}</p>
                      })
                    }
                    <Button variant="outline-primary" size="sm" className="pull-right" onClick={() => {this.setState({selectedTransactionId: service_transaction.id, notes: ''}); this.handleShow();}}>
                      Add Notes
                    </Button>

                  </div>
                </div>

                <hr className="mb-2 mt-2"/>

                <div className="row">
                  <div className="col-12 text-right">
                    <Button variant="primary" className="pull-right" onClick={() => {this.setState({selectedTransactionId: service_transaction.id}); this.handleFinishModalShow();}}>
                      Complete / Close
                    </Button>
                  </div>
                </div>
              </ListGroup.Item>
            )
          }
          {
            service_transactions.length === 0 &&
            <p className="text-muted">No service transactions.</p>
          }
        </ListGroup>
        <Button variant="outline-primary"
                onClick={() => {
                  this.props.history.push('/provider/archived_services')
                }}>
          View Archived Transactions
        </Button>

        <hr/>

        {this.props.dashboard.schedule_settings.legacy === false &&
          <div>
            <div className="mt-3">
              <Calendar
                popup
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 800 }}
                onSelectEvent={this.selectEvent}
                defaultView="week"
                timeslots={10}
                step={15}
              />
            </div>

            <h6 className="text-muted mt-5">Availability</h6>

            <div className='row'>
              {intervals}
            </div>
          </div>
        }

        <Modal show={this.state.showNotesModal} onHide={this.handleNotesModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Transactional Notes</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className='applicant-detail-container'>
              <FormControl
                as="textarea"
                placeholder="Please leave some notes here."
                onChange={(e) => this.onChangeNotes(e.target.value)}
                className="mb-2"
              />
            </div>
          </Modal.Body>

          <Modal.Footer className={"justify-content-between"}>
            <Button
              variant="primary"
              onClick={this.submitNotes}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.finishModal} onHide={this.handleFinishModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Complete Service Transaction</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <label>Are you sure you want to complete and close this service transaction?</label>
          </Modal.Body>

          <Modal.Footer className={"justify-content-between"}>
            <Button
              variant="primary"
              onClick={() => this.finishServiceTransaction()}
            >
              Yes, Complete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showAppTimesModal} onHide={this.handleAppTimesModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Instructions</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className='applicant-detail-container'>
              <FormControl
                as="textarea"
                placeholder="Instructions between staff and provider"
                onChange={(e) => this.onChangeAppTimes(e.target.value)}
                value={this.state.appTimes}
              />
            </div>
          </Modal.Body>

          <Modal.Footer className={"justify-content-between"}>
            <Button
              variant="primary"
              onClick={this.submitAppTimes}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    dashboard: state.dashboard
  }
}

export default connect(mapStateToProps, dashboardActions)(Dashboard)
