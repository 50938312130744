import React from 'react';
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer';
import { ToastContainer, toast } from 'react-toastify';

import './style.css'
import 'react-toastify/dist/ReactToastify.css';

const Layout = ({ children }) => (
  <section>
    <ToastContainer autoClose={8000}/>
    <Header/>
    <main role="main" className="main-wrapper">
      { children }
    </main>
    <Footer/>
  </section>
);

export default Layout
