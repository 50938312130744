import {
  ADD_QUESTIONS,
  ADD_ANIMAL_INFO,
  ADD_ADDITION_INFO,
  ADD_USER_QUESTION_INFO,
  ADD_ADDITIONAL_ANIMALS_INFO
} from '../actions/questionsActionCreators';
import { ESAStore } from '../constant';

const initialState = ESAStore.questions;

const consultationReducer = (state = initialState, action) => {
  const localStorageValue = JSON.parse(localStorage.getItem('ESA'));

  switch( action.type ) {
    case ADD_QUESTIONS:
      localStorageValue.questions = action.payload;
      localStorage.setItem('ESA', JSON.stringify(localStorageValue));
      return {...action.payload}
    case ADD_ANIMAL_INFO:
      localStorageValue.questions.animalInfo = action.payload;
      localStorage.setItem('ESA', JSON.stringify(localStorageValue));
      return {...state, animalInfo: action.payload}
    case ADD_ADDITIONAL_ANIMALS_INFO:
      localStorageValue.questions.additionalAnimals = action.payload;
      localStorage.setItem('ESA', JSON.stringify(localStorageValue));
      return {...state, additionalAnimals: action.payload}
    case ADD_USER_QUESTION_INFO:
      localStorageValue.questions.userQuestion = action.payload;
      localStorage.setItem('ESA', JSON.stringify(localStorageValue));
      return {...state, userQuestion: action.payload}
    case ADD_ADDITION_INFO:
      localStorageValue.questions.addition = action.payload;
      localStorage.setItem('ESA', JSON.stringify(localStorageValue));
      return {...state, addition: action.payload}
    default:
      return state
  }
}

export default consultationReducer
  