import React from 'react';
import { connect } from 'react-redux';
import {Button, Form, ListGroup, Pagination, InputGroup, Modal, FormControl} from 'react-bootstrap';
import Switch from 'react-switch';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import * as dashboardActions from '../../actions/dashboardActionCreators'
import ArchivedLetterApplicationModal from "./LetterApplication/ArchivedLetterApplicationModal";
import {statusTexts, actionTexts, letterTypeTexts, approvalReasonsText} from "../../constant";

class ArchivedApplications extends React.Component {
  constructor() {
    super()
    this.state = {
      activePage: 1,
      searchPatientName: "",
      startDate: null,
      endDate: new Date(),
      showNotesModal: false,
      selectedOrderId: null,
      openCaseId: 0,
      approved: false
    }
  }

  componentDidMount = () => {
    this.props.fetchArchivedLetterApplications(1, this.state.startDate, this.state.endDate, this.state.searchPatientName, this.state.approved);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dashboard.isFetching === true
      && this.props.dashboard.isFetching === false
      && this.state.showNotesModal === true) {
      this.setState({ showNotesModal: false });
    }
  }

  submitNotes = () => {
    const { notes, selectedOrderId } = this.state;

    this.props.submitOrderNotes(selectedOrderId, notes);
  }

  handleSwitch = (checked) => {
    this.setState({
      approved: checked
    }, () => {
      this.props.fetchArchivedLetterApplications(1, this.state.startDate, this.state.endDate, this.state.searchPatientName, this.state.approved);
    });
  }

  render(){
    const { archivedLetterApplications, isFetching, archivedActivePage, archivedTotalPages, count, totalCount } = this.props.dashboard;

    let items = [];
    let startPage, endPage;
    if (archivedTotalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = archivedTotalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (archivedActivePage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (archivedActivePage + 4 >= archivedTotalPages) {
        startPage = archivedTotalPages - 9;
        endPage = archivedTotalPages;
      } else {
        startPage = archivedActivePage - 5;
        endPage = archivedActivePage + 4;
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item key={number} active={number === archivedActivePage} onClick={() => {this.props.fetchArchivedLetterApplications(number, this.state.startDate, this.state.endDate, this.state.searchPatientName, this.state.approved)}}>
          {number}
        </Pagination.Item>
      );
    }

    return (
      <div className="container page-space-top-sm page-space-bottom">
        <div className="d-flex justify-content-between">
          <h4>Archived Cases</h4>
          <div className="mb-0">
            <Button variant="outline-primary"
                    onClick={() => {this.props.history.push('/provider/dashboard')}}>
              <i className="fa fa-chevron-left"/>
              &nbsp;Back To Dashboard
            </Button>
          </div>
        </div>
        <hr className="header-underline mb-1"/>

        <div className="row">
          <div className="col-12 form-inline justify-content-end mb-2">
            <InputGroup className="search-patient-box mr-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend">
                  <span className="fa fa-search"></span>
                </InputGroup.Text>
              </InputGroup.Prepend>

              <Form.Control
                type="text"
                placeholder="Search Patient"
                aria-describedby="inputGroupPrepend"
                name="search"
                value={this.state.searchPatientName}
                onChange={e => {
                  this.setState({searchPatientName: e.target.value});
                  this.props.fetchArchivedLetterApplications(1, this.state.startDate, this.state.endDate, e.target.value, this.state.approved);
                }}
              />
            </InputGroup>

            <DateTime
              value={this.state.startDate}
              onChange={date => {
                this.setState({startDate: date ? new Date(date) : null});
                this.props.fetchArchivedLetterApplications(1, new Date(date), this.state.endDate, this.state.searchPatientName, this.state.approved);
              }}
              timeFormat={false}
              closeOnSelect={true}
              inputProps={{ placeholder: 'From'}}/>

            <span className="text-muted text-large">&nbsp;~&nbsp;</span>

            <DateTime
              value={this.state.endDate}
              onChange={date => {
                this.setState({endDate: date ? new Date(date) : null});
                this.props.fetchArchivedLetterApplications(1, this.state.startDate, new Date(date), this.state.searchPatientName, this.state.approved);
              }}
              timeFormat={false}
              closeOnSelect={true}
              inputProps={{ placeholder: 'To'}}/>

            <span className="text-muted text-small ml-2">Search by approval date:</span>

            <Switch
              onChange={this.handleSwitch}
              checked={this.state.approved}
              onColor="#7EC7D1"
              checkedIcon={false}
              uncheckedIcon={false}
              className="ml-2"
              width={50}
            />

          </div>
        </div>

        <ListGroup className="mb-2">
          <ListGroup.Item key={0}>
            <div className="row">
              <div className="col-6 col-md-1">
                <label className="text-muted mb-0"><small>Submitted</small></label>
              </div>

              <div className="col-6 col-md-2 d-flex justify-content-md-center">
                <label className="text-muted mb-0"><small>Approved</small></label>
              </div>

              <div className="col-6 col-md-2">
                <label className="text-muted mb-0"><small>Patient</small></label>
              </div>

              <div className="col-6 col-md-1">
                <label className="text-muted mb-0"><small>Birth Date</small></label>
              </div>

              <div className="col-6 col-md-1">
                <label className="text-muted mb-0"><small>State</small></label>
              </div>

              <div className="col-6 col-md-2">
                <label className="text-muted mb-0"><small>Status</small></label>
              </div>

              <div className="col-6 col-md-1">
                <label className="text-muted mb-0"><small>Type</small></label>
              </div>

              <div className="col-6 col-md-2">
                <label className="text-muted mb-0"><small>Animals</small></label>
              </div>
            </div>
          </ListGroup.Item>
          {isFetching ?
            <p className="text-muted">Loading...</p>
            :
            archivedLetterApplications.map(application =>
              <ListGroup.Item key={application.id} onClick={(e) => this.setState({openCaseId: application.id})} >
                <div className="row align-items-center">
                  <div className="col-6 col-md-1 d-flex justify-content-flex-start align-items-center">
                    {
                      this.state.openCaseId === application.id ?
                        <i className="fa fa-chevron-right pb-1 pr-1"></i> :
                        <i className="fa fa-chevron-down pb-1 pr-1"></i>
                    }
                    <span className="mb-0 text-medium">{new Date(application.created_at).toLocaleDateString("en-US")}</span>
                  </div>

                  <div className="col-6 col-md-2 d-flex justify-content-md-center align-items-center">
                    <span className="mb-0 text-medium">{application.approved_on ? new Date(application.approved_on).toLocaleDateString("en-US") : ''}</span>
                  </div>

                  {typeof application.patient != 'undefined' && (
                    <>
                      <div className="col-6 col-md-2">
                        <p className="mb-0 text-medium">{`${application.patient.first_name} ${application.patient.last_name}`}</p>
                      </div>
                      <div className="col-6 col-md-1">
                        <p className="mb-0 text-medium">{new Date(application.patient.profile.birthdate).toLocaleDateString("en-US")}</p>
                      </div>

                      <div className="col-6 col-md-1">
                        <p className="mb-0 text-medium">{application.patient.profile.address_state}</p>
                      </div>
                    </>
                  )}

                  <div className="col-6 col-md-2">
                    <p className="text-medium mb-0">{statusTexts[application.status]}</p>
                  </div>

                  <div className="col-6 col-md-1">
                    <p className="text-medium mb-0">{letterTypeTexts[application.product]}</p>
                  </div>

                  <div className="col-6 col-md-1">
                    <p className="text-medium mb-0">{application.animals.length}</p>
                  </div>

                  <div className="col-12 col-md-1 d-flex justify-content-end align-items-center">
                    <ArchivedLetterApplicationModal
                      letterApplication={application}
                    />
                  </div>
                </div>
                {this.state.openCaseId === application.id &&
                  <div>
                    <hr className="my-2"/>
                    <div className="row">
                      <div className="col-12 col-md-8">
                        <label className="text-muted">Notes:</label><br/>
                        {
                          application.letter_application_actions.filter((action) =>
                            ['add_notes', 'approve_application', 'deny_application'].includes(action.action)
                          ).map(action => {
                            return <span key={action.id}>{new Date(action.done_at).toLocaleDateString("en-US")} - {actionTexts[action.action]}: {action.notes}<br/></span>
                          })
                        }
                      </div>
                      <div className="col-12 col-md-4">
                        <Button variant="outline-primary" size="sm" className="pull-right" onClick={() => {this.setState({selectedOrderId: application.id, notes: '', showNotesModal: true})}}>
                          Add Notes
                        </Button>
                      </div>
                    </div>
                  </div>
                }
              </ListGroup.Item>
            )
          }
          {
            archivedLetterApplications.length === 0 &&
            <p className="text-muted">No archived cases.</p>
          }
        </ListGroup>

        <p className="pull-left text-muted ">Showing <b>{count}</b> items of <b>{totalCount}</b> in total</p>

        <Pagination className="justify-content-end">
          <Pagination.Item className={archivedActivePage === 1 ? 'disabled' : ''}
                           onClick={() => {this.props.fetchArchivedLetterApplications(1, this.state.startDate, this.state.endDate, this.state.searchPatientName, this.state.approved)}}>
            First
          </Pagination.Item>
          <Pagination.Item className={archivedActivePage === 1 ? 'disabled' : ''}
                           onClick={() => {this.props.fetchArchivedLetterApplications(archivedActivePage - 1, this.state.startDate, this.state.endDate, this.state.searchPatientName, this.state.approved)}}>
            Previous
          </Pagination.Item>
          {items}
          <Pagination.Item className={archivedActivePage === archivedTotalPages ? 'disabled' : ''}
                           onClick={() => {this.props.fetchArchivedLetterApplications(archivedActivePage + 1, this.state.startDate, this.state.endDate, this.state.searchPatientName, this.state.approved)}}>
            Next
          </Pagination.Item>
          <Pagination.Item className={archivedActivePage === archivedTotalPages ? 'disabled' : ''}
                           onClick={() => {this.props.fetchArchivedLetterApplications(archivedTotalPages, this.state.startDate, this.state.endDate, this.state.searchPatientName, this.state.approved)}}>
            Last
          </Pagination.Item>
        </Pagination>

        <Modal show={this.state.showNotesModal} onHide={this.handleNotesModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Notes</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className='applicant-detail-container'>
              <FormControl
                as="textarea"
                placeholder="Please leave some notes here."
                onChange={(e) => this.setState({
                  notes: e.target.value
                })}
                className="mb-2"
              />
            </div>
          </Modal.Body>

          <Modal.Footer className={"justify-content-between"}>
            <Button
              variant="primary"
              onClick={this.submitNotes}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    dashboard: state.dashboard
  }
}

export default connect(mapStateToProps, dashboardActions)(ArchivedApplications)
