export const additionalAnimalPrice = 49;
export const productsList = [
  {
    id: 0,
    data: {
      title: 'ESA Housing Letter',
      comment: 'Our housing letter similar to the one below, signed by a licensed, qualified clinician, is your ticket to allowing your emotional support animal in your dwelling regardless if your landlord has a no-pet deposit or a hefty pet deposit. Our clinician will base their letter on your answers to our questionnaire.',
      image: '/images/product_housing.jpeg',
      letter: {
        title: 'Name of Professional (therapist, physician, rehabilitation counselor)',
        address: {
          street: 'xxx Road',
          city: 'City',
          state: 'State',
          zip: 'Zip'
        },
        header: 'Dear [Housing Authority/Landlord]:',
        content: [
          '[Full Name of Tenant] is my Patient, and has been under my care since [date]. I am intimately familiar with his/her history and with the functional limitations imposed by his/her disability. He/She meets hte definition of disability under the Americans with Disabilities Act, the Fair Housing Act, and the Rehabilitation Act of 1973.',
          'Due to mental illness, [first name] has certain limitations regarding [social interaction/coping with stress/anxiety, etc]. In order to help alleviate these difficulties, and to enhance his/her ability to live independently and to fully use and enjoy the dwelling unit you own and/or administer, I am prescribing an emotional support animal that will assist [first name] in coping with his/her disbility.',
          'I am familiar with the voluminous professional literature concerning the therapeutic benefits of assistance animals for people with disabilities such as that experienced by [first name]. Upon request, I will share citations to relevant studies, and would be happy to answer other questions you may have concerning my recommendation that [Full Name of Tenant] have an emotional support animal. Should you have additional questions, please do not hesitate to contact me.'
        ],
        footer: [
          'Sincerely,',
          'Name of Professional'
        ]
      },
      features: [
        'Exempt from “No Pet” housing',
        'Exempt from pet deposit and fee',
        'Valid in all housing',
        'Doctor signed letter within 1 business day',
        'All housing forms included',
        '100% money back guarantee'
      ]
    },
    price: 99,
    ref: 'housing-letter',
    name: 'Housing Letter'
  },
  {
    id: 1,
    data: {
      title: 'ESA Housing & Travel Letter',
      comment: '',
      image: '/images/product_both.jpeg',
      letter: '',
      features: [
        'Exempt from “No Pet” housing',
        'Exempt from pet deposit and fee',
        'Exempt from airline pet fee',
        'Fly inside the aircraft cabin',
        'Valid in all housing and airlines',
        'Doctor signed letter within 1 business day',
        'All airline forms included',
        '100% money back guarantee'
      ]
    },
    price: 129,
    ref: 'combo-letter',
    name: 'Combo Letters'
  },
  {
    id: 2,
    data: {
      title: 'ESA Travel Letter',
      comment: 'Traveling by air with a pet is expensive and restrictive - but not if you qualify for an Emotional Support Animal Travel Letter. Our licensed will assess your answers and, once they have approved you for a letter, you’ll be flying with your pet with you in the cabin for free in no time!',
      image: '/images/product_travel.jpeg',
      letter: {
        title: '',
        address: '',
        header: 'To Whom it May Concern:',
        content: [
          'Under the provisions of The Health Insurance Portability and Accountability Act of 1996 (HIPAA), I have been authorized to disclose that i am providing the patient listed above with treatment for a mental and emotiional disability recognized in the Diagnostic and Statistical Manual of Mental Disorders - Fifth Edition [DSM-V].',
          'Being familar with the limitations of their illness, I have prescribed an emotional support animal [ESA] as identified above to assist with recovery.',
          'It is my professional opinion that my patient needs their emotional support animal as an accommodation for air travel and activity at their destination.',
          'In accordance with the Air Carrier Access Act [49 U.S.C. 41705 and C.F.R. 382], an emotional support animal is required to accompany my patient in the cabin of the aircraft for the reasons stated herein.'
        ],
        footer: [
          'Sincerely,'
        ]
      },
      features: [
        'Exempt from airline pet fee',
        'Fly inside the aircraft cabin',
        'Valid in all airlines',
        'Doctor signed letter within 1 business day',
        'All airline forms included',
        '100% money back guarantee'
      ]
    },
    price: 99,
    ref: 'travel-letter',
    name: 'Travel Letter'
  }
];

export const userQuestions = [
  {
    id: 0,
    title: 'Are you under a high amount of stress? *',
    key: 'stress'
  },
  {
    id: 1,
    title: 'Do you feel overwhelmed by your current life situation? *',
    key: 'overwhelm'
  },
  {
    id: 2,
    title: 'Do you feel discouraged or pessimistic about life? *',
    key: 'pessimistic'
  },
  {
    id: 3,
    title: 'Do you feel depressed or saddened by your life situation? *',
    key: 'sad'
  },
  {
    id: 4,
    title: 'Do you feel "beat-down" or "burnt out" by your current life circumstances? *',
    key: 'beatDown'
  },
  {
    id: 5,
    title: 'Do you keep worrying about your future, finances, or family? *',
    key: 'worry'
  },
  {
    id: 6,
    title: 'Do you feel frustrated, uneasy, or burdened in your current life situation? *',
    key: 'frustrate'
  },
  {
    id: 7,
    title: 'Do you feel tense and uptight about your current life situation? *',
    key: 'tense'
  },
  {
    id: 8,
    title: 'Does your stress interfere with your work or sleep? *',
    key: 'workStress'
  },
  {
    id: 9,
    title: 'Does your stress interfere with your ability to focus or concentrate on what you have to do? *',
    key: 'concentrate'
  },
  {
    id: 10,
    title: 'Do you turn to alcohol, food, or drugs to comfort you from the stress factors in your life? *',
    key: 'alcohol'
  },
  {
    id: 11,
    title: 'Do you feel irritable and impatient in your everyday life? *',
    key: 'irritable'
  },
  {
    id: 12,
    title: 'Do you feel lonely? *',
    key: 'lonely'
  },
  {
    id: 13,
    title: 'Do you feel like you lack an emotional support system? *',
    key: 'emotional'
  },
  {
    id: 14,
    title: 'Does your stress level interfere with your communication with loved ones, friends, or coworkers? *',
    key: 'communication'
  },
  {
    id: 15,
    title: 'Are you currently subject to outbursts, mood swings, or long periods of negativity? *',
    key: 'outburst'
  }
];

export const userAdditionalQuestions = [
  {
    id: 0,
    title: 'In the past 6 months, have you had any thought of suicide, wished you were dead, or thought your family would be better off if you were dead?',
    key: 'wishDead'
  },
  {
    id: 1,
    title: 'Have you ever attempted to commit suicide?',
    key: 'attemptSuicide'
  },
  {
    id: 2,
    title: 'Which significant events have caused you significant stress this year?',
    key: 'significantEvent'
  },
  {
    id: 3,
    title: 'Please list any physician who has prescribed you medicine or any psychiatrist you are currently seeing',
    key: 'psychiatrist'
  }
];

export const userQuestionOptions = [
  {
    name: 'Select Frequency',
    value: ''
  },
  {
    name: 'Never',
    value: 'Never'
  },
  {
    name: 'Rarely',
    value: 'Rarely'
  },
  {
    name: 'Sometimes',
    value: 'Sometimes'
  },
  {
    name: 'Usually',
    value: 'Usually'
  },
  {
    name: 'Always',
    value: 'Always'
  }
];

export const userQuestionDurationOptions = [
  {
    name: '1yr+',
    value: '1yr+'
  },
  {
    name: '6mon+',
    value: '6mon+'
  },
  {
    name: '3mon+',
    value: '3mon+'
  },
  {
    name: 'less',
    value: 'less'
  }
];

export const ESAStore = {
  product: {
    id: -1
  },
  userInfo: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    sex: '',
    address: {
      street: '',
      city: '',
      state: '',
      zip: ''
    },
    birthdate: {
      day: '',
      month: '',
      year: ''
    }
  },
  questions: {
    userQuestion: {
      checkableQuestions: {
        stress: {},
        overwhelm: {},
        pessimistic: {},
        sad: {},
        beatDown: {},
        worry: {},
        frustrate: {},
        tense: {},
        workStress: {},
        concentrate: {},
        alcohol: {},
        irritable: {},
        lonely: {},
        emotional: {},
        communication: {},
        outburst: ''
      },
      significantEvent: '',
      wishDead: -1,
      attemptSuicide: -1,
      psychiatrist: ''
    },
    animalInfo: {
      animalName: '',
      breedName: '',
      type: 'dog',
      age: '',
      wght: '',
      gender: '',
      support: ''
    },
    addition: '',
    additionalAnimals: []
  }
}

export const approvalReasons = [
  { value: 'learningDisorder', label: 'Learning Disorder' },
  { value: 'anxietyDisorder', label: 'Anxiety Disorder' },
  { value: 'bipolar', label: 'Bipolar' },
  { value: 'depression', label: 'Depression' },
  { value: 'stressDisorder', label: 'Stress Disorder' },
  { value: 'austism', label: 'Austism' },
  { value: 'identity', label: 'Identity' },
  { value: 'cognitiveDisorder', label: 'Cognitive Disorders' },
  { value: 'severeAnxiety', label: 'Severe Anxiety' },
  { value: 'ptsd', label: 'Post-Traumatic Stress Disorder'},
  { value: 'adjustmentDisorder', label: 'Adjustment Disorder'},
  { value: 'personalityDisorder', label: 'Personality Disorder'},
  { value: 'substanceUseDisorder', label: 'Substance Use Disorder'},
  { value: 'relationshipIssues', label: 'Relationship Issues With Spouse/Significant Other'},
  { value: 'bereavement', label: 'Bereavement'},
  { value: 'add-adhd', label: 'ADD/ADHD'},
  { value: 'anxietyDisorderDueToAGeneralMedicalCondition', label: 'Anxiety Disorder Due To A General Medical Condition'},
  { value: 'moodDisorder', label: 'Mood Disorder Due To A General Medical Condition'},
];

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const statesList = [{
  value: 'AL',
  name: 'Alabama'
}, {
  value: 'AK',
  name: 'Alaska'
}, {
  value: 'AZ',
  name: 'Arizona'
}, {
  value: 'AR',
  name: 'Arkansas'
}, {
  value: 'CA',
  name: 'California'
}, {
  value: 'CO',
  name: 'Colorado'
}, {
  value: 'CT',
  name: 'Connecticut'
}, {
  value: 'DE',
  name: 'Delaware'
}, {
  value: 'DC',
  name: 'District of Columbia'
}, {
  value: 'FL',
  name: 'Florida'
}, {
  value: 'GA',
  name: 'Georgia'
}, {
  value: 'HI',
  name: 'Hawaii'
}, {
  value: 'ID',
  name: 'Idaho'
}, {
  value: 'IL',
  name: 'Illinois'
}, {
  value: 'IN',
  name: 'Indiana'
}, {
  value: 'IA',
  name: 'Iowa'
}, {
  value: 'KS',
  name: 'Kansas'
}, {
  value: 'KY',
  name: 'Kentucky'
}, {
  value: 'LA',
  name: 'Louisiana'
}, {
  value: 'ME',
  name: 'Maine'
}, {
  value: 'MD',
  name: 'Maryland'
}, {
  value: 'MA',
  name: 'Massachusetts'
}, {
  value: 'MI',
  name: 'Michigan'
}, {
  value: 'MN',
  name: 'Minnesota'
}, {
  value: 'MS',
  name: 'Mississippi'
}, {
  value: 'MO',
  name: 'Missouri'
}, {
  value: 'MT',
  name: 'Montana'
}, {
  value: 'NE',
  name: 'Nebraska'
}, {
  value: 'NV',
  name: 'Nevada'
}, {
  value: 'NH',
  name: 'New Hampshire'
}, {
  value: 'NJ',
  name: 'New Jersey'
}, {
  value: 'NM',
  name: 'New Mexico'
}, {
  value: 'NY',
  name: 'New York'
}, {
  value: 'NC',
  name: 'North Carolina'
}, {
  value: 'ND',
  name: 'North Dakota'
}, {
  value: 'OH',
  name: 'Ohio'
}, {
  value: 'OK',
  name: 'Oklahoma'
}, {
  value: 'OR',
  name: 'Oregon'
}, {
  value: 'PA',
  name: 'Pennsylvania'
}, {
  value: 'RI',
  name: 'Rhode Island'
}, {
  value: 'SC',
  name: 'South Carolina'
}, {
  value: 'SD',
  name: 'South Dakota'
}, {
  value: 'TN',
  name: 'Tennessee'
}, {
  value: 'TX',
  name: 'Texas'
}, {
  value: 'UT',
  name: 'Utah'
}, {
  value: 'VT',
  name: 'Vermont'
}, {
  value: 'VA',
  name: 'Virginia'
}, {
  value: 'WA',
  name: 'Washington'
}, {
  value: 'WV',
  name: 'West Virginia'
}, {
  value: 'WI',
  name: 'Madison'
}, {
  value: 'WY',
  name: 'Cheyenne'
}];

export const actionTexts = {
  'add_notes': 'Added Notes',
  'approve_application': 'Case Approved',
  'deny_application': 'Case Denied'
}

export const statusTexts = {
  'pending': 'Pending',
  'onhold': 'Case On-hold',
  'new': 'New Case',
  'assigned': 'Assigned Case',
  'approved': 'Approved Case',
  'denied': 'Denied Case',
  'generated': 'Letters Generated',
  'delivered': 'Letters Delivered',
  'refunded': 'Payment Refunded'
}

export const letterTypeTexts = {
  'housing-letter': 'Housing',
  'travel-letter': 'Travel',
  'combo-letter': 'Both'
}

export const approvalReasonsText = {
  'learningDisorder': 'Learning Disorder',
  'anxietyDisorder': 'Anxiety Disorder',
  'bipolar': 'Bipolar',
  'depression': 'Depression',
  'stressDisorder': 'Stress Disorder',
  'austism': 'Austism',
  'identity': 'Identity',
  'cognitiveDisorder': 'Cognitive Disorders',
  'severeAnxiety': 'Severe Anxiety',
  'ptsd': 'Post-Traumatic Stress Disorder',
  'adjustmentDisorder': 'Adjustment Disorder'
};
