import React from 'react';
import {connect} from "react-redux";
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {Form, Button, FormGroup, FormControl, Alert, FormLabel, Badge} from 'react-bootstrap';
import {CardElement, injectStripe} from 'react-stripe-elements';

import {statesList, productsList, additionalAnimalPrice} from '../../constant';
import {addUserInfo, submitPayment} from "../../actions/paymentActionCreators";
import {FieldInput, FieldSelect} from '../formFields';
import {required, email, requiredSelection} from '../formValidations';
import './PaymentForm.css';


class PaymentForm extends React.Component {

  getUSStatesOptions() {
    let arr = [];
    arr.push(<option key={-1} value={""}>{"Select State"}</option>)
    statesList.map((state, index) => {
      arr.push(<option key={index} value={state.value}>{state.value}</option>);
    });
    return arr;
  }

  getPaymentInfo() {
    const {payment, questions} = this.props;
    if (!payment.checkout && (payment.productId !== undefined && payment.productId !== null)) {
      return {
        ...this.props.payment,
        checkout: {
          subtotal: productsList[this.props.payment.productId].price,
          orderTotal: productsList[this.props.payment.productId].price + additionalAnimalPrice * (questions.additionalAnimals ? questions.additionalAnimals.length : 0),
        }
      };
    }
    return payment;
  }

  handleSubmitPayment = (data) => {
    const { userInfo, questions } = this.props;
    const payment = this.getPaymentInfo();

    let animals = [];
    if (questions.additionalAnimals)
      animals = questions.additionalAnimals.slice(0);

    animals.unshift(questions.animalInfo);

    if (payment && payment.checkout) {
      return this.props.stripe.createToken({
        name: `${data.firstName} ${data.lastName}`,
        email: data.email,
        amount: payment.checkout.orderTotal
      })
        .then(({token}) => {
          if (token) {
            return this.props.submitPayment({
              token: token.id,
              amount: payment.checkout.orderTotal,
              product: productsList[payment.productId].ref,
              patient: userInfo,
              animals: animals,
              billing_info: data,
              condition: JSON.stringify(this.props.questions.userQuestion)
            })
          } else {
            throw 'Token error';
          }
        })
        .catch((e) => {
          throw new SubmissionError({
            _error: 'An error occurred while processing your card.'
          });
        })
    }
  }

  render() {
    const {handleSubmit, submitting, error} = this.props;
    const payment = this.getPaymentInfo();

    return (
      <form onSubmit={handleSubmit(this.handleSubmitPayment)}>

        {payment.errors && payment.errors.global &&
        <Alert variant="danger">
          <strong>{payment.errors.global}</strong>
        </Alert>
        }

        {error && (!payment.errors || !payment.errors.global) &&
          <Alert variant="danger">
            <strong>{error}</strong>
          </Alert>
        }

        <div className="form-group">
          <h6 className="text-muted">Billing Information</h6>
        </div>

        <Field name="email"
               type='email'
               component={FieldInput}
               placeholder="Email"
               validate={[required, email]}
        />
        <Field name="firstName"
               type='text'
               component={FieldInput}
               placeholder="First Name"
               validate={required}
        />
        <Field name="lastName"
               type='text'
               component={FieldInput}
               placeholder="Last Name"
               validate={required}
        />
        <Field name="street"
               type='text'
               component={FieldInput}
               placeholder="Billing Street"
               validate={required}
        />
        <div className="row mx-gutters-1">
          <div className="col-5">
            <Field name="city"
                   type='text'
                   component={FieldInput}
                   placeholder="City"
                   validate={required}
            />
          </div>
          <div className="col-3">
            <Field name="state"
                   component={FieldSelect}
                   options={this.getUSStatesOptions()}
                   placeholder="state"
                   validate={required}
            />
          </div>
          <div className="col-4">
            <Field name="zip"
                   type='text'
                   component={FieldInput}
                   placeholder="Zip"
                   validate={required}
            />
          </div>
        </div>
        <FormGroup>
          <CardElement className="form-control"/>
        </FormGroup>
        <div className="row">
          <div className="col-lg-4 align-self-center">
            <Button
              variant="primary"
              type="submit"
              disabled={submitting}>
              {
                submitting ?
                  <div>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                    &nbsp;Processing
                  </div>
                  :
                  "Pay $" + payment.checkout.orderTotal
              }
            </Button>
          </div>
          <div className="col-lg-8 align-self-center mt-lg-0 mt-4 text-lg-right text-left">
            <img className="payment-form-stripe-logo" src="/images/secure-stripe-payment-logo.png" alt="Stripe"/>
          </div>
        </div>
      </form>
    )
  }
}

const createReduxForm = reduxForm({form: 'payment'})

PaymentForm = createReduxForm(PaymentForm)

function bindAction(dispatch) {
  return {
    submitPayment: (data) => dispatch(submitPayment(data))
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    payment: state.payment,
    questions: state.questions,
    initialValues: {
      email: ownProps.userInfo.email,
      firstName: ownProps.userInfo.firstName,
      lastName: ownProps.userInfo.lastName,
      street: ownProps.userInfo.address.street,
      city: ownProps.userInfo.address.city,
      state: ownProps.userInfo.address.state,
      zip: ownProps.userInfo.address.zip
    },
  }
}

export default injectStripe(connect(mapStateToProps, bindAction)(PaymentForm));
