import React from 'react';
import { Button, Alert, Modal, FormControl } from "react-bootstrap";

class ConfirmAppointmentModalDecline extends React.Component {
  state = {
    note: ''
  };

  updateNote = (note) => {
    this.setState({ note });
  }

  handleSubmit = () => {
    this.props.submit(this.state.note);
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.close}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Add a note:</p>
          <FormControl
            as="textarea"
            placeholder="Please provide the best days and times that work for you to be rescheduled"
            onChange={(e) => this.updateNote(e.target.value)}
          />
        </Modal.Body>

        <Modal.Footer className={"justify-content-between"}>
          <Button
            variant="primary"
            onClick={this.handleSubmit}
            disabled={!this.state.note}
          >
            {this.props.title}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ConfirmAppointmentModalDecline;