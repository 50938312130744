import { createStore, applyMiddleware } from 'redux'
import { rootReducer } from './reducers/index'
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history'
import { addToCart, addUserInfo } from '../Main/actions/paymentActionCreators';
import { addQuestions } from '../Main/actions/questionsActionCreators';
import { ESAStore } from './constant';

const history = createBrowserHistory()

let store;
if (process.env.RAILS_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
  ));
} else {
  store = createStore(rootReducer, applyMiddleware(thunk));
}

if (localStorage.getItem('ESA')) {
  const localStorageValue = JSON.parse(localStorage.getItem('ESA'));
  store.dispatch(addToCart(localStorageValue.product.id));
  store.dispatch(addUserInfo(localStorageValue.userInfo));
  store.dispatch(addQuestions(localStorageValue.questions));
} else {
  localStorage.setItem('ESA', JSON.stringify(ESAStore));
}

export {store, history}
