import React from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import {Badge, Button, FormLabel} from 'react-bootstrap';
import queryString from 'query-string';

import PaymentForm from '../../components/PaymentForm/PaymentForm';
import FreeForm from '../../components/PaymentForm/FreeForm';
import { productsList } from '../../constant';
import * as paymentActions from "../../actions/paymentActionCreators";

import './style.css';


class CheckoutContainer extends React.Component {
  state = {
    showSuccessMessage: false
  }

  componentDidMount() {
    const { actions } = this.props;
    const parsed = queryString.parse(location.search);

    if (parsed.product) {
      actions.paymentActions.addToCart(parseInt(parsed.product));
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.payment.submitting && !nextProps.payment.submitting
      && nextProps.payment.errors === null) {
      this.setState({showSuccessMessage: true})
    }
    if (!this.props.payment.submitting && nextProps.payment.submitting) {
      this.setState({showSuccessMessage: false})
    }
  }

  isValidProduct = (productId) => {
    return productId !== null && productsList.filter(e => e.id === productId).length > 0;
  }

  render() {
    const { questions, payment } = this.props;
    const { userInfo, productId } = payment;
    const parsed = queryString.parse(location.search);

    return (
      <div className="container page-space-top page-space-bottom">
        <h2 className="page-header">{parsed.discount_token ? 'Finalize' : 'Checkout'}</h2>
        <hr className="header-underline"/>

        <div className={(parsed.discount_token ? "d-none" : "d-flex") + " flex-column justify-content-between mb-5"}>
          <h6 className="text-muted mb-3">Service Selection</h6>
          <div className="d-flex">
            <Button variant={productId === 0 ? "primary" : "outline-primary"} className="mr-2"
                    onClick={() => {this.props.actions.paymentActions.addToCart(0);}}>
              Housing <Badge variant="light" pill>$99</Badge>
            </Button>
          </div>
        </div>

        <div className="row">
          <div className={(parsed.discount_token ? "d-none" : "") + " col-lg-5 mb-4 mb-lg-0"}>
            <h6 className="text-muted mb-3">
              Order Summary
            </h6>
            <div className="p-3 card-bordered border-primary">
              <div className="p-2 heading-card">
                <p className="mb-0 text-large">
                  {
                    this.isValidProduct(productId) ?
                      <div className="cart-info-row">
                        <span className="title">
                          {productsList[productId].data.title}
                        </span>
                      </div>
                      : null
                  }
                </p>
              </div>
              <hr/>

              <div className="p-2">
                <div className="d-flex justify-content-between mb-3">
                  <span className="text-muted text-large">
                    Letter
                  </span>
                  {
                    this.isValidProduct(productId) &&
                      <span className="text-large">
                        ${productsList[productId].price}
                      </span>
                  }
                </div>
                <div className="d-flex justify-content-between">
                  <span className="text-muted text-large">
                    Additional Animals
                  </span>
                  <span className="text-large">
                    {questions.additionalAnimals && questions.additionalAnimals.length > 0 ? `$49  x  ${questions.additionalAnimals.length}` : "No"}
                  </span>
                </div>
              </div>
              <hr/>

              {this.isValidProduct(productId) &&
              <div className="p-2">
                <div className="d-flex justify-content-between">
                  <span className="text-muted text-large">
                    Order Total
                  </span>
                  {parsed.discount_token ?
                    <div>
                      <span className="text-large text-blured">
                        ${productsList[productId].price + (49 * (questions.additionalAnimals ? questions.additionalAnimals.length : 0))}
                      </span>
                      <span className="text-large">
                        &nbsp;&nbsp;0$
                      </span>
                    </div>
                    :
                    <span className="text-large">
                      ${productsList[productId].price + (49 * (questions.additionalAnimals ? questions.additionalAnimals.length : 0))}
                    </span>
                  }
                </div>
              </div>}
            </div>
          </div>

          {parsed.discount_token ?
            <div className="col-lg-7">
              <FreeForm userInfo={userInfo} discountToken={parsed.discount_token}/>
            </div>
            :
            <div className="col-lg-7">
              {this.isValidProduct(productId) &&
              <PaymentForm userInfo={userInfo}/>
              }
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    payment: state.payment,
    questions: state.questions
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      paymentActions: bindActionCreators(paymentActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutContainer);
