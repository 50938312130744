import {
  SUBMIT_SCHEDULE_REQUEST,
  SUBMIT_SCHEDULE_SUCCESS,
  SUBMIT_SCHEDULE_FAILURE,
  SUBMIT_SCHEDULE_NOT_FOUND,
  SUBMIT_SCHEDULE_USED,
  FETCH_SCHEDULE_INFO_REQUEST,
  FETCH_SCHEDULE_INFO_SUCCESS,
  FETCH_SCHEDULE_INFO_FAILURE
} from '../actions/scheduleActionCreators';

const initialState = {
  sanding: false,
  failed: false,
  errors: [],
  message: "",
  loading: false,
  appoinment: []
};

const scheduleReducer = (state = initialState, action) => {
  switch(action.type){
    case SUBMIT_SCHEDULE_REQUEST:
      return {...state, sanding: true, failed: false}
    case SUBMIT_SCHEDULE_FAILURE:
      return {...state, sanding: false, failed: true}
    case SUBMIT_SCHEDULE_SUCCESS:
      return {...state, sanding: false}
    case SUBMIT_SCHEDULE_NOT_FOUND:
      return {...state, sanding: false, message: 'not found'}
    case SUBMIT_SCHEDULE_USED:
      return {...state, sanding: false, message: 'used'}
    case FETCH_SCHEDULE_INFO_REQUEST:
      return { ...state, loading: true, failed: false }
    case FETCH_SCHEDULE_INFO_SUCCESS:
      return { ...state, loading: false, appoinment: action.payload, failed: false }
    case FETCH_SCHEDULE_INFO_FAILURE:
      return { ...state, loading: false, failed: true }
    default:
      return state
  }
}

export default scheduleReducer