import React from 'react';
import { Route, Redirect } from 'react-router';

const DoctorRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    !(sessionStorage.accountType === "Provider") ?  (
      <Redirect to={{
        pathname: '/',
        state: { from: props.location }
      }}/>
    ) : (
      <Component {...props}/>
    )
  )}/>
)

export default DoctorRoute
