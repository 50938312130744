import React from 'react';
import { Button } from 'react-bootstrap';
import {Link} from "react-router-dom";

import './style.css';

export default class HowItWorksContainer extends React.Component {
  render() {
    return (
      <div className="page-space-bottom">
        <div className="hiw-intro-section">
          <div className="container pt-4 pt-md-5 pt-lg-3">
            <div className="align-items-center flex-column-reverse flex-lg-row row">
              <div className="col-lg-8 d-lg-block px-lg-0 image-container">
                <figure className="w-100 m-0">
                  <img src="/images/hiw_intro_just_house.svg" className="img-fluid" />
                </figure>
              </div>

              <div className="col-lg-4 col-12 px-lg-0">
                <h3 className="mb-3 mb-lg-4 text-primary">What is an Emotional<br/>Support Animal?</h3>
                <p className="text-muted text-small">An Emotional Support Animal is any animal that provides support and comfort to deal with challenges that might otherwise compromise quality of life.</p>
                <p className="text-muted text-small">Federal laws allow, with a doctor’s recommendation, your animal to accompany you in housing (including “No Pet” housing).</p>
              </div>
            </div>
          </div>
          <div className="intro-section-gap" />
        </div>

        <div className="hiw-features-section container bn-space-top">
          <div className="row">
            <div className="col-lg-4 d-flex flex-column text-center align-items-center">
              <div className="feature-item-container">
                <img src="/images/hiw_feature1.svg" className="img-fluid p-3" />
                <div className="feature-item-desc">
                  <h6 className="text-primary">Who qualifies to have an Emotional Support Animal?</h6>
                  <p className="text-muted text-small">If you suffer from an emotional or psychological condition, and having an animal around comforts you, this animal qualifies as an Emotional Support Animal for you.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-flex flex-column text-center align-items-center">
              <div className="feature-item-container">
                <img src="/images/hiw_feature2.svg" className="img-fluid p-3" />
                <div className="feature-item-desc">
                  <h6 className="text-primary">How do I make my animal an Emotional Support Animal?</h6>
                  <p className="text-muted text-small">Fill out our simple questionnaire to qualify under federal law to have an Emotional Support Animal.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-flex flex-column text-center align-items-center">
              <div className="feature-item-container">
                <img src="/images/hiw_feature3.svg" className="img-fluid p-3" />
                <div className="feature-item-desc">
                  <h6 className="text-primary">When will I receive my letter of approval?</h6>
                  <p className="text-muted text-small">Your request will be reviewed by a licensed practitioner. You will receive your approval same day or next business day.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hiw-functions-section bn-space-top">
          <h3 className="text-primary text-center mb-4 mb-lg-5">Benefits of an Emotional Support Animal <br/>Authorization Letter?</h3>
          <div className="d-flex">
            <div className="benefit-left-container w-50">

              <div className="pull-right">
                <div className="benefit-item-container flex-column flex-lg-row align-items-center d-flex media mt-5 mt-lg-3 mr-md-4 mr-lg-5">
                  <img className="img-fluid mr-lg-3" src="/images/hiw_benefits_avoid_nopet.svg" width="80px"/>
                  <div className="media-body mt-1 mt-lg-0 text-center text-lg-left">
                    <p className="text-muted text-medium mb-0">Avoid "No pet"</p>
                    <p className="text-muted text-medium mb-2">housing restrictions</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="benefit-right-container w-50">
              <div className="pull-left">
                <div className="benefit-item-container flex-column flex-lg-row-reverse align-items-center d-flex media mt-5 mt-lg-3 ml-md-4 ml-lg-5">
                  <img className="img-fluid ml-lg-3" src="/images/hiw_benefits_avoid_paying.svg" width="80px"/>
                  <div className="media-body mt-1 mt-lg-0 text-center text-lg-right">
                  <p className="text-muted text-medium mb-0">Avoid paying</p>
                    <p className="text-muted text-medium mb-2">pet fees</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="hiw-reasons-section container bn-space-top px-5">
          <h3 className="text-primary text-center mb-4 mb-lg-5">Why AnimalAccess?</h3>
          <div className="mx-lg-5 px-lg-5">
            <div className="row">
              <div className="col-12 col-md-6 d-flex justify-content-center mb-3 mb-lg-4">
                <div className="reason-item-container align-items-start d-flex media">
                  <img className="img-fluid mr-3" src="/images/hiw_why_bn1.svg" width="80px"/>
                  <div className="media-body mt-1">
                    <p className="text-muted text-medium text-bold mb-1">No Risk</p>
                    <p className="text-muted text-small mb-0">100% money back if not approved</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mb-3 mb-lg-4">
                <div className="reason-item-container align-items-start d-flex media">
                  <img className="img-fluid mr-3" src="/images/hiw_why_bn2.svg" width="80px"/>
                  <div className="media-body mt-1">
                    <p className="text-muted text-medium text-bold mb-1">Licensed Practitioners</p>
                    <p className="text-muted text-small mb-0">Practitioners in your state as required by law</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 d-flex justify-content-center mb-3 mb-lg-4">
                <div className="reason-item-container align-items-start d-flex media">
                  <img className="img-fluid mr-3" src="/images/hiw_why_bn3.svg" width="80px"/>
                  <div className="media-body mt-1">
                    <p className="text-muted text-medium text-bold mb-1">No Health Records</p>
                    <p className="text-muted text-small mb-0">You do not need to provide any medical records</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mb-3 mb-lg-4">
                <div className="reason-item-container align-items-start d-flex media">
                  <img className="img-fluid mr-3" src="/images/hiw_why_bn4.svg" width="80px"/>
                  <div className="media-body mt-1">
                    <p className="text-muted text-medium text-bold mb-1">Highest Security</p>
                    <p className="text-muted text-small mb-0">Industry leading 256-Bit encryption</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 d-flex justify-content-center mb-3 mb-lg-4">
                <div className="reason-item-container align-items-start d-flex media">
                  <img className="img-fluid mr-3" src="/images/hiw_why_bn5.svg" width="80px"/>
                  <div className="media-body mt-1">
                    <p className="text-muted text-medium text-bold mb-1">Recognized by Law</p>
                    <p className="text-muted text-small mb-0">Federal, State, HIPAA Law Compliant</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mb-3 mb-lg-4">
                <div className="reason-item-container align-items-start d-flex media">
                  <img className="img-fluid mr-3" src="/images/hiw_why_bn6.svg" width="80px"/>
                  <div className="media-body mt-1">
                    <p className="text-muted text-medium text-bold mb-1">Satisfaction Guarantee</p>
                    <p className="text-muted text-small mb-0">#1 in customer satisfaction</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 d-flex justify-content-center mb-3 mb-lg-4">
                <div className="reason-item-container align-items-start d-flex media">
                  <img className="img-fluid mr-3" src="/images/hiw_why_bn7.svg" width="80px"/>
                  <div className="media-body mt-1">
                    <p className="text-muted text-medium text-bold mb-1">All Forms Included</p>
                    <p className="text-muted text-small mb-0">All housing forms included</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mb-3 mb-lg-4">
                <div className="reason-item-container align-items-start d-flex media">
                  <img className="img-fluid mr-3" src="/images/hiw_why_bn8.svg" width="80px"/>
                  <div className="media-body mt-1">
                    <p className="text-muted text-medium text-bold mb-1">Lowest Price</p>
                    <p className="text-muted text-small mb-0">Best price on the market</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hiw-more-questions-section container text-center bn-space-top">
          <h3 className="text-primary mb-3">Questions?</h3>
          <p className="text-small text-muted">You may view our <Link to="/faq">FAQ</Link> page or <Link to="/contact">Contact Us</Link>.</p>
          <Button size="lg" onClick={() => {this.props.history.push('/letters')}} className="mt-3 mt-lg-4">
            Get started
          </Button>
        </div>
      </div>
    )
  }
}
