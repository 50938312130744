import { history } from '../store'

export const logOutAccount = () => {
  sessionStorage.removeItem('jwt');
  sessionStorage.removeItem('accountType');
  sessionStorage.removeItem('email');
  history.push("/")
  return {
    type: "LOGOUT_ACCOUNT"
  }
}

export const loginRequest = () => {
  return {
    type: "LOGIN_REQUEST"
  }
}
export const loginSuccess = (accountType) => {
  if (accountType === 'Provider') {
    history.push("/provider/dashboard")
  } else {
    history.push("/")
  }
  return {
    type: "LOGIN_SUCCESS"
  }
}

export const loginFailure = (errors) => {
  return {
    type: "LOGIN_FAILURE",
    payload: errors
  }
}

export const logInAccount = (credentials) => {
  return (dispatch) => {
    dispatch(loginRequest())
    return fetch("/accounts/sign_in", {
      method: "POST",
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(credentials)
    })
    .then(response => {
      if (!response.ok) {
        throw response
      } else {
        return response.json()
      }
    })
    .then(json => {
      sessionStorage.setItem('jwt', json.jwt)
      sessionStorage.setItem('accountType', json.accountType)
      sessionStorage.setItem('email', json.email)
      dispatch(loginSuccess(json.accountType))
    })
    .catch(response =>
      response.json()
      .then((json) =>
        dispatch(loginFailure(json.errors))
      )
    );
  };
}
