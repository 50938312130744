import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Alert, Button, Col, Form, FormGroup} from "react-bootstrap";
import { Link} from "react-router-dom";

import ContactUsForm from "../../components/ConactUsForm/ContactUsForm";
import * as contactActions from "../../actions/contactActionCreators";
import './style.css';

class ContactUsContainer extends React.Component {
  state = {
    message: "",
    submitted: false
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.contact.saving && !nextProps.contact.saving) {
      if (nextProps.contact.failed)
        this.setState({message: "Please correct the form and submit again."})
      else
        this.setState({message: "Thank you! Your contact message has been sent.", submitted: true})
    }
  }

  handleSubmit = (e) => {
    this.props.actions.submitContact(e)
  }

  render() {
    const { errors, saving, failed } = this.props.contact;
    return (
      <div className="container page-space-top page-space-bottom">
        <h2 className="page-header">Contact Us</h2>
        <hr className="header-underline"/>

        <p className="text-muted text-medium mb-4">
          Please contact us with your questions using the form below. A customer support representative will happily assist you shortly. You may also find the answer to your questions by visiting our <Link to="/faq">FAQ page</Link>. Alternatively you can give us a call at (800) 510-2620.
        </p>

        {this.state.message !== "" &&
          <Alert variant={failed ? 'warning' : 'success'}>
            {this.state.message}
          </Alert>
        }
        {!this.state.submitted &&
          <ContactUsForm onSubmit={this.handleSubmit} errors={errors} saving={saving}/>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    contact: state.contact
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(contactActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsContainer)
