import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { FormGroup, Button, Alert } from 'react-bootstrap';

import { FieldInput } from '../formFields';
import './LoginForm.css';
import {email, required} from "../formValidations";

let LoginForm = ({ handleSubmit, errors }) => (
  <form onSubmit={ handleSubmit }>
    { errors.length > 0 &&
      <Alert variant={'danger'}>
        {errors.map((error, i) => <span key={i}><strong>{error}</strong></span>)}
      </Alert>
    }

    <FormGroup
      controlId="email"
      >
      <Field
        component={ FieldInput }
        name="account[email]"
        validate={[required, email]}
        placeholder="Email"
        />
    </FormGroup>

    <FormGroup
      controlId="password"
      >
      <Field
        component={ FieldInput }
        type="password"
        name="account[password]"
        validate={required}
        placeholder="Password"
        />
    </FormGroup>

    <div className="action-container">
      <div className="action-wrapper text-right">
        <Link to="/forgot-password">Forgot Password?</Link>
      </div>
      <Button className="btn btn-primary" type="submit">Login</Button>
    </div>
  </form>
);

const createReduxForm = reduxForm({ form: 'login' })

LoginForm = createReduxForm(LoginForm)

export default LoginForm
