import React from 'react';

import './style.css';
import {Button, DropdownButton, Dropdown} from "react-bootstrap";
import FAQCard from "./FAQCard";

export default class FAQContainer extends React.Component {
  state = {
    filter: "esa"
  };

  render() {
    return (
      <div className="container page-space-top page-space-bottom">
        <h2 className="page-header">Frequently Asked Questions</h2>
        <hr className="header-underline"/>

        <div className="row">
          <div className="col-lg-6">
            <p className="text-muted text-medium mb-4">
              Below you will find some of the most Frequently Asked Questions about Emotional Support Animals.
            </p>

            <div className="row mx-gutters-2 mb-5 d-none d-lg-flex">
              <div className="col-lg-7 col-xl-6">
                <Button variant={this.state.filter === 'esa' ? "primary" : "outline-primary"} className="btn-block"
                        onClick={() => {this.setState({filter: 'esa'})}}>
                  Emotional Support Animals
                </Button>
              </div>

              <div className="col-lg-5 col-xl-6">
                <Button variant={this.state.filter === 'legal' ? "primary" : "outline-primary"} className="btn-block"
                        onClick={() => {this.setState({filter: 'legal'})}}>
                  Legal
                </Button>
              </div>
            </div>

            <div className="mb-4 d-lg-none">
              <DropdownButton
                variant="outline-primary"
                className="btn-block"
                title={this.state.filter === "esa" ? "Emotional Support Animals" : "Legal"}
                onSelect={(eventKey) => {this.setState({filter: eventKey})}}
              >
                <Dropdown.Item eventKey="esa">Emotional Support Animals</Dropdown.Item>
                <Dropdown.Item eventKey="legal">Legal</Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        </div>

        <div className="row mx-gutters-2">
          {this.state.filter === "esa" &&
          <FAQCard question="I am new to this; can you please explain how this works?">
            <p>If you suffer from a mental or emotional disability you and your animal have rights.</p>
            <p>Under federal law you cannot be denied housing because of your animal. While exercising these rights landlords cannot
              charge you any fees related to your animal.</p>
            <p>All you need is a letter from a doctor or mental health professional stating that the presence of your
              animal is necessary for your well being.</p>
          </FAQCard>
          }

          {this.state.filter === "esa" &&
          <FAQCard question="What is an Emotional Support Animal?">
            <p>An Emotional Support Animal can be any animal that helps his/her handler alleviate symptoms of mental or
              emotional disability. There are no restrictions on the type, breed, size or age of the animal and there
              are no training requirements.</p>
            <p>The handler must suffer from a mental or emotional disability, this includes, but is not limited to,
              depression, anxiety, stress, loneliness, or anger. And the animal helps the handler cope with their
              disability.</p>
          </FAQCard>
          }

          <FAQCard question="What laws protect Emotional Support Animals?">
            <p>
              Under the <b>Fair Housing Amendments Act (FHAA)</b> of 1988:<br/>
              Individuals with mental or emotional disabilities accompanied by their Emotional Support Animals must be
              given access to housing communities that have a "No Pet" policy. This act permits qualified individuals
              to live with their Emotional Support Animal in their home. Just as a wheelchair provides a person with a
              physical limitation the equal opportunity to use and enjoy a dwelling, an Emotional Support Animal
              provides a person with a mental or emotional disability the same opportunity to live independently.
            </p>
            <p>Most times, an Emotional Support Animal will be seen as a reasonable accommodation for a person with
              such a disability. Failure to make reasonable accommodations by changing rules or policies can be a
              violation of the FHA unless the accommodation would be an undue financial burden on the landlord or
              cause a fundamental alteration to the premises.</p>
            <p>Other protections:<br/>
              Some municipalities and businesses, even though not required by federal law, extend access rights to
              individuals with Emotional Support Animals to accompany them.
            </p>
          </FAQCard>

          {this.state.filter === "esa" &&
          <FAQCard question="How to get an Emotional Support Animal?">
            <p>An Emotional Support Animal can be any animal, with no limitation to type, breed, size or age, and no
              training requirement. This animal can be acquired by any traditional means including shelters, rescues,
              and breeders.</p>
          </FAQCard>
          }

          {this.state.filter === "esa" &&
          <FAQCard question="Does my pet qualify as an Emotional Support Animal?">
            <p>Any animal can qualify as an Emotional Support Animal, so long the animal helps their handler cope with a
              mental or emotional disability.
              There are no limitations to type, breed, size or age, and no special training is required.
            </p>
          </FAQCard>
          }

          {this.state.filter === "esa" &&
          <FAQCard question="Does an Emotional Support Animal require special training?">
            <p>No, there are no special training requirements. Misbehavior, however, may lead to being asked to remove
              the animal.</p>
          </FAQCard>
          }

          <FAQCard question="What documents do I need to have my ESA in my housing?">
            <p>The law allows landlords to request a letter recommending the use of an Emotional Support
              Animal written by a doctor or mental health professional from an individual accompanied by an Emotional
              Support Animal and confirming the existence of mental or emotional disability.</p>
          </FAQCard>

          {this.state.filter === "esa" &&
          <FAQCard question="Does breed, age or size of my animal affect them being an Emotional Support Animal?">
            <p>Any animal can qualify as an Emotional Support Animal. There are no limitations to type, breed, size or
              age, and no training is required.</p>
          </FAQCard>
          }

          {this.state.filter === "esa" &&
          <FAQCard question="Will this get reported on my medical records?">
            <p>AnimalAccess is the only HIPAA compliant service online. Your privacy is of utmost importance, we meet and
              exceed federal and state requirements in all 50 states. AnimalAccess does not report nor share your private
              information without your specific consent. AnimalAccess will not report any information to your medical
              records.</p>
          </FAQCard>
          }

          {this.state.filter === "esa" &&
          <FAQCard question="Why have I never heard about this type of service before?">
            <p>Believe it or not Emotional Support Animals and the requirement of a letter from a doctor or mental
              health professional has been around since the passing of the FHAA in the 80’s. Since then society
              in general has become more aware, accepting, and accompanying of mental or emotional disabilities. In the
              meantime, advancements in technology has allowed services such as AnimalAccess to bring these services to
              21stcentury by bringing them online, making them exponentially more accessible and affordable to all those
              in need.</p>
          </FAQCard>
          }

          {this.state.filter === "esa" &&
          <FAQCard question="What if I don’t qualify for an ESA?">
            <p>The National Institute of Health (NIH) and the National Alliance on Mental Illness (NAMI) report that
              upwards of 1 in 5 adults suffer from mental or emotional disability. The conditions include, but are not
              limited to depression, anxiety, stress, loneliness, anger, and many others. (Follow this link for further
              list and details).</p>
            <Button variant="outline-primary" className="mt-2" target="_blank"
                    href="https://www.ssa.gov/disability/professionals/bluebook/12.00-MentalDisorders-Adult.htm">
              List of Disabilities
            </Button>
            <p>
              Over 99% of patients suffering from such disabilities have qualified by AnimalAccess
              providers.</p>
            <p>In the rare instance that you do not qualify, we will provide a full refund with our 100% money-back
              guarantee.</p>
          </FAQCard>
          }

          {this.state.filter === "esa" &&
          <FAQCard question="How soon will I receive my letters?">
            <p>You will receive your letters the same day and typically within 1 business day.</p>
          </FAQCard>
          }

          {this.state.filter === "esa" &&
          <FAQCard question="Can cats or other animals be Emotional Support Animal?">
            <p>Any animal can qualify as an Emotional Support Animal as they are no limitations to type, breed, size or
              age without any training required.</p>
          </FAQCard>
          }

          {this.state.filter === "esa" &&
          <FAQCard question="What are the benefits of having an Emotional Support Animal?">
            <p>Emotional Support Animals, above and beyond companionship, help alleviate suffering from the negative
              effects of mental or emotional disability,allowing their owners to live happier, healthier and longer
              lives.</p>
            <p><b>Access:</b><br/>
            Emotional Support Animals are granted access to accompany their handler where otherwise animal may not be
              allowed, without restrictions to type, breed, size or age.</p>
            <p><b>Housing:</b><br/>
            Owners of Emotional Support Animals have more options and less restriction on housings. Under the FHAA
              Emotional Support Animals are allowed into housing and communities with “No Pet” policies, without the
              requirement of fees related to the animal.</p>
          </FAQCard>
          }

          {this.state.filter === "legal" &&
          <FAQCard question="What is the Fair Housing Amendments Act (FHAA)?">
            <p>Fair Housing Amendments Act was passed in 1988. Among additional protections extended on a national level
              to individuals seeking housing, is reasonable accommodation for Emotional Support Animals for those
              suffering from mental or emotional disabilities. Under these provisions individuals suffering from mental
              or emotional disabilities can bring their animals where there are “No Pet” policies. That is, where there
              are no animals allowed without the need for additional fees related to the animal.</p>
          </FAQCard>
          }

          {this.state.filter === "legal" &&
          <FAQCard question="Is this Legit? How Do I Know It’s Legal?">
            <p>AnimalAccess brings the convenience of a doctor’s office to your living room. AnimalAccess is a collection of
              practitioners licensed and practicing in every state. As required by law, your Emotional Support Animal
              evaluation and letters will be conducted and issued by a practitioner in your state.
              AnimalAccess’s process and letters meet and exceed all requirements under FHAA, and the laws of all 50
              states.
            </p>
            <p>If you suffer from a mental or emotional disability, you and your animal have rights. These rights
              include reasonable accommodations to housing and access provided by FHAA and various
              municipalities and organizations. Under FHAA any animal can be an Emotional Support Animal if
              they are helping alleviate symptoms of someone suffering from mental or emotional disabilities.</p>
          </FAQCard>
          }

          <FAQCard question="Can my landlord reject my ESA?">
            <p>No, landlords cannot simply reject your Emotional Support Animals. Federal law requires your landlord to
              accommodate you and your Emotional Support Animals where they may otherwise reject pets.</p>
          </FAQCard>

          <FAQCard question="I just completed the questionnaire. What’s next?">
            <p>Your questionnaire will be reviewed by a licensed practitioner in your state. They may follow up with you
              with some questions. Once approved, you will receive your Emotional Support Animal letters immediately via
              Email.</p>
          </FAQCard>

          <FAQCard question="What is the refund policy?">
            <p>If for any reason you do not qualify, we provide a 100% money back guarantee, and will provide you with a
              full refund.</p>
          </FAQCard>

          <FAQCard question="What kind of Licensed Mental Health Professional will sign my letters?">
            <p>As required by FHAA and laws of various state, your letters will be signed by a doctor or mental
              health professional licensed in your state.</p>
          </FAQCard>

          <FAQCard question="What does the letter contain?">
            <p>The Emotional Support Animal letters contain a diagnosis of a mental or emotional disability and a
              recommendation of the use of an Emotional Support Animal to relieve the symptoms of such disability.</p>
          </FAQCard>

          <FAQCard question="What rights do I get with an ESA Letter? Where can I take my ESA?">
            <p>If you suffer from a mental or emotional disability, you and your animal have rights. These rights
              include reasonable accommodations to housing and access provided by FHAA and various
              municipalities and organizations.</p>
            <p>Individuals suffering from mental or emotional disabilities can bring their animals where there are “No
              Pet” policies. That is, where there are no animals allowed without the need for additional fees related to
              the animal.</p>
          </FAQCard>

          <FAQCard question="Does the ESA Letters expire? How often do I need to renew?">
            <p>Our Emotional Support Animal letters do not expire. However, your landlord may require your
              letters to be issued within the prior 12 months.</p>
          </FAQCard>

          <FAQCard question="Can I use this for on-campus housing?">
            <p>Yes, on-campus housing is covered under the FHAA. You are allowed to have your Emotional Support Animal
              with you.</p>
          </FAQCard>

          <FAQCard question="Can I get a letter for more than one ESA?">
            <p>Yes, you may have more than one Emotional Support Animal.</p>
          </FAQCard>

          <FAQCard
            question="Will my letter be written by a provider in my state, as required by the laws of all 50 states?">
            <p>Yes, AnimalAccess is the only service that meets and exceeds all federal and state law requirements that will
              assign you to a provider in your state. The laws of all 50 states require providers serving their
              residents to be licensed to practice in that specific state.</p>
          </FAQCard>

          <FAQCard question="My privacy is very important to me, is AnimalAccess HIPAA complaint?">
            <p>AnimalAccess is the only HIPAA compliant service online. Your privacy is of utmost importance, we meet and
              exceed federal and state requirements in all 50 states. AnimalAccess does not report nor share your private
              information without your specific consent. AnimalAccess will not report any information to your medical
              records.</p>
          </FAQCard>

          <FAQCard question="Do you comply with all local, state, and federal laws?">
            <p>AnimalAccess meets and exceeds federal and state requirements in all 50 states.</p>
          </FAQCard>

          <FAQCard question="What state is my ESA letters good in?">
            <p>Emotional Support Animals are protected by federal law and thus are protected equally in all 50
              states.</p>
          </FAQCard>

          {this.state.filter === "legal" &&
          <FAQCard question="How can I file a complaint with HUD?">
            <p>You may file a complaint using the link below.</p>
            <Button variant="outline-primary" className="mt-2" target="_blank"
                    href="https://www.hud.gov/program_offices/fair_housing_equal_opp/online-complaint">
              HUD.GOV
            </Button>
          </FAQCard>
          }

          {this.state.filter === "esa" &&
          <FAQCard question="What are some reasons for not getting approved?">
            <p>If you do not suffer from any emotional or psychological symptoms, you may not be a candidate who would qualify for having an emotional support animal.</p>
          </FAQCard>
          }

          {this.state.filter === "esa" &&
          <FAQCard question="Do I have to provide any medical forms or records?">
            <p>No, you do not need to provide any medical documentation or forms. You will only be required to fill out the quick, online questionnaire for one of our practitioners to review.</p>
          </FAQCard>
          }

          {this.state.filter === "esa" &&
          <FAQCard question="Do I need to submit a separate application for each of my pets?">
            <p>No, only one application needs to be filled out per individual. There is a limit of two animals per letter, per individual. If you have an additional animal, you may add it to your application in the 2nd step of the process. The additional animal has a fee of $49.</p>
          </FAQCard>
          }

          {this.state.filter === "esa" &&
          <FAQCard question="My landlord is requiring a specific form to be signed, do you sign these?">
            <p>If you have been provided with any additional forms on behalf of your building manager or landlord after you have been approved and received your housing authorization form, simply email it to us for one of our practitioners to review and sign.</p>
          </FAQCard>
          }
        </div>
      </div>
    )
  }
}
