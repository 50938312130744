import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { FormGroup, Button, Alert } from 'react-bootstrap';

import { FieldInput } from '../formFields';
import {email, required} from "../formValidations";

let ForgotPasswordForm = ({ handleSubmit, errors }) => (
  <form onSubmit={ handleSubmit }>
    { errors.length > 0 &&
    <Alert variant={'danger'}>
      {errors.map((error, i) => <span key={i}><strong>{error}</strong></span>)}
    </Alert>
    }

    <FormGroup
      controlId="email"
    >
      <Field
        component={ FieldInput }
        name="account[email]"
        validate={[required, email]}
        placeholder="Email"
      />
    </FormGroup>

    <div className="action-container">
      <Button className="btn btn-primary" type="submit">Request Reset Instructions</Button>
    </div>
  </form>
);

const createReduxForm = reduxForm({ form: 'passwordReset' })

ForgotPasswordForm = createReduxForm(ForgotPasswordForm)

export default ForgotPasswordForm
