import React from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import {Badge, Button, FormLabel} from 'react-bootstrap';
import { Elements } from 'react-stripe-elements';
import queryString from 'query-string';

import AppointmentForm from '../../components/AppointmentForm/AppointmentForm';
import * as appointmentActions from "../../actions/appointmentActionCreators";

import './style.css';


class AppointmentContainer extends React.Component {
  state = {
    showSuccessMessage: false
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.appointment.submitting && !nextProps.appointment.submitting
      && nextProps.appointment.errors === null) {
      this.setState({showSuccessMessage: true})
    }
    if (!this.props.appointment.submitting && nextProps.appointment.submitting) {
      this.setState({showSuccessMessage: false})
    }
  }

  render() {
    const parsed = queryString.parse(location.search);

    return (
      <Elements>
        <div className="container page-space-top page-space-bottom">
          <h2 className="page-header">Provider Services</h2>
          <hr className="header-underline"/>

          <AppointmentForm/>

        </div>
      </Elements>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appointment: state.appointment
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      appointmentActions: bindActionCreators(appointmentActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentContainer);
