import React from 'react';

export default class PrivacyPolicyContainer extends React.Component {

  render() {
    return (
      <div className="container page-space-top page-space-bottom">
        <div className="row">
          <div className="col-lg-7">
            <h2 className="page-header text-muted">Privacy Policy</h2>
            <hr className="header-underline"/>
          </div>
        </div>

        <h6>INTRODUCTION</h6>

        <p>
          This Privacy Notice explains how AnimalAccess (collectively, “Company” or “we”) collects, uses, shares, and
          handles personally identifiable information about Users of the Company websites, including animalaccess.com,
          (collectively, “Company Sites”). By visiting Company Sites, Users accept the practices described in this
          Privacy Notice and consents to the collection, use and disclosure of information described herein.
        </p>

        <p>
          As Company evolves, we may update this Privacy Notice to reflect changes in the manner in which we deal with
          personal information, whether to comply with applicable regulations and self-regulatory standards or
          otherwise. The Privacy Notice posted here will always be current. We encourage you to review this statement
          regularly. If we make a material change to the Privacy Policy, we will notify you via e-mail at your most
          recent e-mail address on record and post a notice of the change on Company Sites.
        </p>

        <p>
          This Privacy Notice only applies to Personally Identifiable information, which is information that is linked
          or linkable to an identifiable individual. This Privacy Notice does not limit collection, use, sharing, or
          handling by Company of aggregated, de-identified, or anonymized information, provided that the information
          cannot be associated with an individual.
        </p>

        <p>
          If you have any questions or concerns about this Privacy Notice, e-mail Company at privacy@relevent.url.
        </p>

        <h6>INFORMATION WE COLLECT</h6>

        <p>
          Information that may be collected via Company Sites includes:</p>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Telephone number</li>
          <li>Mailing address</li>
          <li>Medical information that User chooses to communicate via Company Sites, such as User’s
            medical history
          </li>
          <li>Information provided by a medical professional, such as a doctor’s note</li>
          <li>Information about a User’s animal</li>
          <li>Payment card data and related information necessary to pay for Company Services</li>
          <li>Website log and user agent data such as Internet Protocol (“IP”) address, browser type,
            metadata, and the date and time that you accessed Company Services
          </li>
          <li>Device type and operating system information</li>
          <li>Information collected via cookies and web beacons</li>
          <li>Simple web page actions</li>
          <li>Any other information that Users choose to communicate to Company.</li>
        </ul>

        <p>
          Company Services are not directed to children under the age of 13. Company does not
          knowingly collect or store personal information about children under the age of 13.
        </p>

        <p>
          If a User would like to review or request changes to his or her information that is collected through a
          Company Service (subject to certain exceptions prescribed by law), the User may email privacy@relevent.url.
        </p>

        <h6>HOW WE USE THE INFORMATION</h6>

        <p>
          We use the User information that we collect to administer and provide Company Services. We may use User
          information for the following purposes:
        </p>

        <ul>
          <li>Maintaining, providing, delivering, repairing, personalizing, understanding, and improving Company
            Services, which include processing registrations for pets and animals, including service dogs, emotional
            support dogs, and therapy dogs;
          </li>
          <li>Processing transactions with Users, such as billing and processing account information;</li>
          <li>Communicating with Users about Company Services and affiliated third party services, via
            methods such as e-mail notifications;
          </li>
          <li>Preventing potentially illegal activities, or activities that potentially violate the Company
            Terms of Use;
          </li>
          <li>Responding to the inquiries of Users;</li>
          <li>For any other purpose to which you consent at the time that your information is collected.</li>
        </ul>

        <h6>SHARING INFORMATION</h6>

        <p>
          Under the following circumstances, Company may provide third parties with the information that it has
          collected from a User, provided that the sharing is conducted for one of the following purposes:

        </p>

        <ul>
          <li>To obtain services from Company third-party service providers. Examples of such third-party services
            include but are not limited to technical support, website hosting, cloud storage, and website analytics;
          </li>
          <li>To obtain a medical note or other documentation from a healthcare provider;</li>
          <li>To comply with legal requirements and respond to lawful court orders and other valid legal
            process;
          </li>
          <li>To transfer any information among subsidiaries, parent companies, or commonly owned
            companies;
          </li>
          <li>To protect the rights, property, or safety of Company or others, or to assist in the investigation
            of fraud or any other unlawful activity;
          </li>
          <li>As part of a merger, partial or complete acquisition, bankruptcy, asset sale, financing, consolidation,
            restructuring, liquidation, or similar corporate transaction. In the event of a corporate transaction that
            would result in the transfer of User information, Company would inform Users via their most recent e-mail
            address on record with Company and a prominent notice on the Company Sites;
          </li>
          <li>To transfer any aggregate or anonymized information, provided that no individual could be identified by
            the information;
          </li>
          <li>Under any other circumstance to which the User consents after being notified.</li>
        </ul>

        <h6>COOKIES, WEB BEACONS, AND DO-NOT-TRACK</h6>

        <p>
          Cookies are bits of electronic information that can be transferred to your computer or other electronic device
          to uniquely identify your browser. When you use our website, we may place one or more cookies on your computer
          or other electronic device. We may use cookies to connect your activity on our website with other information
          we store about you in your account profile or your prior interactions with our website to, for example, store
          your preferences. Company uses cookies that are session-based and persistent. Session-based cookies exist only
          during one session. They disappear from your computer when you close your browser software or turn off your
          computer. Persistent cookies remain on your computer after you close your browser or turn off your computer.
          You have the ability to accept or decline cookies by modifying the settings in your browser. You also have the
          ability to enable or disable persistent cookies by checking or unchecking the “Remember Me” option at login.
        </p>

        <p>
          At any time, you may adjust your browser settings to refuse cookies according to the instructions related to
          your browser. Please note that if you disable your web browser’s ability to accept cookies, you will not be
          able to access all features and functions available on the Company Sites, and you may not be able to
          successfully use Company services or offerings. Unless you have adjusted your browser setting so that it will
          refuse cookies, our system will place cookies when you log on to our websites. For more information about how
          to manage cookies in your web browser, see <a
          href="​http://www.aboutcookies.org​">​http://www.aboutcookies.org​</a>.
        </p>

        <p>
          Company uses cookies in conjunction with services such as Google Analytics to better understand Users’
          experience with Company Services.
        </p>

        <p>
          As described in this Privacy Notice, other parties may collect Company User personally identifiable
          information about an individual User’s online activities over time and across different Web sites when the
          User uses Company services. Company does not respond to Do-Not-Track signals.
        </p>

        <h6>TARGETED ADVERTISING</h6>
        <p>
          We may participate in targeted advertising. This means that, for example, when you visit our website, we or
          third party service providers or advertisers may use cookies (that they collect or that we provide to them) or
          other similar technologies to collect information about your use of

          our website or your online activities over time and across different websites. As you visit our website,
          cookies will be placed on your computer and information will be collected so that we can understand what you
          are interested in. Collected information may include the content you view, the date and time that you view
          this content, the time you spent viewing this content, and the website that referred you to our website. This
          information may be associated with your unique browser, device identifier, or Internet Protocol address, and
          may be used to tailor advertising to you across websites.

        </p>

        <p>
          This could be in the form of an advertisement on the Google search results page, or a website in the Google
          Display Network. You can visit the page <a href="http://www.google.com/settings/ads/onweb/">​Google Ad
          Preferences​</a> to opt-out of targeted advertising, and to set preferences for how Google advertises to you.
          You can also opt-out of targeted advertising entirely by modifying your browser settings or by using a browser
          plug-in. We also may use other platforms for targeted advertisements, including but not limited to Bing and
          Facebook.

        </p>

        <h6>INFORMATION SECURITY</h6>

        <p>
          We take commercially reasonable security measures to protect against unauthorized access. However, it is
          important to remember that no security measures are 100 percent effective, and it is therefore impossible to
          guarantee absolute security of personal data provided on Company Sites.
        </p>

        <h6>
          INTERNATIONAL TRANSFERS</h6>

        <p>
          Company is headquartered in the United States and, regardless of where you use our Services or otherwise
          provide information to us, your information may be transferred to, maintained, and processed by Company and
          our service providers in the United States or other jurisdictions in which we or they operate. Please note
          that privacy laws, regulations, and standards in the jurisdictions in which your information may be maintained
          and processed may not be equivalent to the laws in your country of residence and such information may be
          subject to lawful access by U.S. or other foreign courts, law enforcement, and governmental authorities.
        </p>

        <h6>YOUR CALIFORNIA PRIVACY RIGHTS</h6>
        <p>
          The California Shine the Light Law requires companies to disclose certain information about information shared
          with third parties that is used for the third parties’ direct marketing purposes.
          Company does not share Users’ personal information with third parties for the third parties’ direct marketing
          purposes. Nonetheless, if you are a California resident, you may request information about the disclosure of
          your personal information to third parties for the third parties’ direct marketing purposes by emailing
          privacy@animalaccess.com

        </p>
        <h6>
          CONTACT US
        </h6>


        <p>
          If you have any questions about this Privacy Notice or our privacy practices, please contact us at
          privacy@animalaccess.com
        </p>

      </div>
    )
  }
}
